import {
    Box,
    Button,
    Divider,
    HStack,
    Heading,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text,
    Textarea,
    VStack,
    useToast,
} from "@chakra-ui/react";
import { IntlKey } from "../../../../intl/intlLanguages";
import { User } from "../../../user/user";
import { GoCommentDiscussion } from "react-icons/go";
import { CiStar } from "react-icons/ci";
import { useEffect, useState } from "react";
import { addReviewAndUpdateRating } from "./review";
import { v4 as uuidv4 } from "uuid";

interface IProps {
    langKey: keyof IntlKey;
    isOpen: boolean;
    onClose: () => void;
    teacherUser: User;
    user: User;
}

export const ReviewModal: React.FC<IProps> = ({ isOpen, onClose, teacherUser, user }) => {
    const [hover, setHover] = useState<number>(0);
    const toast = useToast();

    const [rating, setRating] = useState(0);
    const [text, setText] = useState("");
    const [lessonsCount, setLessonsCount] = useState(1);
    const [saveInProgress, setSaveInProgress] = useState(false);

    const setInitState = () => {
        setText("");
        setLessonsCount(1);
        setRating(0);

        setSaveInProgress(false);
    };

    const handleSubmit = async () => {
        setSaveInProgress(true);
        const res = await addReviewAndUpdateRating({
            id: uuidv4(),
            rating: rating,
            text: text,
            lessonsCount: lessonsCount,
            studentId: user.id,
            teacherId: teacherUser.id,
            dateTime: new Date(),
        });

        if (res.ok) {
            toast({
                title: "Feedback",
                description: "Your review has ben saved!",
                status: "success",
            });
            handleClose();
        }

        if (!res.ok)
            toast({
                title: "Error",
                description: "Something went wrong! Please, try again later",
                status: "error",
            });

        setSaveInProgress(false);
    };

    const handleClose = () => {
        setInitState();
        onClose();
    };

    useEffect(() => {
        setInitState();
    }, []);

    return (
        <>
            <Modal size="lg" isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay h="100%" w="100%" bg="teddy_blue.5069" />
                <ModalContent p={3}>
                    <ModalCloseButton />
                    <ModalHeader>
                        <HStack>
                            <GoCommentDiscussion />
                            <Text>Feedback</Text>
                        </HStack>
                    </ModalHeader>
                    <ModalBody>
                        <Divider />
                        <VStack pt={5} gap={5} pb={5}>
                            <VStack>
                                <Heading fontSize="2xl">How was your experience?</Heading>
                                <Text textAlign="center" fontSize="sm">
                                    Your feedback is very important for us and will help other users to find their
                                    teacher
                                </Text>
                            </VStack>
                            <HStack gap={3} fontSize="5xl" pt={5} pb={5}>
                                {[1, 2, 3, 4, 5].map((star) => {
                                    return (
                                        <Icon
                                            key={star}
                                            as={CiStar}
                                            color={star <= (hover || rating) ? "teddy_yellow.500" : "teddy_grey.100"}
                                            onMouseEnter={() => setHover(star)}
                                            onMouseLeave={() => setHover(0)}
                                            onClick={() => {
                                                if (!saveInProgress) setRating(star);
                                            }}
                                        />
                                    );
                                })}
                            </HStack>
                            <HStack w="100%" justify="space-between">
                                <Text>How many lessons did you have?</Text>
                                <NumberInput
                                    onChange={(valueString) => setLessonsCount(Number.parseInt(valueString))}
                                    value={lessonsCount}
                                    w="10vw"
                                    min={1}
                                    isDisabled={saveInProgress}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </HStack>
                            <Textarea
                                h="25vh"
                                placeholder={`Feel free to leave honest review of your experience with ${teacherUser.name}`}
                                onChange={(e) => setText(e.target.value)}
                                isDisabled={saveInProgress}
                            />
                            <Box w="100%" h="100%" pt={5}>
                                <Button
                                    w="100%"
                                    colorScheme="teddy_teal"
                                    isDisabled={saveInProgress}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
