import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    Box,
    VStack,
    Icon,
    HStack,
    Text,
    Spinner,
    Spacer,
    Flex,
    useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useDropzone } from "react-dropzone";
import { saveAvatar } from "./avatar";
import { User, updateUser } from "../../user/user";
import { BsImage } from "react-icons/bs";
import { IntlKey, intl } from "../../../intl/intlLanguages";

interface IProps {
    langKey: keyof IntlKey;
    onClose: () => void;
    isOpen: boolean;
    user: User | null;
    setUser: (user: User | null) => void;
    isMobile: boolean;
}

export const AvatarEditModal: React.FC<IProps> = ({ langKey, isOpen, onClose, user, setUser, isMobile }) => {
    const [image, setImage] = useState<File | string | null>(null);
    const [editor, setEditor] = useState<AvatarEditor | null>(null);
    const [inProgress, setInProgress] = useState(false);
    const [scale, setScale] = useState<number>(1);
    const toast = useToast();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const lastPinchDistance = useRef<number | null>(null);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/png": [".png"],
            "image/jpg": [".jpg"],
            "image/jpeg": [".jpeg"],
        },
        noClick: image !== null, // Disable click if there's already an image
        onDrop: (acceptedFiles) => {
            setImage(acceptedFiles[0]);
        },
    });

    const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setImage(files[0]);
        }
    };

    const handleWheel = (e: React.WheelEvent) => {
        e.preventDefault();
        const delta = e.deltaY * -0.01;
        const newScale = Math.min(Math.max(1, scale + delta), 2); // Constrain scale between 1 and 2
        setScale(newScale);
    };

    const handleError = (msg: string) => {
        toast({
            duration: 3000,
            title: "Error",
            description: intl.avatarEditError[langKey],
            status: "error",
        });
    };

    const handleTouchMove = useCallback(
        (e: TouchEvent) => {
            if (e.touches.length === 2) {
                e.preventDefault();
                const touch1 = e.touches[0];
                const touch2 = e.touches[1];
                const distance = Math.sqrt(
                    Math.pow(touch2.pageX - touch1.pageX, 2) + Math.pow(touch2.pageY - touch1.pageY, 2)
                );

                if (lastPinchDistance.current != null) {
                    const delta = distance - lastPinchDistance.current;
                    const newScale = Math.min(Math.max(1, scale + delta * 0.005), 2); // Adjust scale sensitivity as needed
                    setScale(newScale);
                }

                lastPinchDistance.current = distance;
            }
        },
        [scale]
    );

    const handleTouchEnd = () => {
        lastPinchDistance.current = null; // Reset pinch distance when the touch ends
    };

    const onSave = async (blob: Blob) => {
        if (editor && blob && user) {
            setInProgress(true);
            const uploadRes = await saveAvatar(blob, user);
            if (!uploadRes.ok) {
                handleError(uploadRes.message ?? intl.avatarEditError[langKey]);
                return;
            }

            if (uploadRes.data) {
                const userWithNewAvatar = { ...user, avatarUrl: uploadRes.data } as User;
                const userUpdateRes = await updateUser(userWithNewAvatar);

                if (!userUpdateRes.ok) {
                    handleError(userUpdateRes.message ?? intl.avatarEditError[langKey]);
                    return;
                }

                setUser(userWithNewAvatar);
                setInProgress(false);
            }
        }
    };

    const handleClose = () => {
        setImage(null);
        setInProgress(false);
        onClose();
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("touchmove", handleTouchMove, { passive: false });
            container.addEventListener("touchend", handleTouchEnd);
            container.addEventListener("touchcancel", handleTouchEnd);
        }

        return () => {
            if (container) {
                container.removeEventListener("touchmove", handleTouchMove);
                container.removeEventListener("touchend", handleTouchEnd);
                container.removeEventListener("touchcancel", handleTouchEnd);
            }
        };
    }, [scale, handleTouchMove, image]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <ModalOverlay h="100%" w="100%" bg="teddy_blue.5069" />
            <ModalContent>
                <ModalCloseButton />
                <ModalBody>
                    <VStack h="100%" pt={10} gap={3}>
                        <Box ref={containerRef}>
                            {!image ? (
                                <Flex
                                    {...getRootProps()}
                                    border="2px dashed"
                                    borderColor="teddy_blue.300"
                                    borderRadius={8}
                                    width={isMobile ? 350 : 400}
                                    height={400}
                                    alignItems="center"
                                    justify="center"
                                    color="teddy_blue.300"
                                    bg="teddy_blue.50"
                                >
                                    <input {...getInputProps()} />
                                    <VStack alignContent="center">
                                        <Icon as={BsImage} fontSize="150" color="teddy_blue.300" />
                                        <Text fontSize="sm" textAlign="center">
                                            Drag 'n' drop some files here, or click to select files
                                        </Text>
                                    </VStack>
                                </Flex>
                            ) : (
                                <Box onWheel={handleWheel}>
                                    <AvatarEditor
                                        ref={(ref) => setEditor(ref)}
                                        image={image ?? ""}
                                        width={300}
                                        height={300}
                                        border={50}
                                        color={[255, 255, 255, 0.6]} // RGBA
                                        scale={scale}
                                        rotate={0}
                                    />
                                </Box>
                            )}
                        </Box>

                        <HStack>
                            <Button as={"label"} colorScheme="teddy_blue" isDisabled={inProgress}>
                                Choose from library
                                <Input type="file" accept="image/*" hidden onChange={handleChangeFile} />
                            </Button>
                            <Spacer />
                            {image && (
                                <Button
                                    colorScheme="teddy_yellow"
                                    isDisabled={inProgress}
                                    onClick={() => {
                                        if (editor) {
                                            const canvas = editor.getImageScaledToCanvas();
                                            canvas.toBlob(async (blob) => {
                                                if (!blob) {
                                                    handleError(intl.avatarEditError[langKey]);
                                                    return;
                                                }

                                                await onSave(blob);
                                            });
                                        }
                                    }}
                                >
                                    {inProgress ? <Spinner /> : "Save"}
                                </Button>
                            )}
                        </HStack>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
