import { Box, Icon } from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";

interface IProps {
    starIndex: number;
    rating: number;
    vw: string;
}

export const RatingStar: React.FC<IProps> = ({ rating, starIndex, vw }) => {
    const isFilled = starIndex <= Math.floor(rating);
    const isHalfFilled = starIndex === Math.ceil(rating) && !Number.isInteger(rating);

    return (
        <Box key={starIndex} position="relative" display="inline-block" w={vw} h={vw}>
            <Icon as={FaStar} color="teddy_grey.100" width="100%" height="100%" />
            {isFilled && (
                <Icon
                    as={FaStar}
                    color="teddy_yellow.400"
                    width="100%"
                    height="100%"
                    position="absolute"
                    top="0"
                    left="0"
                />
            )}
            {isHalfFilled && (
                <Box position="absolute" top="0" left="0" width="50%" height="100%" overflow="hidden">
                    <Icon
                        as={FaStar}
                        color="teddy_yellow.400"
                        width="300%"
                        height="100%"
                        position="absolute"
                        top="0"
                        left="-50%"
                    />
                </Box>
            )}
        </Box>
    );
};
