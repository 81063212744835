import { useBreakpoint } from "@chakra-ui/react";
import { useContext } from "react";
import { LanguageContext } from "../../../intl/languageContext";
import { IntlKey, Languages } from "../../../intl/intlLanguages";
import { isMobileScreen } from "../../../common/breakPoints";
import { ForStudentsDesktop } from "./forStudentsDesktop";
import { ForStudentsMobile } from "./forStudentsMobile";

export const ForStudents: React.FC = () => {
    const breakPoint = useBreakpoint({ ssr: false });
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return isMobileScreen(breakPoint) ? (
        <ForStudentsMobile langKey={langKey} />
    ) : (
        <ForStudentsDesktop langKey={langKey} />
    );
}