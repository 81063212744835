import { Box, Button, Center, Flex, Heading, Text } from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { Link } from "react-router-dom";
import { faqQuestions } from "../../faq/faqQuestions";

interface IProps {
    langKey: keyof IntlKey;
}

export const FaqMobile: React.FC<IProps> = ({ langKey }) => {
    return (
        <Flex
            h="100%"
            w="100%"
            flexDirection="column"
            borderRadius={16}
            bg="teddy_blue.50"
            justify="center"
            alignItems="center"
            gap={15}
            p={5}
        >
            <Text fontSize="sm" color="teddy_blue.500">
                FAQ's
            </Text>
            <Box w="100%">
                <Heading whiteSpace="pre-wrap" fontSize="xl">
                    {intl.faqHeader[langKey]}
                </Heading>
            </Box>
            <Flex w="100%" justify="center" alignItems="center" gap={15} pb={15} pt={5} flexDirection="column">
                {faqQuestions.slice(0, 4).map((q, index) => {
                    return (
                        <Link to={`faq/${index}`}>
                            <Center bg="white" h="100px" w="250px" p={5}>
                                <Text fontSize="md">{q.question[langKey]}</Text>
                            </Center>
                        </Link>
                    );
                })}
            </Flex>
            <Link to="faq">
                <Button colorScheme="teddy_blue">FAQ's</Button>
            </Link>
        </Flex>
    );
};
