import { sendPasswordResetEmail } from "firebase/auth";
import { firebaseAuth } from "../..";
import { IResponse } from "../../common/response";
import { IntlKey, intlErrorMessages } from "../../intl/intlLanguages";
import { User, getUserById } from "../user/user";

export const signInWithEmail = async (
    email: string,
    pwd: string,
    setUser: (user: User) => void,
    langKey: keyof IntlKey
): Promise<IResponse> => {
    const res = await firebaseAuth.doEmailAuth(email, pwd);
    if (!res.ok) {
        return { ok: false, message: res.message };
    }

    const userId = res.data?.user.uid ?? "";
    if (userId === "") {
        return {
            ok: false,
            message:
                res.message === "Invalid login or password"
                    ? intlErrorMessages.invalidLoginOrPassword[langKey]
                    : intlErrorMessages.loginGeneralError[langKey],
        };
    }

    const userRes = await getUserById(userId);
    if (!userRes.ok || !userRes.data) {
        return { ok: false, message: intlErrorMessages.loginGeneralError[langKey] };
    }

    setUser(userRes.data);
    return { ok: true };
};

export const restoreLoggedInUser = async (userId: string, setUser: (user: User) => void) => {
    if (userId === "") {
        return;
    }

    const userRes = await getUserById(userId);
    if (!userRes.ok || !userRes.data) {
        return;
    }

    setUser(userRes.data);
};

export const signOut = async () => {
    await firebaseAuth.signOut();
};

export const sendRecoverPasswordEmail = async (email: string): Promise<IResponse> => {
    try {
        if (!email) return { ok: false, message: "Invalid email!" };
        await sendPasswordResetEmail(firebaseAuth.Auth, email);
        return { ok: true };
    } catch (ex) {
        console.log(ex);
        return { ok: false, message: (ex as Error).message };
    }
};
