import { Box, Button, Flex, Heading, Image, ListItem, Text, UnorderedList, VStack } from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { Link } from "react-router-dom";

interface IProps {
    langKey: keyof IntlKey;
}

export const AboutDesktop: React.FC<IProps> = ({ langKey }) => {
    return (
        <Flex justify="space-between" p={20} pt={0} pb={10}>
            <VStack h="100%" textAlign="left" alignItems="start" gap={10} flexDirection="column" justify="flex-start">
                <Box>
                    <VStack w="40vw" pb={18} alignItems="left" gap={5}>
                        <Link to="about">
                            <Text color="teddy_blue.500" fontSize="sm">
                                {intl.aboutAnchor[langKey]}
                            </Text>
                        </Link>

                        <Heading fontWeight="bold" fontSize="4xl">
                            {intl.aboutHeader[langKey]}
                        </Heading>
                    </VStack>
                    <Box fontSize="md">
                        <Text>{intl.aboutDescr[langKey]}</Text>
                        <UnorderedList pl={5}>
                            <ListItem>{intl.aboutDescrFirstPoint[langKey]}</ListItem>
                            <ListItem>{intl.aboutDescrSecondPoint[langKey]}</ListItem>
                            <ListItem>{intl.aboutDescrThirdPoint[langKey]}</ListItem>
                        </UnorderedList>
                    </Box>
                </Box>
                <Link to="about">
                    <Button colorScheme="teddy_orange">{intl.aboutBtn[langKey]}</Button>
                </Link>
            </VStack>
            <Image boxSize="md" fit="contain" src="/illustrations/about-2.png" />
        </Flex>
    );
};
