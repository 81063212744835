import React, { useEffect, useState } from "react";
import { Languages } from "./intlLanguages";

interface ILanguageContextProps {
    lang: Languages;
    setLanguage: (lang: Languages) => void;
}

export const LanguageContext = React.createContext<ILanguageContextProps>({
    lang: Languages.en,
    setLanguage: () => {
        throw new Error("Language context has not been initialized");
    },
});

interface ILanguageContextProviderProps {
    lang?: Languages; // Make `lang` optional since it's not being used
    children?: React.ReactNode;
}

export const LanguageContextProvider: React.FC<ILanguageContextProviderProps> = ({ children }) => {
    const [currLang, setCurrLang] = useState<Languages>(() => {
        // Retrieve the language from local storage, if available
        const savedLang = localStorage.getItem("language") as Languages | null;
        // Validate the saved language
        if (savedLang && Object.values(Languages).includes(savedLang)) {
            return savedLang;
        }
        return Languages.en; // Default to 'en' if no language is saved or invalid language
    });

    useEffect(() => {
        // Save the language to local storage whenever it changes
        localStorage.setItem("language", currLang);
    }, [currLang]);

    const setLanguage = (lang: Languages) => {
        setCurrLang(lang);
    };

    return <LanguageContext.Provider value={{ lang: currLang, setLanguage }}>{children}</LanguageContext.Provider>;
};
