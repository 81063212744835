import { Button } from "@chakra-ui/react";
import { IntlKey, intl } from "../../intl/intlLanguages";
import { IoMdLogIn } from "react-icons/io";
import { UserMenu } from "../user/userMenu";
import { useContext } from "react";
import { UserContext } from "../user/userContext";
import { firebaseAuth } from "../..";

interface IProps {
    langKey: keyof IntlKey;
    onOpen: () => void;
}

export const LogInBtn: React.FC<IProps> = ({ langKey, onOpen }) => {
    const { user } = useContext(UserContext);

    return (
        <>
            {firebaseAuth.isSignedIn() && user ? (
                <UserMenu />
            ) : (
                <Button onClick={onOpen} size="sm" rightIcon={<IoMdLogIn />} colorScheme="teddy_blue">
                    {intl.loginBtn[langKey]}
                </Button>
            )}
        </>
    );
};
