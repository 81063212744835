import {
    Alert,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    SimpleGrid,
    Spinner,
    VStack,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { User, updateUser } from "../../user/user";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { getRandomColorScheme } from "../../../common/randomColor";
import { AvatarEditModal } from "../avatar/avatarEditModal";
import { useCallback, useEffect, useState } from "react";

interface IProps {
    langKey: keyof IntlKey;
    isOpen: boolean;
    user: User | null;
    isMobile: boolean;
    onClose: () => void;
    setUser: (user: User | null) => void;
}

export const StudentProfileEdit: React.FC<IProps> = ({ langKey, user, setUser, isOpen, onClose, isMobile }) => {
    const { onOpen: onAvatarEditOpen, isOpen: isAvatarEditOpen, onClose: onAvatarEditClose } = useDisclosure();
    const toast = useToast();

    const [name, setName] = useState("");
    const [nickname, setNickname] = useState("");

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
    });

    const [saveError, setSaveError] = useState("");
    const [inProgress, setInProgress] = useState(false);

    const validateField = (name: string, value: string) => {
        if (!value || value.length === 0) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: "This field is required." }));
            return false;
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
            return true;
        }
    };

    const setInitialState = useCallback(() => {
        setErrors({
            firstName: "",
            lastName: "",
        });

        setSaveError("");
        setInProgress(false);

        if (!user) return;
        setName(user.name);
        setNickname(user.nickname);
    }, [user]);

    const handleClose = () => {
        setInitialState();
        onClose();
    };

    const handleSubmit = async () => {
        if (!user) {
            setSaveError("Error occured during save process. Please, reload page and try again!");
            return;
        }

        setInProgress(true);

        const isNameValid = validateField("firstName", name);

        if (!isNameValid) {
            setInProgress(false);
            return;
        }

        const updatedUser = { ...user, name: name, nickname: nickname };
        const saveRes = await updateUser(updatedUser);
        if (!saveRes.ok) {
            setInProgress(false);
            setSaveError(saveRes.message ?? "Error occured during saving process!");
            return;
        }

        setUser(updatedUser);
        setInProgress(false);

        toast({
            title: "Saved",
            description: "Your profile has been successfully updated!",
            status: "success",
            duration: 2000,
        });
    };

    useEffect(() => {
        setInitialState();
    }, [user, setInitialState]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleClose} size="2xl">
                <ModalOverlay h="100%" w="100%" bg="teddy_blue.5069" />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack p={isMobile ? 3 : 5}>
                            <VStack gap={3}>
                                <Image
                                    borderRadius="full"
                                    boxSize={isMobile ? "130px" : "170px"}
                                    src={user?.avatarUrl}
                                    fit="contain"
                                    bg={`${getRandomColorScheme("")}.300`}
                                />
                                <Button
                                    isDisabled={inProgress}
                                    size="sm"
                                    colorScheme="teddy_blue"
                                    variant="ghost"
                                    onClick={onAvatarEditOpen}
                                >
                                    {intl.profileEditChangeAvatar[langKey]}
                                </Button>
                            </VStack>
                            <SimpleGrid w="100%" columns={isMobile ? 1 : 2} spacing={3}>
                                <FormControl isInvalid={!!errors.firstName}>
                                    <FormLabel fontWeight="bold">{intl.profileEditName[langKey]}</FormLabel>
                                    <Input
                                        isDisabled={inProgress}
                                        type="text"
                                        placeholder="First name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                                </FormControl>

                                <FormControl>
                                    <FormLabel fontWeight="bold">Nickname</FormLabel>
                                    <Input
                                        isDisabled={inProgress}
                                        placeholder=""
                                        type="text"
                                        value={nickname}
                                        onChange={(e) => setNickname(e.target.value)}
                                    />
                                </FormControl>
                            </SimpleGrid>

                            <VStack w="100%">
                                {saveError !== "" && <Alert status="error">{saveError}</Alert>}
                                <Button
                                    colorScheme="teddy_yellow"
                                    w={isMobile ? "100%" : "350px"}
                                    mt={4}
                                    type="submit"
                                    isDisabled={inProgress}
                                    onClick={handleSubmit}
                                >
                                    {inProgress ? <Spinner /> : "Save"}
                                </Button>
                            </VStack>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <AvatarEditModal
                langKey={langKey}
                isOpen={isAvatarEditOpen}
                onClose={onAvatarEditClose}
                user={user}
                setUser={setUser}
                isMobile={isMobile}
            />
        </>
    );
};
