import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { MsgStatus } from "../msgStatus";
import { Message } from "../../message";
import { useEffect, useRef } from "react";

interface IProps {
    userId: string;
    message: Message | undefined;
    observer: React.MutableRefObject<IntersectionObserver | null>;
}

export const MessageBox: React.FC<IProps> = ({ message, userId, observer }) => {
    const messageRef = useRef<HTMLDivElement | null>(null);
    const isSender = message?.senderId === userId;
    const bg = isSender ? "teddy_blue.500" : "teddy_blue.100";

    useEffect(() => {
        const refObj = observer.current;
        const currentMessage = messageRef.current;
        if (currentMessage && refObj) {
            refObj.observe(currentMessage);
        }

        return () => {
            if (currentMessage && refObj) {
                refObj.unobserve(currentMessage);
            }
        };
    }, [message, observer]);

    return (
        <Box w="100%" p={3}>
            {message && (
                <Flex
                    message-id={message.id}
                    message-status={message.status}
                    is-users-message={message.senderId === userId ? 1 : 0}
                    w="100%"
                    justify={isSender ? "flex-end" : "flex-start"}
                    ref={messageRef}
                >
                    <VStack
                        minW="70px"
                        maxW="450px"
                        borderRadius={8}
                        bg={bg}
                        color="white"
                        p={1}
                        gap={0}
                        borderTopLeftRadius={isSender ? 8 : 0}
                        borderTopRightRadius={isSender ? 0 : 8}
                    >
                        <Text p={1}>{message.text}</Text>
                        <Flex w="100%" justify="flex-end" alignItems="center" gap={1} pr={1}>
                            <Text fontSize={10} color="teddy_grey.50">
                                {moment(message.createdAt).format("HH:mm")}
                            </Text>
                            {isSender && (
                                <Box fontSize={12} color="teddy_grey.50">
                                    <MsgStatus status={message.status ?? 0} senderId={undefined} userId={undefined} />
                                </Box>
                            )}
                        </Flex>
                    </VStack>
                </Flex>
            )}
        </Box>
    );
};
