import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";

interface IProps {
    text: string;
    tagId: string;
    removeTag: (tagId: string) => void;
}

export const SearchTag: React.FC<IProps> = ({ text, tagId, removeTag }) => {
    return (
        <Tag key={tagId} size="lg" colorScheme={"teddy_blue"} variant="solid">
            <TagLabel>{text}</TagLabel>
            <TagCloseButton onClick={() => removeTag(tagId)} />
        </Tag>
    );
};
