import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    Icon,
    IconButton,
    Input,
    Spinner,
    Text,
    VStack,
    useBreakpoint,
    useToast,
} from "@chakra-ui/react";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { UserType } from "../user/userType";
import { useCallback, useContext, useEffect, useState } from "react";
import { isMobileScreen } from "../../common/breakPoints";
import { createUserWithEmailAndPassword } from "./signUpService";
import { UserContext } from "../user/userContext";
import { User } from "../user/user";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { IntlKey, Languages, intl } from "../../intl/intlLanguages";
import { LanguageContext } from "../../intl/languageContext";
import { OutletContextType } from "../outletContext";

export const SignUp: React.FC = () => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    const firstStep = 1;
    const createAccountStep = 2;
    const finalStep = 9;

    const navigate = useNavigate();

    const { user, setUser } = useContext(UserContext);
    const { onLogInOpen } = useOutletContext<OutletContextType>();

    const bp = useBreakpoint();
    const toast = useToast();

    const isMobile = isMobileScreen(bp);
    const [success, setSuccess] = useState(false);
    const [step, setStep] = useState(1);

    const emailRegex = /^[^@]+@[^@]+$/;
    const pwdRegex = /^.{6,20}$/;

    const [userType, setUserType] = useState(UserType.Undefined);
    const [isInProgress, setIsInProgress] = useState(false);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [pwd, setPwd] = useState("");
    const [rePwd, setRePwd] = useState("");

    const [errors, setErrors] = useState({
        email: "",
        name: "",
        pwd: "",
        rePwd: "",
    });

    const validateField = (name: string, value: string) => {
        if (!value || value.length === 0) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: "This field is required." }));
            return false;
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
            return true;
        }
    };

    const validateEmail = () => {
        if (emailRegex.test(email)) {
            setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
            return true;
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, email: "Email is not valid" }));
            return false;
        }
    };

    const validatePwd = () => {
        if (!pwdRegex.test(pwd)) {
            setErrors((prevErrors) => ({ ...prevErrors, pwd: "Password needs to be from 6 to 20 characters long" }));
            return false;
        }
        setErrors((prevErrors) => ({ ...prevErrors, pwd: "" }));

        if (pwd !== rePwd) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                rePwd: "Oops! Your passwords don't match. Please re-enter them.",
            }));
            return false;
        }

        setErrors((prevErrors) => ({ ...prevErrors, rePwd: "" }));
        return true;
    };

    const handleBack = () => {
        initState();
    };

    const initState = () => {
        setEmail("");
        setName("");
        setPwd("");
        setRePwd("");
        setUserType(UserType.Undefined);

        setSuccess(false);
        setIsInProgress(false);
        setStep(firstStep);
    };

    const handleStep1 = (userType: UserType) => {
        setUserType(userType);
        setStep(createAccountStep);
    };

    const handleStep2 = async () => {
        console.log("submit");
        setIsInProgress(true);
        const isValidName = validateField("name", name);
        const isValidEmail = validateEmail();
        const isValidPwd = validatePwd();

        if (!isValidName || !isValidEmail || !isValidPwd) {
            setIsInProgress(false);
            return;
        }

        const createRes = await createUserWithEmailAndPassword(
            {
                avatarUrl: "",
                email: email,
                name: name,
                id: "",
                type: userType,
                nickname: "",
            } as User,
            pwd,
            setUser
        );

        if (!createRes.ok) {
            setIsInProgress(false);
            toast({
                title: "Error",
                description: "Something went wrong! Please, try again later!",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setIsInProgress(false);
        setSuccess(true);
        setStep(finalStep);
    };

    const navigateHome = useCallback(() => {
        if (user && step === firstStep) navigate("/");
    }, [user, step, navigate]);

    useEffect(() => {
        navigateHome();
    }, [user, navigateHome]);

    return (
        <Flex h="100%" w="100%" justify="center" pt={isMobile ? "5vh" : "5vh"}>
            <Card minH={isMobile ? "80vh" : "70vh"} w={isMobile ? "95vw" : "50vw"} size="lg">
                <CardHeader pb={0}>{!success && <Heading>{intl.signUpHeader[langKey]}</Heading>}</CardHeader>
                <CardBody h="100%">
                    <VStack w="100%" h="100%" justify="start">
                        {step === firstStep && (
                            <VStack h="100%" justify="center" gap={5}>
                                <Heading fontSize="2xl">{intl.signUpHeader2[langKey]}</Heading>
                                <VStack>
                                    <Button
                                        w={isMobile ? "40vw" : "20vw"}
                                        size="lg"
                                        colorScheme="teddy_yellow"
                                        onClick={() => handleStep1(UserType.Student)}
                                    >
                                        {intl.signUpStudent[langKey]}
                                    </Button>
                                    <Button
                                        w={isMobile ? "40vw" : "20vw"}
                                        size="lg"
                                        colorScheme="teddy_blue"
                                        onClick={() => handleStep1(UserType.Teacher)}
                                    >
                                        {intl.signUpTeacher[langKey]}
                                    </Button>
                                </VStack>
                                <VStack pt={5} fontSize="sm">
                                    <Text color="teddy_grey.400">Already have an account?</Text>
                                    <Link to="#" onClick={onLogInOpen}>
                                        <Text color="teddy_orange.500">Log In</Text>
                                    </Link>
                                </VStack>
                            </VStack>
                        )}
                        {step === createAccountStep && (
                            <>
                                <VStack w="100%" h="100%">
                                    <FormControl isInvalid={!!errors.name}>
                                        <FormLabel fontWeight="bold">{intl.signUpName[langKey]}</FormLabel>
                                        <Input
                                            isDisabled={isInProgress}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.email}>
                                        <FormLabel fontWeight="bold">{intl.signUpEmail[langKey]}</FormLabel>
                                        <Input
                                            isDisabled={isInProgress}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                        />
                                        <FormErrorMessage>{errors.email}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.pwd}>
                                        <FormLabel fontWeight="bold">{intl.signUpPassword[langKey]}</FormLabel>
                                        <Input
                                            isDisabled={isInProgress}
                                            value={pwd}
                                            onChange={(e) => setPwd(e.target.value)}
                                            type="password"
                                        />
                                        <FormErrorMessage>{errors.pwd}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.rePwd}>
                                        <FormLabel fontWeight="bold">{intl.signUpReenterPassword[langKey]}</FormLabel>
                                        <Input
                                            isDisabled={isInProgress}
                                            value={rePwd}
                                            onChange={(e) => setRePwd(e.target.value)}
                                            type="password"
                                        />
                                        <FormErrorMessage>{errors.rePwd}</FormErrorMessage>
                                    </FormControl>
                                </VStack>
                                <Flex w="100%" justify="space-between" pt={5}>
                                    <IconButton
                                        isDisabled={isInProgress}
                                        variant="outline"
                                        colorScheme="teddy_grey"
                                        icon={<IoArrowBackOutline />}
                                        aria-label="back"
                                        onClick={handleBack}
                                    />

                                    <Button
                                        isDisabled={isInProgress}
                                        colorScheme="teddy_orange"
                                        rightIcon={<IoArrowForwardOutline />}
                                        onClick={handleStep2}
                                    >
                                        {isInProgress ? <Spinner /> : intl.signUpContinue[langKey]}
                                    </Button>
                                </Flex>
                            </>
                        )}

                        {step === finalStep && (
                            <Flex direction="column" justify="center" alignItems="center" h="100%" gap={5}>
                                <VStack>
                                    <Heading size="2xl">Welcome</Heading>
                                    <Text>Sign up has been completed!</Text>
                                </VStack>
                                {userType === UserType.Teacher && (
                                    <Text textAlign="center" pt={5}>
                                        To help students choose you as their teaher, please take a moment to complete
                                        your profile details.
                                    </Text>
                                )}
                                {userType === UserType.Student && (
                                    <Text textAlign="center" pt={5}>
                                        {intl.signUpStudentWelcome[langKey]}
                                    </Text>
                                )}
                                <Icon color="teddy_teal.400" fontSize="8xl" as={IoCheckmarkCircleOutline} />
                                <Link to={`/profile/${user?.type}/${user?.id}`}>
                                    <Button colorScheme="teddy_yellow">{intl.signUpGoToProfile[langKey]}</Button>
                                </Link>
                            </Flex>
                        )}
                    </VStack>
                </CardBody>
            </Card>
        </Flex>
    );
};
