import {
    getAuth,
    signInWithEmailAndPassword,
    setPersistence,
    browserLocalPersistence,
    UserCredential,
    Auth,
    createUserWithEmailAndPassword,
} from "firebase/auth";
import { IResponse, IResponseData } from "../common/response";
import { FirebaseApp, FirebaseError } from "firebase/app";

export class FirebaseAuth {
    constructor(app: FirebaseApp) {
        this.Auth = getAuth(app);
        setPersistence(this.Auth, browserLocalPersistence);
    }

    public Auth: Auth;

    public async doEmailAuth(email: string, pwd: string): Promise<IResponseData<UserCredential>> {
        try {
            const firebaseAuth = getAuth();
            const res = await signInWithEmailAndPassword(firebaseAuth, email, pwd);

            return { ok: true, data: res };
        } catch (ex) {
            const firebaseError = ex as FirebaseError;
            console.log(firebaseError);
            if (
                firebaseError.code === "auth/invalid-email" ||
                firebaseError.code === "auth/wrong-password" ||
                firebaseError.code === "user-not-found"
            )
                return { ok: false, message: "Invalid login or password" };

            console.log(`Error occured during login! ${ex}`);
            return { ok: false, message: "Error occured during login! Try again later!" };
        }
    }

    public async signUpWithEmailAndPassword(email: string, password: string): Promise<IResponseData<UserCredential>> {
        try {
            if (!email || !password) return { ok: false, message: "Invalid email or password!" };

            const res = await createUserWithEmailAndPassword(this.Auth, email, password);
            return { ok: true, data: res };
        } catch (ex) {
            const error = ex as Error;
            console.log(error);
            return { ok: false, message: error.message };
        }
    }

    public async signOut(): Promise<IResponse> {
        try {
            const firebaseAuth = getAuth();
            await firebaseAuth.signOut();

            return { ok: true };
        } catch (ex) {
            const error = ex as Error;
            console.log(error);
            return { ok: false, message: error.message };
        }
    }

    public isSignedIn() {
        const auth = getAuth();
        if (auth.currentUser) return true;

        return false;
    }
}
