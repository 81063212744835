// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyBRnT94TyynBZEcuLLRct2KOiTR4BOahNc",
    authDomain: "teddy-bear-teacher.firebaseapp.com",
    projectId: "teddy-bear-teacher",
    storageBucket: "teddy-bear-teacher.appspot.com",
    messagingSenderId: "1012944084352",
    appId: "1:1012944084352:web:2fd4bcaae3c0efb988f565",
    measurementId: "G-1F2VZM6Y7S",
};

// Initialize Firebase
