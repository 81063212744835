import { Box, Slide, useDisclosure } from "@chakra-ui/react";
import { IntlKey } from "../../../intl/intlLanguages";
import { User } from "../../user/user";
import { Chat } from "../chat";
import { ChatList } from "./chatList/chatList";
import { MessageList } from "./messageList/messageList";
import { useEffect } from "react";

interface IProps {
    langKey: keyof IntlKey;
    user: User | null;
    activeChat: Chat | null;
    setActiveChat: (chat: Chat | null) => void;
}

export const ChatMobile: React.FC<IProps> = ({ user, activeChat, setActiveChat }) => {
    const { isOpen, onToggle } = useDisclosure();
    const onChatOpen = (chat: Chat | null) => {
        setActiveChat(chat);
        onToggle();
    };

    useEffect(() => {
        if (activeChat) onToggle();
    }, [activeChat, onToggle]);

    return (
        <Box h="100%" w="100%" minH={!activeChat ? "100vh" : "0"}>
            <ChatList userId={user?.id} activeChat={activeChat} setActiveChat={onChatOpen} doOverflowY={false} />
            <Slide direction="right" in={isOpen} style={{ zIndex: 10 }}>
                <MessageList chat={activeChat} user={user} isMobile={true} setActiveChat={onChatOpen} />
            </Slide>
        </Box>
    );
};
