import { useContext } from "react";
import { Chat } from "../../chat";
import { Flex, Text } from "@chakra-ui/react";
import { ChatListItem } from "./chatListItem";
import { ChatsContext } from "../../chatsContext";

interface IProps {
    userId: string | undefined;
    activeChat: Chat | null;
    setActiveChat: (chat: Chat) => void;
    doOverflowY: boolean;
}

export const ChatList: React.FC<IProps> = ({ userId, activeChat, setActiveChat, doOverflowY }) => {
    const { chats } = useContext(ChatsContext);

    return (
        <Flex
            h="100%"
            w="100%"
            borderRadius={8}
            flexDir="column"
            alignItems="flex-start"
            justify="flex-start"
            gap={2}
            overflowY={doOverflowY ? "auto" : "unset"}
            maxH={doOverflowY ? "89vh" : "max-content"}
        >
            {chats && chats.length > 0 ? (
                chats
                    ?.sort(
                        (chat1, chat2) => chat2.lastModified.getMilliseconds() - chat1.lastModified.getMilliseconds()
                    )
                    .map((chat) => {
                        return (
                            <ChatListItem
                                key={chat.id}
                                chat={chat}
                                activeChatId={activeChat?.id}
                                setActiveChat={setActiveChat}
                                userId={userId}
                            />
                        );
                    })
            ) : (
                <Text></Text>
            )}
        </Flex>
    );
};
