import { Avatar, Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { Chat } from "../../chat";
import { MsgStatus } from "../msgStatus";
import moment from "moment";
import { getRandomColorScheme } from "../../../../common/randomColor";

interface IProps {
    chat: Chat;
    activeChatId: string | undefined;
    setActiveChat: (chat: Chat) => void;
    userId: string | undefined;
}

export const ChatListItem: React.FC<IProps> = ({ chat, activeChatId, setActiveChat, userId }) => {
    const participant = chat.participant;
    const chatName = participant?.name;
    const lastMsgCreatedAt = chat.lastMessage?.createdAt
        ? `${moment(chat.lastMessage?.createdAt).format("HH:mm")}`
        : "";

    const handleClick = (chat: Chat) => {
        setActiveChat(chat);
    };

    return (
        <>
            <HStack
                h="70px"
                w="100%"
                borderRadius={8}
                p={2}
                pr={3}
                pl={3}
                bg={activeChatId === chat.id ? "teddy_blue.85" : "teddy_blue.75"}
                _hover={{
                    bg: "teddy_blue.85",
                }}
                onClick={() => handleClick(chat)}
            >
                <Avatar
                    name={chatName}
                    src={participant?.avatarUrl}
                    bg={getRandomColorScheme(chatName ?? "") + ".200"}
                />
                <VStack w="100%" h="80%" justify="space-between" gap={0} alignItems="start">
                    <Flex w="100%" justify="space-between" alignItems="center">
                        <Text
                            fontWeight="bold"
                            fontSize="md"
                            maxW="180px"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            overflow="hidden"
                        >
                            {chatName}
                        </Text>
                        <Text color="teddy_grey.600" fontSize="xs">
                            {lastMsgCreatedAt}
                        </Text>
                    </Flex>
                    <Flex w="100%" justify="space-between" alignItems="center">
                        <Text
                            fontSize="sm"
                            color="teddy_grey.600"
                            maxW="180px"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            overflow="hidden"
                        >
                            {chat.lastMessage?.text}
                        </Text>
                        <Box color="teddy_blue.500" fontSize="xs">
                            <MsgStatus
                                status={chat.lastMessage?.status ?? 0}
                                senderId={chat.lastMessage?.senderId}
                                userId={userId}
                            />
                        </Box>
                    </Flex>
                </VStack>
            </HStack>
        </>
    );
};
