import { Dispatch } from "react";
import { Chat } from "./chat";
import { firestore } from "../..";
import {
    DocumentData,
    QueryDocumentSnapshot,
    Timestamp,
    collection,
    doc,
    getDoc,
    onSnapshot,
    orderBy,
    query,
    runTransaction,
    updateDoc,
    where,
} from "firebase/firestore";
import { User, getUserById } from "../user/user";
import { IResponse, IResponseData } from "../../common/response";
import { MessageDto } from "./dto/messageDto";
import { collections } from "../../db/collections";
import { Message } from "./message";
import { v4 as uuidv4 } from "uuid";
import { MessageStatus } from "./messageStatus";
import { ChatDto } from "./dto/chatDto";

export class ChatService {
    public async getMessageById(chatId: string, messageId: string): Promise<IResponseData<Message>> {
        try {
            if (!chatId || !messageId)
                return {
                    ok: false,
                    message:
                        "Invalid chatId or messageId while trying to load chat last message: chatId " +
                        chatId +
                        "; messageId " +
                        messageId,
                };

            const docRef = doc(firestore, "chats", chatId, "messages", messageId);
            const res = await getDoc(docRef);

            const data = res.data();
            if (!data)
                return {
                    ok: false,
                    message: "Messafe with id: " + messageId + " in  chat: " + chatId + " does not exist!",
                };

            return {
                ok: true,
                data: {
                    id: res.id,
                    createdAt: (data.createdAt as Timestamp).toDate(),
                    senderId: data.senderId,
                    recipientId: data.recipientId,
                    text: data.text,
                    status: data.status,
                    notificationSent: data.notificationSent,
                },
            };
        } catch (error) {
            console.log("Exception during getting messages by ids occured: " + error);
            return {
                ok: false,
                message: "Exception during getting messages by ids occured: " + (error as Error).message,
            };
        }
    }

    public async getChatFromFirestoreChange(
        doc: QueryDocumentSnapshot<DocumentData>,
        currentUserId: string | undefined
    ) {
        const data = doc.data();

        const participantId = (data.participants as string[]).find((p) => p !== currentUserId);
        const participant = await getUserById(participantId ?? "");
        const lastMessageRes = await this.getMessageById(doc.id, data.lastMessageId);

        const chat = {
            id: doc.id,
            createdAt: (data.createdAt as Timestamp).toDate(),
            lastModified: (data.lastModified as Timestamp)?.toDate(),
            participant: participant.data,
            lastMessage: lastMessageRes.data,
        } as Chat;

        return chat;
    }

    public async subscribeForChats(
        userId: string | undefined,
        setChats: Dispatch<React.SetStateAction<Chat[] | null>>
    ) {
        try {
            if (!userId) return;

            const q = query(collection(firestore, collections.chats), where("participants", "array-contains", userId));
            const unsubscribe = await onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach(async (docChange) => {
                    const doc = docChange.doc;
                    if (docChange.type === "added") {
                        const chat = await this.getChatFromFirestoreChange(doc, userId);
                        setChats((prev) => {
                            if (prev && prev.length > 0) return [...prev, chat];
                            else return [chat];
                        });
                    }

                    if (docChange.type === "modified") {
                        const chat = await this.getChatFromFirestoreChange(doc, userId);
                        console.log(JSON.stringify(chat));
                        setChats((prev) => {
                            if (prev && prev.length > 0) return prev.map((c) => (c.id === chat.id ? chat : c));
                            else return [chat];
                        });
                    }

                    if (docChange.type === "removed") {
                        setChats((prev) => prev?.filter((c) => c.id !== doc.id) ?? null);
                    }
                });
            });
            return unsubscribe;
        } catch (err) {
            console.log(`Error occured during subscription on chats: ${err} | StackTrace: ${(err as Error).stack}`);
        }
    }

    public addOrUpdateChat = async () => {};

    public async subscribeForMessagesV2(
        chatId: string | undefined,
        setMessages: Dispatch<React.SetStateAction<Message[]>>
    ) {
        if (!chatId) return;
        // const cache = await messagesCache.getMessages(chatId);

        const collectionRef = collection(firestore, collections.chats, chatId, "messages");
        const q = query(collectionRef, orderBy("createdAt", "asc"));
        const unsubscribe = await onSnapshot(
            q,
            (querySnapshot) => {
                querySnapshot.docChanges().forEach((docChange) => {
                    const doc = docChange.doc;
                    if (docChange.type === "added") {
                        const msg = this.convertMessageFromFirestore(doc.data(), doc.id);
                        // if (cache && cache.length > 0 && cache.some((x) => x._id === doc.id)) return;
                        setMessages((prev) => {
                            return [...prev, msg];
                        });
                    }

                    if (docChange.type === "modified") {
                        const msg = this.convertMessageFromFirestore(doc.data(), doc.id);
                        // messagesCache.updateMessage(chatId, msg);
                        setMessages((prev) => {
                            if (prev.length > 0)
                                return prev.map((m) => {
                                    if (m.id === msg.id) return msg;

                                    return m;
                                });

                            return [...prev, msg];
                        });
                    }

                    if (docChange.type === "removed") {
                        // messagesCache.removeMessage(chatId, doc.id);
                        setMessages((prev) => {
                            return prev.filter((m) => m.id !== doc.id);
                        });
                    }
                });
            },
            (error) => {
                console.log(`Error during subscription on chat ${chatId}: ${error}`);
            }
        );

        return unsubscribe;
    }

    public async sendMessage(text: string, user: User | null, chat: Chat): Promise<IResponse> {
        try {
            if (!chat || !chat.participant || !user || !user.id) return { ok: false, message: "Invalid input data!" };

            const msgId = uuidv4();
            const msg = {
                senderId: user.id,
                recipientId: chat.participant.id,
                text: text,
                createdAt: Timestamp.now(),
                status: MessageStatus.Sent,
                notificationSent: false,
            };
            const messagesRef = collection(firestore, collections.chats, chat.id, "messages");
            const chatRef = doc(firestore, collections.chats, chat.id);

            await runTransaction(firestore, async (transaction) => {
                await transaction.set(doc(messagesRef, msgId), msg);
                await transaction.set(chatRef, {
                    participants: [user.id, chat.participant?.id],
                    createdAt: Timestamp.fromDate(chat.createdAt),
                    lastMessageId: msgId,
                    lastModified: Timestamp.now(),
                } as ChatDto);
            });

            return { ok: true };
        } catch (err) {
            const error = err as Error;
            console.log(`Error occured while sending message: ${error.message} | StackTrace: ${error.stack}`);
            return { ok: false, message: error.message };
        }
    }

    public async setMessageReceived(messageId: string | null, chat: Chat | null): Promise<IResponse> {
        if (!messageId || !chat)
            return {
                ok: false,
                message: "Invalid input data: bad message or bad chat!",
            };

        try {
            const messageRef = doc(firestore, collections.chats, chat.id, "messages", messageId);
            await updateDoc(messageRef, {
                status: MessageStatus.Received,
            });

            const chatRef = doc(firestore, collections.chats, chat.id);
            await updateDoc(chatRef, {
                lastModified: Timestamp.now(),
            });

            return { ok: true };
        } catch (err) {
            const error = err as Error;
            console.log(
                `Error occured during setting message status to received: ${error.message} | StackTrace: ${error.stack}`
            );
            return { ok: false, message: error.message };
        }
    }

    public getNewMessagesStatus(chats: Chat[] | null, userId: string | undefined) {
        if (!chats || !userId) return;

        return chats.some((chat) => {
            if (!chat.lastMessage) return false;

            return chat.lastMessage.senderId !== userId && chat.lastMessage.status !== MessageStatus.Received;
        });
    }

    convertMessageFromFirestore(msg: DocumentData, id: string): Message {
        return {
            id: id,
            status: msg.status,
            createdAt: (msg.createdAt as Timestamp).toDate(),
            senderId: msg.senderId,
            recipientId: msg.recipientId,
            text: msg.text,
            notificationSent: msg.notificationSent,
        };
    }

    convertMessageToFirestore(msg: Message): MessageDto {
        return {
            senderId: msg.senderId,
            status: msg.status,
            text: msg.text,
            createdAt: Timestamp.fromDate(msg.createdAt),
        };
    }
}

const instance = new ChatService();
export { instance as chatService };
