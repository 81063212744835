import { Button, Flex, HStack, Heading, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { AiOutlineEdit } from "react-icons/ai";
import { User } from "../../user/user";
import { IoMail } from "react-icons/io5";

interface IProps {
    langKey: keyof IntlKey;
    user: User | null;
    profileUser: User | null;
    onModalEditOpen: () => void;
}

export const StudentProfileMobile: React.FC<IProps> = ({ langKey, user, profileUser, onModalEditOpen }) => {
    const isUsersProfile = user && profileUser && user.id === profileUser.id;

    return (
        <VStack w="100%">
            <VStack w="100%" h="80vh" p={5} gap={3} borderRadius={8} bg="teddy_yellow.25" justifyContent="center">
                <Flex w="100%" justify="center">
                    <Image borderRadius="full" boxSize="170px" src={profileUser?.avatarUrl} bg={"teddy_yellow.200"} />
                </Flex>
                <HStack w="100%" justify="center" pl={isUsersProfile ? 0 : 0}>
                    <Heading>{profileUser?.name}</Heading>
                </HStack>
                <HStack color="teddy_grey.400">
                    <Icon as={IoMail} />
                    <Text>{profileUser?.email}</Text>
                </HStack>
                {isUsersProfile && (
                    <Button
                        mt={10}
                        w="60%"
                        leftIcon={<AiOutlineEdit />}
                        colorScheme="teddy_yellow"
                        variant="solid"
                        size="md"
                        onClick={onModalEditOpen}
                    >
                        {intl.profileEdit[langKey]}
                    </Button>
                )}
            </VStack>
        </VStack>
    );
};
