const colorSchemes = ["teddy_blue", "teddy_orange", "teddy_teal", "teddy_yellow"];

const getHash = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    return Math.abs(hash) % colorSchemes.length;
};

export const getRandomColorScheme = (str: string) => {
    return colorSchemes[getHash(str)];
};
