import { Box, Button, Flex, HStack, Spacer } from "@chakra-ui/react";
import LanguageSelector from "../languageSelector/languageSelector";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { LogInBtn } from "../../logIn/logInBtn";
import { Link } from "react-router-dom";

interface IProps {
    langKey: keyof IntlKey;
    onOpen: () => void;
    showLogo: boolean;
}

export const NavbarDesktop: React.FC<IProps> = ({ langKey, onOpen, showLogo }) => {
    return (
        <Flex w="100%" direction="row" justify={"space-evenly"} gap={5} p={10} pt={5} pb={0}>
            <HStack>{showLogo && <Box h={10} w={50} bg="grey"></Box>} </HStack>
            <Spacer />
            <HStack>
                <Link to="/">
                    <Button size="sm" variant="ghost" colorScheme="teddy_blue" color="teddy_grey.500">
                        Home
                    </Button>
                </Link>
                <Button size="sm" variant="ghost" colorScheme="teddy_blue" color="teddy_grey.500">
                    {intl.navbarForStudents[langKey]}
                </Button>
                <Button size="sm" variant="ghost" colorScheme="teddy_blue" color="teddy_grey.500">
                    {intl.navbarForTeachers[langKey]}
                </Button>
                <Link to="faq">
                    <Button size="sm" variant="ghost" colorScheme="teddy_blue" color="teddy_grey.500">
                        {intl.navbarFaq[langKey]}
                    </Button>
                </Link>

                <Link to="about">
                    <Button size="sm" variant="ghost" colorScheme="teddy_blue" color="teddy_grey.500">
                        {intl.navbarAbout[langKey]}
                    </Button>
                </Link>

                <Button size="sm" variant="ghost" colorScheme="teddy_blue" color="teddy_grey.500">
                    {intl.navbarContact[langKey]}
                </Button>
            </HStack>
            <HStack>
                <LanguageSelector />
                <LogInBtn onOpen={onOpen} langKey={langKey} />
            </HStack>
        </Flex>
    );
};
