import { VStack, useBreakpoint } from "@chakra-ui/react";
import { isMobileScreen } from "../../common/breakPoints";
import { Hero } from "./hero/hero";
import { StepByStep } from "./stepByStep/stepByStep";
import { About } from "./about/about";
import { Faq } from "./faq/faq";
import { ForTeachers } from "./forTeachers/forTeachers";
import { ForStudents } from "./forStudents/forStudents";
import { ContactUs } from "./contactUs/contactUs";
import { useOutletContext } from "react-router-dom";
import { HomeFooter } from "./footer/homeFooter";
import { OutletContextType } from "../outletContext";

export const HomeContainer: React.FC = () => {
    const breakPoint = useBreakpoint({ ssr: false });
    const { onLogInOpen } = useOutletContext<OutletContextType>();

    return (
        <>
            <VStack
                textAlign="center"
                fontSize="xl"
                w="100%"
                h="100%"
                p={isMobileScreen(breakPoint) ? 1 : 10}
                pt={0}
                gap={0}
            >
                <Hero />
                <StepByStep />
                <About />
                <Faq />
                <ForStudents />
                <ForTeachers />
                <ContactUs />
            </VStack>
            <HomeFooter onLogInModalOpen={onLogInOpen} />
        </>
    );
};
