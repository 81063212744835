import React, { useState } from "react";
import { Chat } from "./chat";

interface IChatsContextProps {
    chats: Chat[] | null;
    setChats: React.Dispatch<React.SetStateAction<Chat[] | null>>;
}

export const ChatsContext = React.createContext<IChatsContextProps>({
    chats: [] as Chat[],
    setChats: () => {
        throw new Error("Chats context has not been inited");
    },
});

interface IChatsContextProviderProps {
    chats: Chat[] | null;
    children?: React.ReactNode;
}

export const ChatsContextProvider: React.FC<IChatsContextProviderProps> = ({ chats, children }) => {
    const [currChats, setCurrChats] = useState(chats);

    return (
        <ChatsContext.Provider value={{ chats: currChats, setChats: setCurrChats }}>{children}</ChatsContext.Provider>
    );
};
