import { extendTheme } from "@chakra-ui/react";

export const customTheme = extendTheme({
    colors: {
        teddy_blue: {
            25: "#f3f5fa",
            5069: "#778cc2b0",
            50: "#eceff6",
            85: "#e8ebf5",
            100: "#8b9ccb",
            200: "#778cc2",
            300: "#647bb9",
            400: "#506bb1",
            500: "#3D5AA8",
            600: "#375197",
            700: "#314886",
            800: "#2b3f76",
            900: "#253665",
        },
        teddy_orange: {
            50: "#fef3ea",
            100: "#f9b681",
            200: "#f8aa6c",
            300: "#f79e57",
            400: "#f69242",
            500: "#F5862D",
            600: "#dd7929",
            700: "#c46b24",
            800: "#ac5e1f",
            900: "#93501b",
        },
        teddy_teal: {
            50: "#eef9f9",
            100: "#9adcdc",
            200: "#89d6d6",
            300: "#78d0d1",
            400: "#67cacb",
            500: "#56C4C5",
            600: "#4db0b1",
            700: "#459d9e",
            800: "#3c898a",
            900: "#347676",
        },
        teddy_yellow: {
            10: "#FDF3E0",
            25: "#fffaf0",
            50: "#fff4e1",
            100: "#fedea4",
            200: "#fed995",
            300: "#fed386",
            400: "#fece77",
            500: "#FEC868",
            600: "#e5b45e",
            700: "#cba053",
            800: "#b28c49",
            900: "#98783e",
        },
        teddy_grey: {
            25: "#f2f2f2",
            50: "#e4e4e4",
            100: "#afafaf",
            200: "#a1a1a1",
            300: "#949494",
            400: "#868686",
            500: "#797979",
            600: "#6d6d6d",
            700: "#616161",
            800: "#555555",
            900: "#494949",
        },
    },
    fonts: {
        heading: `'Montserrat', 'IBM Plex Sans KR','Nanum Gothic', sans-serif`,
        body: `'Inter', 'IBM Plex Sans KR', 'Nanum Gothic', sans-serif`,
    },
});
