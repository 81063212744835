import { DocumentData, doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "../../..";
import { IResponse, IResponseData } from "../../../common/response";
import { collections } from "../../../db/collections";

export type TeacherInfo = {
    userId: string;
    minorAllowed: boolean;
    location: string;
    tags: string[];
    age: number;
    nationality: string;
    synopsis: string;
    kindsOfEnglish: string;
    kindsOfStudents: string;
    lessonStructure: string;
    locationDescription: string;
    experience: string;
    availability: string;
    price: number;
    priceCurrency: string;
    priceAdditionalInfo: string;
    additionalInfo: string;
    avgRating: number;
    reviewsCount: number;
    posted: boolean;
};

export const teacherInfoFromFirestore = (userId: string, data: DocumentData) => {
    return {
        userId: userId,
        minorAllowed: data.minorAllowed,
        location: data.location,
        tags: data.tags,
        age: data.age,
        nationality: data.nationality,
        synopsis: data.synopsis,
        kindsOfEnglish: data.kindsOfEnglish,
        kindsOfStudents: data.kindsOfStudents,
        lessonStructure: data.lessonStructure,
        locationDescription: data.locationDescription,
        experience: data.experience,
        availability: data.availability,
        price: data.price,
        priceCurrency: data.priceCurrency,
        priceAdditionalInfo: data.priceAdditionalInfo,
        additionalInfo: data.additionalInfo,
        avgRating: data.avgRating,
        reviewsCount: data.reviewsCount,
        posted: data.posted,
    } as TeacherInfo;
};

export const getTeacherInfoById = async (id: string): Promise<IResponseData<TeacherInfo>> => {
    try {
        if (!id || id.length === 0) return { ok: false, message: "No userId" };

        const docRef = doc(firestore, collections.teacherInfos, id);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists) return { ok: false, message: "No teacher with such id: " + id };

        const data = docSnap.data();
        if (!data) return { ok: false, message: "Teacher data is empty! userId: " + id };

        return {
            ok: true,
            data: teacherInfoFromFirestore(docSnap.id, data),
        };
    } catch (ex) {
        const error = ex as Error;
        console.log(error);
        return { ok: false, message: error.message };
    }
};

export const setOrUpdateTeacherInfo = async (userId: string, teacherInfo: TeacherInfo): Promise<IResponse> => {
    try {
        if (!userId) return { ok: false, message: "Error occured while creating teacher: No user id." };
        if (!teacherInfo) return { ok: false, message: "Error occured while creating teacher: no data." };

        await setDoc(doc(firestore, collections.teacherInfos, userId), teacherInfo, { merge: true });

        return { ok: true };
    } catch (ex) {
        const error = ex as Error;
        console.log(error);
        return { ok: false, message: error.message };
    }
};
