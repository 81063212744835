import { FirebaseApp } from "firebase/app";
import {
    FirebaseStorage,
    deleteObject,
    getDownloadURL,
    getStorage,
    listAll,
    ref,
    uploadBytesResumable,
} from "firebase/storage";
import { IResponse, IResponseData } from "../common/response";

export class FireStorage {
    constructor(app: FirebaseApp) {
        this.Storage = getStorage(app);
    }

    Storage: FirebaseStorage;

    async uploadFile(path: string, file: Blob | Uint8Array | ArrayBuffer): Promise<IResponseData<string>> {
        try {
            const fileRef = ref(this.Storage, path);
            const res = await uploadBytesResumable(fileRef, file);
            const url = await getDownloadURL(res.ref);

            return { ok: true, data: url };
        } catch (ex) {
            return { ok: false, message: (ex as Error).message };
        }
    }

    async deleteFile(path: string): Promise<IResponse> {
        try {
            const fileRef = ref(this.Storage, path);
            await deleteObject(fileRef);

            return { ok: true };
        } catch (ex) {
            return { ok: false, message: (ex as Error).message };
        }
    }

    async getListUrls(path: string): Promise<IResponseData<string[]>> {
        try {
            const folderRef = ref(this.Storage, path);
            const filesRefs = await listAll(folderRef);
            const res = await Promise.all(filesRefs.items.map(async (item) => await getDownloadURL(item)));

            return { ok: true, data: res };
        } catch (ex) {
            return { ok: false, message: (ex as Error).message };
        }
    }

    async deleteAllFiles(path: string): Promise<IResponse> {
        try {
            const folderRef = ref(this.Storage, path);
            const files = await listAll(folderRef);

            for (const file of files.items) {
                await deleteObject(file);
            }

            return { ok: true };
        } catch (ex) {
            return { ok: false, message: (ex as Error).message };
        }
    }
}
