import {
    Button,
    Divider,
    Flex,
    HStack,
    Heading,
    IconButton,
    Image,
    Tag,
    TagLabel,
    TagLeftIcon,
    Text,
    VStack,
    //Wrap,
    //WrapItem,
} from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { AiOutlineEdit } from "react-icons/ai";
import { TeachersTag } from "./teachersTag";
import { TeacherInfo } from "./teacherInfo";
import { User } from "../../user/user";
import { Tags } from "../../../tags/tags";
import { Locations } from "../../../locations/locations";
//import { PhotoProvider, PhotoView } from "react-photo-view";
import { IoLocationOutline } from "react-icons/io5";
import { Review } from "./review/review";
import { ReviewCard } from "./review/reviewCard";
import { useContext } from "react";
import { UserContext } from "../../user/userContext";
import { UserType } from "../../user/userType";
import { useNavigate } from "react-router-dom";

interface IProps {
    langKey: keyof IntlKey;
    teacherInfo: TeacherInfo | undefined;
    teachersUser: User | null;
    reviews: Review[] | undefined;
    certificatesUrls: string[];
    onEditModalOpen: () => void;
    onReviewModalOpen: () => void;
    onLogInOpen: () => void;
}

export const TeachersProfileMobile: React.FC<IProps> = ({
    langKey,
    teachersUser,
    teacherInfo,
    reviews,
    onEditModalOpen,
    onReviewModalOpen,
    onLogInOpen,
    //certificatesUrls,
}) => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const locationsStr = teacherInfo ? Locations[teacherInfo.location][langKey] : "";
    const headingFontSize = "md";
    const isUsersProfile = user?.id === teachersUser?.id;

    const handleSendMessage = () => {
        if (user) {
            navigate(`/messages/${teachersUser?.id}`);
            return;
        }

        onLogInOpen();
    };

    const handleWriteReview = () => {
        if (user) {
            onReviewModalOpen();
            return;
        }

        onLogInOpen();
    };

    return (
        <VStack w="100%" pb={5}>
            <VStack gap={3} p={5} borderRadius={8} bg="teddy_blue.50">
                <Flex w="100%" justify="center">
                    <Image borderRadius="full" boxSize="170px" src={teachersUser?.avatarUrl} bg={"teddy_blue.200"} />
                </Flex>
                <HStack w="100%" justify="center" pl={isUsersProfile ? 12 : 0}>
                    <Heading>{teachersUser?.name}</Heading>

                    {isUsersProfile && (
                        <IconButton
                            aria-label="edit profile"
                            icon={<AiOutlineEdit />}
                            variant="outline"
                            colorScheme="teddy_blue"
                            onClick={onEditModalOpen}
                        />
                    )}
                </HStack>
                <HStack w="100%" justify="center" gap={2} flexWrap="wrap">
                    <Tag colorScheme="teddy_blue" variant="solid">
                        <TagLeftIcon as={IoLocationOutline} />
                        <TagLabel>{locationsStr}</TagLabel>
                    </Tag>
                    <Tag colorScheme="teddy_blue" variant="solid">
                        <TagLabel>{`${intl.profileAge[langKey]}: ${teacherInfo?.age}`}</TagLabel>
                    </Tag>
                    <Tag colorScheme="teddy_blue" variant="solid">
                        <TagLabel>{`${intl.profileNationality[langKey]}: ${teacherInfo?.nationality}`}</TagLabel>
                    </Tag>
                    {isUsersProfile && teacherInfo && (
                        <Tag variant="solid" colorScheme={teacherInfo.posted ? "green" : "red"}>
                            <TagLabel>{teacherInfo.posted ? "Visible" : "Hidden"}</TagLabel>
                        </Tag>
                    )}
                </HStack>

                <VStack w="100%" align="left" py={3}>
                    <Text>{teacherInfo?.synopsis}</Text>
                </VStack>

                <Flex h="100%" w="100%" gap={3} justify="center" wrap="wrap" pt={3}>
                    {teacherInfo?.tags.map((tagId) => (
                        <TeachersTag key={tagId} text={Tags[tagId][langKey]} />
                    ))}
                </Flex>
                {isUsersProfile && (
                    <Button
                        w="full"
                        leftIcon={<AiOutlineEdit />}
                        colorScheme="teddy_blue"
                        variant="outline"
                        onClick={onEditModalOpen}
                    >
                        Edit
                    </Button>
                )}
                <HStack w="100%" justify="space-between" pt={5}>
                    {user?.type !== UserType.Teacher && (
                        <Button size="md" variant="solid" colorScheme="teddy_blue" onClick={handleWriteReview}>
                            {intl.profileWriteReview[langKey]}
                        </Button>
                    )}
                    {!isUsersProfile && (
                        <Button size="md" colorScheme="teddy_yellow" variant="solid" onClick={handleSendMessage}>
                            {intl.sendMessage[langKey]}
                        </Button>
                    )}
                </HStack>
            </VStack>

            {teacherInfo && (
                <VStack w="100%" bg="teddy_blue.50" borderRadius={8} p={5}>
                    <Heading alignSelf="start" size="md">
                        {intl.profilePricing[langKey]}
                    </Heading>
                    <Divider />
                    <HStack w="100%" fontSize="lg">
                        <Text fontSize="md" fontWeight="bold">
                            {`${intl.profilePricingBase}:`}
                        </Text>
                        <Text>{teacherInfo?.priceCurrency + teacherInfo?.price} / hr</Text>
                    </HStack>
                    <Text fontSize="sm">{teacherInfo.priceAdditionalInfo}</Text>
                </VStack>
            )}

            <VStack pt={5} fontSize={16} gap={5}>
                {teacherInfo?.kindsOfEnglish && (
                    <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                        <Heading fontSize={headingFontSize}>
                            In General; what kinds of English lessons are you best at teaching?
                        </Heading>
                        <Text>{teacherInfo?.kindsOfEnglish}</Text>
                    </VStack>
                )}
                {teacherInfo?.kindsOfStudents && (
                    <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                        <Heading fontSize={headingFontSize}>
                            In General; what kind of student will benefit most from your lessons?
                        </Heading>
                        <Text>{teacherInfo?.kindsOfStudents}</Text>
                    </VStack>
                )}
                {teacherInfo?.lessonStructure && (
                    <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                        <Heading fontSize={headingFontSize}>
                            What can a student expect a lesson with you to be like?
                        </Heading>
                        <Text>{teacherInfo?.lessonStructure}</Text>
                    </VStack>
                )}
                {teacherInfo?.locationDescription && (
                    <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                        <Heading fontSize={headingFontSize}>
                            Are you living in South Korea, or strictly an online teacher?
                        </Heading>
                        <Text>{teacherInfo?.locationDescription}</Text>
                    </VStack>
                )}
                {teacherInfo?.experience && (
                    <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                        <Heading fontSize={headingFontSize}>
                            Do you have any relevant experience or professional certifications you would like to share?
                        </Heading>
                        <Text>{teacherInfo?.experience}</Text>
                    </VStack>
                )}
                {teacherInfo?.availability && (
                    <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                        <Heading fontSize={headingFontSize}>When are you generally available for lessons?</Heading>
                        <Text>{teacherInfo?.availability}</Text>
                    </VStack>
                )}
                {teacherInfo?.availability && (
                    <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                        <Heading fontSize={headingFontSize}>Additional interesting information about yourself</Heading>
                        <Text>{teacherInfo?.availability}</Text>
                    </VStack>
                )}
                {reviews && reviews.length > 0 && (
                    <VStack w="100%" align="left" gap={5}>
                        <Heading fontSize="lg">Reviews</Heading>
                        <VStack w="100%">
                            {reviews?.map((review) => {
                                return <ReviewCard key={review.id} langKey={langKey} review={review} />;
                            })}
                        </VStack>
                    </VStack>
                )}
                {/* <VStack w="100%" align="left" pt={5}>
                    <PhotoProvider bannerVisible>
                        <Wrap spacing="5px">
                            {certificatesUrls.map((url) => {
                                return (
                                    <WrapItem>
                                        <PhotoView src={url}>
                                            <Image boxSize="150px" objectFit="cover" src={url} />
                                        </PhotoView>
                                    </WrapItem>
                                );
                            })}
                        </Wrap>
                    </PhotoProvider>
                </VStack> */}
            </VStack>
        </VStack>
    );
};
