import { useBreakpoint } from "@chakra-ui/react";
import { IntlKey, Languages } from "../../../intl/intlLanguages";
import { LanguageContext } from "../../../intl/languageContext";
import { useContext } from "react";
import { isMobileScreen } from "../../../common/breakPoints";
import { ContactUsMobile } from "./contactUsMobile";
import { ContactUsDesktop } from "./contactUsDesktop";

export const ContactUs: React.FC = () => {
    const breakPoint = useBreakpoint({ ssr: false });
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return isMobileScreen(breakPoint) ? <ContactUsMobile langKey={langKey} /> : <ContactUsDesktop langKey={langKey} />;
};
