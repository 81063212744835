import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { GoTag } from "react-icons/go";

interface IProps {
    text: string;
}

export const TeachersTag: React.FC<IProps> = ({ text }) => {
    return (
        <Tag colorScheme="teddy_blue" variant="outline">
            <TagLeftIcon boxSize="12px" as={GoTag} />
            <TagLabel>{text}</TagLabel>
        </Tag>
    );
};
