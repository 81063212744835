import { Box, Flex, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { IoIosMenu } from "react-icons/io";
import LanguageSelector from "../languageSelector/languageSelector";
import { LogInBtn } from "../../logIn/logInBtn";
import { Link } from "react-router-dom";

interface IProps {
    langKey: keyof IntlKey;
    onOpen: () => void;
}

export const NavbarMobile: React.FC<IProps> = ({ langKey, onOpen }) => {
    return (
        <Flex w="100%" flexDirection="row" justify="space-between" pb={3} pt={2}>
            <Box h={8} w={50} bg="grey"></Box>
            <HStack>
                <LanguageSelector />
                <LogInBtn langKey={langKey} onOpen={onOpen} />
                <Menu>
                    <MenuButton
                        as={IconButton}
                        icon={<IoIosMenu />}
                        _hover={{ bg: "teddy_blue.50" }}
                        _focus={{ bg: "teddy_blue.100" }}
                        _active={{ bg: "teddy_blue.200" }}
                        color="teddy_blue.500"
                        borderWidth={1}
                        borderColor="teddy_blue.500"
                        bg="white.900"
                        size="sm"
                    ></MenuButton>
                    <MenuList>
                        <Link to="/">
                            <MenuItem>Home</MenuItem>
                        </Link>
                        <MenuItem>{intl.navbarForStudents[langKey]}</MenuItem>
                        <MenuItem>{intl.navbarForTeachers[langKey]}</MenuItem>
                        <Link to="faq">
                            <MenuItem>{intl.navbarFaq[langKey]}</MenuItem>
                        </Link>
                        <Link to="about">
                            <MenuItem>{intl.navbarAbout[langKey]}</MenuItem>
                        </Link>
                        <MenuItem>{intl.navbarContact[langKey]}</MenuItem>
                    </MenuList>
                </Menu>
            </HStack>
        </Flex>
    );
};
