import { useOutletContext, useParams } from "react-router-dom";
import { OutletContextType } from "../outletContext";
import { LanguageContext } from "../../intl/languageContext";
import { useContext } from "react";
import { IntlKey, Languages, intl } from "../../intl/intlLanguages";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Heading,
    VStack,
    useBreakpoint,
} from "@chakra-ui/react";
import { isMobileScreen } from "../../common/breakPoints";
import { ScrollToTop } from "../../common/utilComponents/scrollToTop";
import { HomeFooter } from "../home/footer/homeFooter";
import { faqQuestions } from "./faqQuestions";

export const FaqPage: React.FC = () => {
    const { onLogInOpen } = useOutletContext<OutletContextType>();
    const { qid } = useParams();
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;
    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);

    return (
        <>
            <VStack p={isMobile ? 3 : 20} alignItems="left" gap={10}>
                <ScrollToTop />
                <Heading size="sm" color="teddy_orange.400">
                    FAQ
                </Heading>
                <Heading>{intl.faqPageHeader[langKey]}</Heading>
                <Accordion allowMultiple defaultIndex={qid ? [+qid] : [-1]}>
                    {faqQuestions.map((q, index) => {
                        return (
                            <AccordionItem key={index} py={5}>
                                <h2>
                                    <AccordionButton borderRadius={8} _hover={{ bg: "teddy_blue.50" }}>
                                        <Heading size="md" as="span" flex="1" textAlign="left">
                                            {q.question[langKey]}
                                        </Heading>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel pt={5}>{q.answer[langKey]}</AccordionPanel>
                            </AccordionItem>
                        );
                    })}
                </Accordion>
            </VStack>
            <HomeFooter onLogInModalOpen={onLogInOpen} />
        </>
    );
};
