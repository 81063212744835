import {
    Alert,
    Button,
    FormControl,
    FormErrorMessage,
    HStack,
    Heading,
    Input,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Spinner,
    Text,
    VStack,
    useBreakpoint,
    useToast,
} from "@chakra-ui/react";
import { IntlKey, Languages, intl, intlErrorMessages } from "../../intl/intlLanguages";
import { isMobileScreen } from "../../common/breakPoints";
import { SyntheticEvent, useContext, useState } from "react";
import { sendRecoverPasswordEmail, signInWithEmail } from "./logIn";
import { UserContext } from "../user/userContext";
import { LanguageContext } from "../../intl/languageContext";
import { IoArrowBackOutline } from "react-icons/io5";

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

export const LogInModal: React.FC<IProps> = ({ isOpen, onClose }) => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);
    const { setUser } = useContext(UserContext);

    const [modalState, setModalState] = useState("signin");
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);
    const [isLoginEmpty, setLoginEmpty] = useState(false);
    const [loginErrorMsg, setLoginErrorMsg] = useState("");
    const [showLoginErrorMessage, setShowLoginErrorMessage] = useState(false);

    const [pwdRecoveryInProgress, setPwdRecoveryInProgress] = useState(false);
    const [recoverEmail, setRecoverEmail] = useState("");

    const toast = useToast();

    const handleEmailChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setEmail(val);
        setLoginEmpty(false);
    };

    const handlePwdChange = (e: SyntheticEvent) => {
        const val = (e.target as HTMLInputElement).value;
        setPwd(val);
        setIsPasswordEmpty(false);
    };

    const setInitialState = () => {
        setEmail("");
        setPwd("");
        setIsPasswordEmpty(false);
        setLoginEmpty(false);
        setShowLoginErrorMessage(false);
        setLoginErrorMsg("");
        setIsLoading(false);
        setModalState("signin");
        setPwdRecoveryInProgress(false);
        setRecoverEmail("");
    };

    const onModalClose = () => {
        setInitialState();
        onClose();
    };

    const validate = (logIn: string, pwd: string) => {
        if (email === "") {
            setLoginEmpty(true);
            return false;
        }

        if (pwd === "") {
            setIsPasswordEmpty(true);
            return false;
        }

        return true;
    };

    const processLogIn = async (login: string, pwd: string) => {
        const res = await signInWithEmail(login, pwd, setUser, langKey);
        if (!res.ok) {
            setLoginErrorMsg(res.message ?? intlErrorMessages.loginGeneralError[langKey]);
            setShowLoginErrorMessage(true);
            return false;
        }

        return true;
    };

    const processRecoverPassword = async () => {
        const res = await sendRecoverPasswordEmail(recoverEmail);
        if (!res.ok) {
            toast({
                duration: 3000,
                title: "Error",
                description: res.message ?? "Somethings went wrong! Please, try again later!",
                status: "error",
            });
            return false;
        }

        return true;
    };

    const handleLogInClick = async () => {
        setIsLoading(true);
        setShowLoginErrorMessage(false);
        setLoginErrorMsg("");

        const validationRes = validate(email, pwd);
        if (!validationRes) {
            setIsLoading(false);
            return;
        }

        const logInRes = await processLogIn(email, pwd);
        setIsLoading(false);

        if (!logInRes) return;

        onModalClose();

        return;
    };

    const handleRecoverPasswordEmail = async () => {
        setPwdRecoveryInProgress(true);
        const recoverRes = await processRecoverPassword();
        if (!recoverRes) {
            setPwdRecoveryInProgress(false);
            return;
        }

        setPwdRecoveryInProgress(false);
        setModalState("recoverSuccess");
    };

    const returnToSignIn = () => {
        setModalState("signin");
        setInitialState();
    };

    return (
        <Modal isOpen={isOpen} onClose={onModalClose} isCentered>
            <ModalOverlay h="100%" w="100%" bg="teddy_blue.5069" />
            <ModalContent m={isMobile ? 2 : 0}>
                <ModalCloseButton />
                <ModalBody minH="40vh" pb={5} pt={isMobile ? 10 : 10} pl={isMobile ? 5 : 10} pr={isMobile ? 5 : 10}>
                    {modalState === "signin" && (
                        <VStack>
                            <ModalHeader fontSize="2xl">{intl.signInModalHeader[langKey]}</ModalHeader>
                            <FormControl isRequired isInvalid={isLoginEmpty}>
                                <Input
                                    placeholder={intl.signInModalEmail[langKey]}
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                {isLoginEmpty && (
                                    <FormErrorMessage>{intlErrorMessages.loginEmpty[langKey]}</FormErrorMessage>
                                )}
                            </FormControl>
                            <FormControl isRequired isInvalid={isPasswordEmpty}>
                                <Input
                                    placeholder={intl.signInModalPassword[langKey]}
                                    value={pwd}
                                    onChange={handlePwdChange}
                                    type="password"
                                />
                                {isPasswordEmpty && (
                                    <FormErrorMessage>{intlErrorMessages.loginPasswordEmpty[langKey]}</FormErrorMessage>
                                )}
                            </FormControl>
                            {showLoginErrorMessage && <Alert status="error">{loginErrorMsg}</Alert>}
                            <Link
                                onClick={() => {
                                    setModalState("forgotpwd");
                                }}
                            >
                                <Text color="teddy_blue.300" fontSize="sm">
                                    {intl.signInModalForgotPwd[langKey]}
                                </Text>
                            </Link>
                            <HStack fontSize="md" color="teddy_grey.300">
                                <Text>{intl.signInModalNoAccount[langKey]}</Text>
                                <Link href="/signup" color="teddy_orange.400">
                                    {intl.signInModalSignUp[langKey]}!
                                </Link>
                            </HStack>
                            <Spacer />
                            <Button colorScheme="teddy_blue" onClick={handleLogInClick} isDisabled={isLoading}>
                                {isLoading ? <Spinner /> : intl.signInModalHeader[langKey]}
                            </Button>
                        </VStack>
                    )}
                    {modalState === "forgotpwd" && (
                        <VStack gap={10} px={5}>
                            <Heading fontSize="2xl">{intl.signInModalRecoverHeader[langKey]}</Heading>
                            <VStack w="100%" alignItems="left">
                                <Text color="teddy_grey.500" fontSize="sm">
                                    {intl.signInModalRecoverDescription[langKey]}
                                </Text>
                                <Input
                                    isDisabled={pwdRecoveryInProgress}
                                    placeholder="Email"
                                    onChange={(e) => setRecoverEmail(e.target.value)}
                                    value={recoverEmail}
                                />
                                <Button
                                    isDisabled={pwdRecoveryInProgress}
                                    w="100%"
                                    colorScheme="teddy_blue"
                                    onClick={handleRecoverPasswordEmail}
                                >
                                    {pwdRecoveryInProgress ? <Spinner /> : intl.signInModalRecoverButton[langKey]}
                                </Button>
                            </VStack>
                            <Button
                                isDisabled={pwdRecoveryInProgress}
                                size="sm"
                                leftIcon={<IoArrowBackOutline />}
                                variant="ghost"
                                colorScheme="teddy_yellow"
                                onClick={returnToSignIn}
                            >
                                {intl.signInModalRecoverBackButton[langKey]}
                            </Button>
                        </VStack>
                    )}
                    {modalState === "recoverSuccess" && (
                        <VStack gap={5}>
                            <Heading fontSize="2xl">Password recovery</Heading>
                            <VStack w="100%" alignItems="left">
                                <Text>{intl.recoveryPasswordSuccess[langKey]}</Text>
                            </VStack>
                            <Button
                                isDisabled={pwdRecoveryInProgress}
                                size="sm"
                                leftIcon={<IoArrowBackOutline />}
                                variant="ghost"
                                colorScheme="teddy_yellow"
                                onClick={returnToSignIn}
                            >
                                Back to Sign In
                            </Button>
                        </VStack>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
