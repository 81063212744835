import { isMobileScreen } from "../../../common/breakPoints";
import { AboutMobile } from "./aboutMobile";
import { AboutDesktop } from "./aboutDesktop";
import { useBreakpoint } from "@chakra-ui/react";
import { LanguageContext } from "../../../intl/languageContext";
import { IntlKey, Languages } from "../../../intl/intlLanguages";
import { useContext } from "react";

export const About: React.FC = () => {
    const breakPoint = useBreakpoint({ ssr: false });
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return isMobileScreen(breakPoint) ? <AboutMobile langKey={langKey} /> : <AboutDesktop langKey={langKey} />;
};
