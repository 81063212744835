import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserType } from "./userType";
import { ErrorPage } from "../error/errorPage";
import { TeachersProfile } from "../profile/teacher/teachersProfile";
import { useBreakpoint } from "@chakra-ui/react";
import { LanguageContext } from "../../intl/languageContext";
import { IntlKey, Languages } from "../../intl/intlLanguages";
import { StudentProfile } from "../profile/student/studentProfile";

export const UserProfile: React.FC = () => {
    const { type } = useParams();
    const [userType, setUserType] = useState(0);
    const breakPoint = useBreakpoint({ ssr: false });
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    useEffect(() => {
        if (type) {
            setUserType(Number(type));
        }
    }, [type]);

    return (
        <>
            {userType === UserType.Student ? (
                <StudentProfile breakPoint={breakPoint} langKey={langKey} />
            ) : userType === UserType.Teacher ? (
                <TeachersProfile breakPoint={breakPoint} langKey={langKey} />
            ) : (
                <ErrorPage />
            )}
        </>
    );
};
