import { useBreakpoint } from "@chakra-ui/react";
import { useContext } from "react";
import { LanguageContext } from "../../../intl/languageContext";
import { IntlKey, Languages } from "../../../intl/intlLanguages";
import { isMobileScreen } from "../../../common/breakPoints";
import { ForTeachersDesktop } from "./forTeachersDesktop";
import { ForTeachersMobile } from "./forTeachersMobile";

export const ForTeachers: React.FC = () => {
    const breakPoint = useBreakpoint({ ssr: false });
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return isMobileScreen(breakPoint) ? (
        <ForTeachersMobile langKey={langKey} />
    ) : (
        <ForTeachersDesktop langKey={langKey} />
    );
};
