import { Avatar, Card, HStack, Text, VStack, useBreakpoint } from "@chakra-ui/react";
import { Review } from "./review";
import { useCallback, useEffect, useState } from "react";
import { User, getUserById } from "../../../user/user";
import { getRandomColorScheme } from "../../../../common/randomColor";
import { RatingStar } from "../rating/ratingStar";
import { isMobileScreen } from "../../../../common/breakPoints";
import { IntlKey, intl } from "../../../../intl/intlLanguages";

interface IProps {
    langKey: keyof IntlKey;
    review: Review;
}

export const ReviewCard: React.FC<IProps> = ({ langKey, review }) => {
    const bp = useBreakpoint();
    const isMobile = isMobileScreen(bp);
    const [studentUser, setStudentUser] = useState<User>();

    const loadUser = useCallback(async () => {
        const userRes = await getUserById(review.studentId);
        if (userRes.ok) setStudentUser(userRes.data);
    }, [review]);

    const name = studentUser?.nickname ? studentUser.nickname : studentUser?.name;
    const date = review.dateTime.toISOString().split("T")[0];

    useEffect(() => {
        loadUser();
    }, [loadUser]);
    return (
        <Card
            key={review.studentId + review.dateTime}
            w="100%"
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            p={3}
            fontSize="sm"
            borderColor="teddy_blue.50"
        >
            <HStack w="100%">
                <Avatar
                    size="lg"
                    name={name}
                    src={studentUser?.avatarUrl}
                    bg={getRandomColorScheme(name ?? "") + ".400"}
                />
                <VStack w="100%" alignItems="start">
                    <HStack w="100%">
                        <HStack w="100%" justify="space-between">
                            <HStack>
                                {[1, 2, 3, 4, 5].map((star) => (
                                    <RatingStar
                                        key={star}
                                        rating={review.rating}
                                        starIndex={star}
                                        vw={isMobile ? "4vw" : "1.5vw"}
                                    />
                                ))}
                            </HStack>
                            <Text color="teddy_grey.300">{date}</Text>
                        </HStack>
                    </HStack>
                    <Text fontSize="md">{review.text}</Text>
                    <HStack fontSize="sm" color="teddy_grey.400">
                        <Text>{intl.reviewCardLessons[langKey]}:</Text>
                        <Text>{review.lessonsCount}</Text>
                    </HStack>
                </VStack>
            </HStack>
        </Card>
    );
};
