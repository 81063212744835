import { Box, Button, Flex, Heading, Image, Link, Spacer, Text, VStack } from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";

interface IProps {
    langKey: keyof IntlKey;
}

export const ContactUsMobile: React.FC<IProps> = ({ langKey }) => {
    return (
        <VStack w="100%" p={5} pb={0} alignItems="flex-start">
            <Link color="teddy_blue.500" fontSize="sm">
                {intl.contactUsAnchor[langKey]}
            </Link>
            <Flex w="100%" justify="space-between" pt={5} flexDirection="column">
                <VStack alignItems="flex-start" justify="start">
                    <Heading fontSize="xl">{intl.contactUsHeader[langKey]}</Heading>
                    <Text whiteSpace="nowrap" fontSize="md" pb={3}>
                        {intl.contactUsDescr[langKey]}
                    </Text>
                    <Text fontWeight="bold" color="teddy_blue.500" fontSize="2xl">
                        +82 10 1234 5678
                    </Text>
                    <Text fontWeight="bold">teddybear.teaching@gmail.com</Text>
                    <Spacer />
                    <Box pb={5}>
                        <Button colorScheme="teddy_orange">{intl.contactUsBtn[langKey]}</Button>
                    </Box>
                </VStack>
                <Image boxSize="xs" fit="contain" src="/illustrations/contactUs.png" />
            </Flex>
        </VStack>
    );
};
