import {
    Flex,
    HStack,
    Icon,
    IconButton,
    Input,
    Select,
    Tag,
    TagLabel,
    TagRightIcon,
    Text,
    VStack,
} from "@chakra-ui/react";
import { IntlKey, intl } from "../../intl/intlLanguages";
import { Locations } from "../../locations/locations";
import { ISearchResult, ISearchState } from "./search";
import { Tags } from "../../tags/tags";
import { SearchTag } from "./searchTag";
import { IoClose, IoSearch } from "react-icons/io5";
import { ProfileCard } from "./profileCard";
import { HiOutlineArrowsUpDown } from "react-icons/hi2";

interface IProps {
    langKey: keyof IntlKey;
    input: string;
    setInput: (keyword: string) => void;
    searchState: ISearchState;
    setSearchState: (state: ISearchState) => void;
    searchRes: ISearchResult[];
    search: () => void;
    addTag: (tagId: string) => void;
    removeTag: (tagId: string) => void;
    removeAllTags: () => void;
    sortBy: string;
    setSortBy: (criteria: string) => void;
}

export const SearchDesktop: React.FC<IProps> = ({
    langKey,
    input,
    searchState,
    searchRes,
    sortBy,
    setInput,
    setSearchState,
    search,
    addTag,
    removeTag,
    removeAllTags,
    setSortBy,
}) => {
    return (
        <VStack w="100%" h="100%">
            <VStack w="100%" h="100%" p={3} bg="teddy_blue.50" borderRadius={8} gap={3}>
                <Flex w="100%" flexDirection="row" gap={5} justify="space-between">
                    <Input
                        w="140vw"
                        placeholder={intl.search[langKey]}
                        focusBorderColor="teddy_blue.50"
                        colorScheme="teddy_blue"
                        variant=""
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                // Check if Enter is pressed without the Shift key
                                e.preventDefault(); // Prevent the default action to avoid line breaks in input
                                search(); // Call your function to handle the message sending
                            }
                        }}
                    />
                    <Select
                        defaultValue={searchState.location}
                        focusBorderColor="teddy_blue.100"
                        colorScheme="teddy_blue"
                        onChange={(e) => setSearchState({ ...searchState, location: e.target.value })}
                    >
                        <option key="location-header" value="">
                            {intl.searchLocation[langKey]}
                        </option>
                        <option key="anywhere" value="">
                            {intl.searchLocationAnywhere[langKey]}
                        </option>
                        {Object.entries(Locations)
                            .filter((l) => l[0] !== "00000000-0000-0000-0000-0000000000000")
                            .map((location) => {
                                return (
                                    <option key={location[0]} value={location[0]}>
                                        {location[1][langKey]}
                                    </option>
                                );
                            })}
                    </Select>
                    <Select
                        placeholder={intl.searchAddFilters[langKey]}
                        focusBorderColor="teddy_blue.100"
                        colorScheme="teddy_blue"
                        onChange={(e) => addTag(e.target.value)}
                        value={""}
                    >
                        {Object.entries(Tags).map((tag) => {
                            return (
                                <option key={tag[0]} value={tag[0]}>
                                    {tag[1][langKey]}
                                </option>
                            );
                        })}
                    </Select>
                    <IconButton
                        colorScheme="teddy_blue"
                        variant="outline"
                        aria-label="search"
                        fontSize="lg"
                        icon={<IoSearch />}
                        onClick={search}
                    />
                </Flex>
            </VStack>
            <HStack w="100%" h="100%">
                <Flex w="100%" h="100%" gap={2} wrap="wrap" flexDirection="row" justify="center">
                    {searchState.tags.map((tagId) => {
                        return (
                            <SearchTag key={tagId} text={Tags[tagId][langKey]} tagId={tagId} removeTag={removeTag} />
                        );
                    })}
                    {searchState.tags.length > 1 && (
                        <Tag colorScheme="red" variant="solid" onClick={removeAllTags}>
                            <TagLabel>{intl.searchTagRemoveAll[langKey]}</TagLabel>
                            <TagRightIcon as={IoClose} />
                        </Tag>
                    )}
                </Flex>
                <HStack
                    w="24em"
                    fontSize="sm"
                    borderRadius={8}
                    borderWidth={1}
                    borderColor="teddy_grey.50"
                    p={2}
                    pr={0}
                    alignSelf="start"
                    color="teddy_grey.500"
                >
                    <Icon as={HiOutlineArrowsUpDown} />
                    <Text whiteSpace="nowrap">{intl.searchSortBy[langKey]}</Text>
                    <Select
                        value={sortBy}
                        w="100%"
                        size="sm"
                        variant="unstyled"
                        onChange={(e) => setSortBy(e.target.value)}
                    >
                        <option key="rating-high-to-low" value="rating-high">
                            {intl.searchSortByRatingHigh[langKey]}
                        </option>
                        <option key="rating-low-to-high" value="rating-low">
                            {intl.searchSortByRatingLow[langKey]}
                        </option>
                        <option key="price-high-to-low" value="price-high">
                            {intl.searchSortByPriceHigh[langKey]}
                        </option>
                        <option key="price-low-to-high" value="price-low">
                            {intl.searchSortByPriceLow[langKey]}
                        </option>
                    </Select>
                </HStack>
            </HStack>

            <VStack w="100%">
                {searchRes.map((res) => {
                    return <ProfileCard key={res.user.id} teacher={res} langKey={langKey} />;
                })}
            </VStack>
        </VStack>
    );
};
