import { Box, useBreakpoint } from "@chakra-ui/react";
import { IntlKey, Languages } from "../../../intl/intlLanguages";
import { LanguageContext } from "../../../intl/languageContext";
import { useContext } from "react";
import { isMobileScreen } from "../../../common/breakPoints";
import { FooterMobile } from "./footerMobile";
import { FooterDesktop } from "./footerDesktop";

interface IProps {
    onLogInModalOpen: () => void;
}

export const HomeFooter: React.FC<IProps> = ({ onLogInModalOpen }) => {
    const breakPoint = useBreakpoint({ ssr: false });
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return (
        <Box w="100%" p={isMobileScreen(breakPoint) ? 1 : 10} pb={1}>
            {isMobileScreen(breakPoint) ? (
                <FooterMobile langKey={langKey} onLogInModalOpen={onLogInModalOpen} />
            ) : (
                <FooterDesktop langKey={langKey} onLogInModalOpen={onLogInModalOpen} />
            )}
        </Box>
    );
};
