import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../..";
import { IResponseData } from "../../common/response";
import { TeacherInfo, teacherInfoFromFirestore } from "../profile/teacher/teacherInfo";
import { collections } from "../../db/collections";
import Fuse from "fuse.js";

export class SearchService {
    public async searchTeachers(
        keywords: string,
        locationId: string,
        tagsIds: string[]
    ): Promise<IResponseData<TeacherInfo[]>> {
        try {
            let finalResult: TeacherInfo[] = [];
            const teacherCollection = collection(firestore, collections.teacherInfos);

            const q =
                locationId.length === 0
                    ? query(teacherCollection)
                    : query(teacherCollection, where("location", "==", locationId));

            const byLocationSnapshot = await getDocs(q);
            const byLocation = byLocationSnapshot.docs.map((doc) => teacherInfoFromFirestore(doc.id, doc.data()));

            finalResult = byLocation.filter((t) => t.posted);

            if (tagsIds.length > 0) {
                finalResult = finalResult.filter((teacher) => {
                    return tagsIds.every((searchedTag) => teacher.tags.includes(searchedTag));
                });
            }

            if (keywords.length > 0) {
                const fuseOptions = {
                    keys: [
                        "nickname",
                        "synopsis",
                        "kindsOfEnglish",
                        "nationality",
                        "kindsOfStudents",
                        "lessonStructure",
                        "locationDescription",
                        "experience",
                        "availability",
                        "priceAdditionalInfo",
                        "additionalInfo",
                    ],
                    threshold: 0.5,
                };

                const fuse = new Fuse(finalResult, fuseOptions);
                const fuseRes = fuse.search(keywords);
                console.log(fuseRes);
                const byKeyword = fuseRes.map((res) => res.item);

                if (byKeyword.length > 0) finalResult = byKeyword;
            }

            return { ok: true, data: finalResult };
        } catch (ex) {
            console.log("Exception during getting messages by ids occured: " + ex);
            return {
                ok: false,
                message: "Exception during getting messages by ids occured: " + (ex as Error).message,
            };
        }
    }

    private getPriceInKrw(info: TeacherInfo) {
        const krwToUsd = 1300;
        return info.priceCurrency === "₩" ? info.price : info.price * krwToUsd;
    }

    public comparePriceHigh(teacherInfo1: TeacherInfo, teacherInfo2: TeacherInfo) {
        return this.getPriceInKrw(teacherInfo1) - this.getPriceInKrw(teacherInfo2);
    }
}

const instance = new SearchService();
export { instance as searchService };
