import { useParams } from "react-router-dom";
import { isMobileScreen } from "../../../common/breakPoints";
import { IntlKey } from "../../../intl/intlLanguages";
import { StudentProfileDesktop } from "./studentProfileDesktop";
import { StudentProfileMobile } from "./studentProfileMobile";
import { User, getUserById } from "../../user/user";
import { useCallback, useContext, useEffect, useState } from "react";
import { StudentProfileEdit } from "./studentProfileEdit";
import { useDisclosure } from "@chakra-ui/react";
import { UserContext } from "../../user/userContext";

interface IProps {
    breakPoint: string;
    langKey: keyof IntlKey;
}

export const StudentProfile: React.FC<IProps> = ({ breakPoint, langKey }) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { profileId } = useParams();
    const [profileUser, setProfileUser] = useState<User | null>(null);
    const { user, setUser } = useContext(UserContext);

    const isMobile = isMobileScreen(breakPoint);

    const loadProfileData = useCallback(async () => {
        if (!profileId || profileId.length <= 0) return;

        const profileUserRes = await getUserById(profileId);
        if (profileUserRes.ok && profileUserRes.data) {
            setProfileUser(profileUserRes.data);
        }
    }, [profileId]);

    useEffect(() => {
        loadProfileData();
    }, [loadProfileData]);

    return (
        <>
            {isMobile ? (
                <StudentProfileMobile
                    langKey={langKey}
                    user={user}
                    profileUser={profileUser}
                    onModalEditOpen={onOpen}
                />
            ) : (
                <StudentProfileDesktop
                    langKey={langKey}
                    user={user}
                    profileUser={profileUser}
                    onModalEditOpen={onOpen}
                />
            )}
            <StudentProfileEdit
                langKey={langKey}
                user={user}
                setUser={setUser}
                isOpen={isOpen}
                onClose={onClose}
                isMobile={isMobile}
            />
        </>
    );
};
