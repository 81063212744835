import { useBreakpoint } from "@chakra-ui/react";
import { useContext } from "react";
import { LanguageContext } from "../../../intl/languageContext";
import { IntlKey, Languages } from "../../../intl/intlLanguages";
import { isMobileScreen } from "../../../common/breakPoints";
import { StepByStepMobile } from "./stepByStepMobile";
import { StepByStepDesktop } from "./stepByStepDesktop";

export const StepByStep: React.FC = () => {
    const breakPoint = useBreakpoint({ ssr: false });
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return isMobileScreen(breakPoint) ? (
        <StepByStepMobile langKey={langKey} />
    ) : (
        <StepByStepDesktop langKey={langKey} />
    );
};
