import { DocumentData, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { firestore } from "../..";
import { collections } from "../../db/collections";
import { UserType } from "./userType";
import { IResponse, IResponseData } from "../../common/response";

export type User = {
    id: string;
    name: string;
    email: string;
    type: UserType;
    avatarUrl: string;
    nickname: string;
    settings: UserSettings;
};

export type UserSettings = {
    newMessagesEmailNotifications: boolean;
};

export const createUser = async (user: User): Promise<IResponse> => {
    try {
        if (!user) return { ok: false, message: "Error in user creation process! No user was provided" };
        const docRef = doc(firestore, collections.users, user.id);
        await setDoc(docRef, user);

        return { ok: true };
    } catch (ex) {
        const error = ex as Error;
        console.log(error);
        return { ok: false, message: error.message };
    }
};

export const getUserById = async (id: string): Promise<IResponseData<User>> => {
    try {
        const docRef = doc(firestore, collections.users, id);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists) return { ok: false, message: "No user with such id: " + id };

        const data = docSnap.data();
        if (!data) return { ok: false, message: "User data is empty! userId: " + id };

        return {
            ok: true,
            data: convertUserFromFirestore(data, docSnap.id),
        };
    } catch (ex) {
        const error = ex as Error;
        console.log(error);
        return { ok: false, message: error.message };
    }
};

export const getUsersByIds = async (ids: string[]): Promise<IResponseData<User[]>> => {
    try {
        if (!ids) return { ok: false, message: "No users ids were provided" };

        if (ids.length === 0) return { ok: true, data: [] };

        const q = query(collection(firestore, collections.users), where("__name__", "in", ids));
        const snapshot = await getDocs(q);
        if (snapshot.empty) return { ok: true, data: [] };

        const users = snapshot.docs.map((doc) => convertUserFromFirestore(doc.data(), doc.id));

        return { ok: true, data: users };
    } catch (ex) {
        const error = ex as Error;
        console.log(error);
        return { ok: false, message: error.message };
    }
};

export const updateUser = async (user: User): Promise<IResponse> => {
    try {
        if (!user) return { ok: false, message: "User update failed: no data" };

        await updateDoc(doc(firestore, collections.users, user.id), user);

        return { ok: true };
    } catch (ex) {
        const error = ex as Error;
        console.log(error);
        return { ok: false, message: error.message };
    }
};

export const updateUserSettings = async (userId: string, settings: UserSettings): Promise<IResponse> => {
    try {
        const userRef = doc(firestore, collections.users, userId);
        await updateDoc(userRef, { settings: settings });
        return { ok: true };
    } catch (ex) {
        const error = ex as Error;
        console.log(error);
        return { ok: false, message: error.message };
    }
};

export const getProfileUrl = (user: User | undefined | null) => {
    if (!user) return "";

    return `/profile/${user.type}/${user.id}`;
};

const convertUserFromFirestore = (data: DocumentData, id: string): User => {
    return {
        id: id,
        name: data.name,
        email: data.email,
        type: data.type,
        avatarUrl: data.avatarUrl,
        nickname: data.nickname,
        settings: {
            newMessagesEmailNotifications: data.settings.newMessagesEmailNotifications,
        },
    };
};
