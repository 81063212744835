import { useCallback, useContext, useEffect, useState } from "react";
import { IntlKey, Languages } from "../../intl/intlLanguages";
import { LanguageContext } from "../../intl/languageContext";
import { Box, useBreakpoint, useToast } from "@chakra-ui/react";
import { isMobileScreen } from "../../common/breakPoints";
import { SearchMobile } from "./searchMobile";
import { SearchDesktop } from "./searchDesktop";
import { TeacherInfo } from "../profile/teacher/teacherInfo";
import { searchService } from "./searchService";
import { User, getUsersByIds } from "../user/user";
import { HomeFooter } from "../home/footer/homeFooter";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../outletContext";

export interface ISearchState {
    location: string;
    tags: string[];
}

export interface ISearchResult {
    info: TeacherInfo;
    user: User;
}

export const Search: React.FC = () => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;
    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);
    const { onLogInOpen } = useOutletContext<OutletContextType>();
    const toast = useToast();

    const [input, setInput] = useState("");
    const [searchState, setSearchState] = useState<ISearchState>({
        location: "",
        tags: [],
    });
    const [sortBy, setSortBy] = useState("rating-high");
    const [foundTeachers, setFoundTeachers] = useState<ISearchResult[]>([]);

    const addTag = (tagId: string) => {
        if (!tagId || searchState.tags.some((id) => id === tagId)) return;

        setSearchState({ ...searchState, tags: [...searchState.tags, tagId] });
    };

    const removeTag = (tagId: string) => {
        const filteredTags = searchState.tags.filter((id) => id !== tagId);
        setSearchState({ ...searchState, tags: filteredTags });
    };

    const removeAllTags = () => {
        setSearchState({ ...searchState, tags: [] });
    };

    const handleSort = useCallback(() => {
        switch (sortBy) {
            case "price-high": {
                setFoundTeachers(foundTeachers.sort((t1, t2) => searchService.comparePriceHigh(t1.info, t2.info)));
                break;
            }
            case "price-low": {
                setFoundTeachers(foundTeachers.sort((t1, t2) => searchService.comparePriceHigh(t1.info, t2.info) * -1));
                break;
            }
            case "rating-high": {
                setFoundTeachers(foundTeachers.sort((t1, t2) => t1.info.avgRating - t2.info.avgRating));
                break;
            }
            case "rating-low": {
                setFoundTeachers(foundTeachers.sort((t1, t2) => t2.info.avgRating - t1.info.avgRating));
                break;
            }
            default:
                break;
        }
    }, [foundTeachers, sortBy]);

    const search = useCallback(async () => {
        const res = await searchService.searchTeachers(input, searchState.location, searchState.tags);
        if (!res.ok || !res.data) {
            toast({
                title: "Error",
                description: "Something went wrong! Please, try again later!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        const usersRes = await getUsersByIds(res.data.map((info) => info.userId));
        if (!usersRes.ok || !usersRes.data) {
            toast({
                title: "Error",
                description: "Something went wrong! Please, try again later!",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return;
        }

        const results = res.data.map((info) => {
            return { info: info, user: usersRes.data?.find((user) => user.id === info.userId) } as ISearchResult;
        });

        setFoundTeachers(results);
    }, [input, searchState, toast]);

    useEffect(() => {
        search();
    }, [searchState, search]);

    useEffect(() => {
        handleSort();
    }, [handleSort, sortBy]);

    return (
        <>
            <Box p={isMobile ? 1 : 10} pt={isMobile ? 0 : 5}>
                {isMobile ? (
                    <SearchMobile
                        langKey={langKey}
                        input={input}
                        setInput={setInput}
                        searchState={searchState}
                        setSearchState={setSearchState}
                        search={search}
                        searchRes={foundTeachers}
                        addTag={addTag}
                        removeTag={removeTag}
                        removeAllTags={removeAllTags}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    />
                ) : (
                    <SearchDesktop
                        langKey={langKey}
                        input={input}
                        setInput={setInput}
                        searchState={searchState}
                        setSearchState={setSearchState}
                        search={search}
                        searchRes={foundTeachers}
                        addTag={addTag}
                        removeTag={removeTag}
                        removeAllTags={removeAllTags}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    />
                )}
            </Box>
            <HomeFooter onLogInModalOpen={onLogInOpen} />
        </>
    );
};
