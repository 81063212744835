import { useCallback, useContext, useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Divider,
    Flex,
    Heading,
    Spinner,
    Switch,
    Text,
    VStack,
    useBreakpoint,
    useToast,
} from "@chakra-ui/react";
import { isMobileScreen } from "../../common/breakPoints";
import { UserContext } from "../user/userContext";
import { IntlKey, Languages, intl, intlErrorMessages } from "../../intl/intlLanguages";
import { LanguageContext } from "../../intl/languageContext";
import { sendRecoverPasswordEmail } from "../logIn/logIn";
import { updateUserSettings } from "../user/user";

export const UserSettings = () => {
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;
    const bp = useBreakpoint();
    const isMobile = isMobileScreen(bp);

    const { user, setUser } = useContext(UserContext);
    //const [currSetting, setCurrSetting] = useState("account");
    const toast = useToast();

    const [pwdRecoveryInProgress, setPwdRecoveryInProgress] = useState(false);
    const [enableMessagesNotifications, setEnableMessagesNotifications] = useState(false);

    const handleSendPasswordRecoveryEmail = async () => {
        setPwdRecoveryInProgress(true);
        if (!user || !user.email) {
            toast({
                duration: 3000,
                title: "Error",
                description: intlErrorMessages.userNotFound[langKey],
                status: "error",
            });
            setPwdRecoveryInProgress(false);
            return;
        }

        const res = await sendRecoverPasswordEmail(user.email);
        if (!res || !res.ok) {
            setPwdRecoveryInProgress(false);
            toast({
                duration: 5000,
                title: "Error",
                description: intlErrorMessages.somethingWentWrong[langKey],
                status: "error",
            });
            return;
        }

        toast({
            duration: 5000,
            title: "Success",
            description: intl.recoveryPasswordSuccess[langKey],
            status: "success",
        });

        setPwdRecoveryInProgress(false);
    };

    const handleMessagesNotificationsChange = async (enabled: boolean) => {
        if (!user) {
            toast({
                duration: 3000,
                title: "Error",
                description: intlErrorMessages.userNotFound[langKey],
                status: "error",
            });
            return;
        }
        const newSettings = { ...user.settings, newMessagesEmailNotifications: enabled };
        const res = await updateUserSettings(user.id, newSettings);
        if (!res.ok) {
            toast({
                duration: 5000,
                title: "Error",
                description: intlErrorMessages.somethingWentWrong[langKey],
                status: "error",
            });
            return;
        }

        setUser({ ...user, settings: newSettings });
    };

    const initState = useCallback(() => {
        setPwdRecoveryInProgress(false);
        if (user) setEnableMessagesNotifications(user?.settings.newMessagesEmailNotifications);
    }, [user]);

    useEffect(() => {
        initState();
    }, [user, initState]);

    return (
        <Flex justify="center" alignItems="center" w="100%" h="100%" py={isMobile ? 5 : 10}>
            <Card w={isMobile ? "full" : "55vw"} p={isMobile ? 3 : 5} pt={0}>
                <CardHeader>
                    <Heading color="teddy_orange.500" size={isMobile ? "lg" : "md"} pt={5}>
                        {intl.userMenuSettings[langKey]}
                    </Heading>
                </CardHeader>
                <CardBody w="100%" px={isMobile ? 5 : 10}>
                    <VStack alignItems="left">
                        <Heading size="md">{intl.settingsAccount[langKey]}</Heading>
                        <Divider />
                        <Flex direction="row" justify="space-between" alignItems="center">
                            <Text>{intl.settingsChangePwd[langKey]}</Text>
                            <Button
                                w={isMobile ? "full" : "20vw"}
                                isDisabled={pwdRecoveryInProgress}
                                variant="outline"
                                size="sm"
                                colorScheme="teddy_blue"
                                onClick={handleSendPasswordRecoveryEmail}
                            >
                                {pwdRecoveryInProgress ? <Spinner /> : intl.settingsSendPwdEmail[langKey]}
                            </Button>
                        </Flex>

                        <Heading size="md" pt={10}>
                            {intl.settingsNotifications[langKey]}
                        </Heading>
                        <Divider />
                        <Flex justify="space-between" alignItems="center">
                            <VStack alignItems="left" gap={0}>
                                <Text>{intl.settngsNotificationsNewMsg[langKey]}</Text>
                                <Text w="95%" fontSize="xs" color="teddy_grey.300">
                                    {intl.settingsNotificationsNeMsgDesc[langKey]}
                                </Text>
                            </VStack>
                            <Switch
                                isChecked={enableMessagesNotifications}
                                colorScheme="teddy_blue"
                                onChange={(e) => handleMessagesNotificationsChange(e.target.checked)}
                            />
                        </Flex>

                        {/* <Heading size="md" pt={5}>
                            Payment
                        </Heading>
                        <Divider /> */}
                    </VStack>
                </CardBody>
            </Card>
        </Flex>
    );

    // <VStack alignItems="left">
    //     <Heading color="teddy_orange.500" size="md" pl={10} pt={5}>
    //         Settings
    //     </Heading>
    //     <HStack w="100%" h="100%" p={10} py={5} pt={3} alignItems="top">
    //         <VStack alignItems="left" w="30vw">
    //             <UserSettingsMenuItem
    //                 isActive={currSetting === "account"}
    //                 onClick={() => {
    //                     setCurrSetting("account");
    //                 }}
    //                 colorScheme="teddy_blue"
    //                 text="Account"
    //             />
    //             <UserSettingsMenuItem
    //                 isActive={currSetting === "payment"}
    //                 onClick={() => {
    //                     setCurrSetting("payment");
    //                 }}
    //                 colorScheme="teddy_blue"
    //                 text="Payment"
    //             />
    //         </VStack>
    //         {currSetting === "account" && (
    //             <VStack w="100vw" h="85vh" borderRadius={8} bg="teddy_blue.25" p={5}>
    //                 <Text>Change password</Text>
    //                 <Input />
    //             </VStack>
    //         )}
    //         {currSetting === "payment" && <VStack w="100vw" h="85vh" borderRadius={8} bg="teddy_blue.25"></VStack>}
    //     </HStack>
    // </VStack>

    // <VStack w="100%" h="100%" py={5} px={10} alignItems="left">
    //     <VStack w="100%" h="100%" py={5} px={5} borderRadius={8} bg="teddy_blue.25" alignItems="left">
    //         <Heading size="lg">Settings</Heading>
    //         <VStack w="100%" h="85vh" alignItems="left" pt={5}>
    //             <Tabs variant="enclosed" colorScheme="teddy_blue">
    //                 <TabList>
    //                     <Tab fontWeight="600">Account</Tab>
    //                     <Tab fontWeight="600">Notification</Tab>
    //                     <Tab fontWeight="600">Payment</Tab>
    //                 </TabList>

    //                 <TabPanels>
    //                     <TabPanel>
    //                         <VStack w="100%" h="100%" alignItems="left">
    //                             <HStack>
    //                                 <Text>Change password</Text>
    //                             </HStack>
    //                         </VStack>
    //                     </TabPanel>
    //                 </TabPanels>
    //             </Tabs>
    //         </VStack>
    //     </VStack>
    // </VStack>
};
