import * as React from "react";
import { Box, ChakraProvider, useDisclosure } from "@chakra-ui/react";
import { LanguageContextProvider } from "../intl/languageContext";
import { Languages } from "../intl/intlLanguages";
import { customTheme } from "../theme/customTheme";
import { UserContextProvider } from "./user/userContext";
import { Navbar } from "./home/navbar/navbar";
import { OutletContainer } from "./outletContainer";
import { ChatsContextProvider } from "./chat/chatsContext";
import { LogInModal } from "./logIn/logInModal";

export const MainContainer: React.FC = () => {
    const { isOpen: isLogInOpen, onOpen: onLogInOpen, onClose: onLogInClose } = useDisclosure();
    return (
        <ChakraProvider theme={customTheme}>
            <LanguageContextProvider lang={Languages.en}>
                <ChatsContextProvider chats={[]}>
                    <UserContextProvider user={null}>
                        <Box pl={2} pr={2}>
                            <Navbar isOpen={isLogInOpen} onOpen={onLogInOpen} onClose={onLogInClose} showLogo={true} />
                            <OutletContainer onLogInOpen={onLogInOpen} />
                            {/* <BasicFooter /> */}
                            <LogInModal isOpen={isLogInOpen} onClose={onLogInClose} />
                        </Box>
                    </UserContextProvider>
                </ChatsContextProvider>
            </LanguageContextProvider>
        </ChakraProvider>
    );
};
