import { Box, Card, CardBody, CardHeader, Heading, Text, VStack, useBreakpoint } from "@chakra-ui/react";
import { IntlKey, Languages, intl } from "../../intl/intlLanguages";
import { useContext } from "react";
import { LanguageContext } from "../../intl/languageContext";
import { isMobileScreen } from "../../common/breakPoints";
import { HomeFooter } from "../home/footer/homeFooter";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../outletContext";
import { ScrollToTop } from "../../common/utilComponents/scrollToTop";

export const AboutPage: React.FC = () => {
    const { onLogInOpen } = useOutletContext<OutletContextType>();
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;
    const breakPoint = useBreakpoint({ ssr: false });
    const isMobile = isMobileScreen(breakPoint);

    return (
        <>
            <ScrollToTop />
            <Box px={isMobile ? 1 : 10} py={isMobile ? 5 : 10}>
                <Card p={isMobile ? 2 : 5}>
                    <CardHeader>
                        <Heading size="sm" color="teddy_orange.400">
                            About Us
                        </Heading>
                    </CardHeader>
                    <CardBody>
                        <VStack w="100%" alignItems="left" gap={10}>
                            <VStack alignItems="left" gap={3}>
                                <Heading size={isMobile ? "sm" : "md"}>{intl.aboutPageHeading[langKey]}</Heading>
                                <Heading size={isMobile ? "lg" : "xl"}>{intl.aboutPageHeading2[langKey]}</Heading>
                            </VStack>
                            <VStack alignItems="left" gap={5} color="teddy_grey.800">
                                <Text>{intl.aboutPageP1[langKey]}</Text>
                                <Text>{intl.aboutPageP2[langKey]}</Text>
                                <Text>{intl.aboutPageP3[langKey]}</Text>
                                <Text>{intl.aboutPageP4[langKey]}</Text>
                            </VStack>
                        </VStack>
                    </CardBody>
                </Card>
            </Box>
            <HomeFooter onLogInModalOpen={onLogInOpen} />
        </>
    );
};
