import { firebaseAuth } from "../..";
import { IResponse } from "../../common/response";
import { setOrUpdateTeacherInfo } from "../profile/teacher/teacherInfo";
import { User, createUser } from "../user/user";
import { UserType } from "../user/userType";

export const createUserWithEmailAndPassword = async (
    user: User,
    password: string,
    setUser: (user: User) => void
): Promise<IResponse> => {
    try {
        if (!user) return { ok: false, message: "Error occured during user creation" };

        const credentialsRes = await firebaseAuth.signUpWithEmailAndPassword(user.email, password);
        if (!credentialsRes.ok) return { ok: false, message: credentialsRes.message };

        const firebaseUser = credentialsRes.data?.user;
        if (!firebaseUser) return { ok: false, message: "Error occured during user creation" };

        const newUser = { ...user, id: firebaseUser.uid, settings: { newMessagesEmailNotifications: true } };
        const createRes = await createUser(newUser);
        if (!createRes.ok) return { ok: false, message: "Error occured while saving new user! " + createRes.message };

        if (user.type === UserType.Teacher) {
            const teacherInfoRes = await setOrUpdateTeacherInfo(firebaseUser.uid, {
                userId: firebaseUser.uid,
                age: 0,
                nationality: "",
                kindsOfEnglish: "",
                kindsOfStudents: "",
                lessonStructure: "",
                locationDescription: "",
                synopsis: "",
                availability: "",
                additionalInfo: "",
                price: 0,
                priceCurrency: "$",
                priceAdditionalInfo: "",
                experience: "",
                location: "00000000-0000-0000-0000-0000000000000",
                minorAllowed: false,
                reviewsCount: 0,
                avgRating: 0,
                tags: [],
                posted: false,
            });
            if (!teacherInfoRes.ok)
                return { ok: false, message: "Error occured while creating user! " + teacherInfoRes.message };
        }

        setUser(newUser);

        return { ok: true };
    } catch (ex) {
        const error = ex as Error;
        console.log(error);
        return { ok: false, message: error.message };
    }
};
