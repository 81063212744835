import { useCallback, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { UserContext } from "./user/userContext";
import { ChatsContext } from "./chat/chatsContext";
import { chatService } from "./chat/chatService";

interface IProps {
    onLogInOpen: () => void;
}

export const OutletContainer: React.FC<IProps> = ({ onLogInOpen }) => {
    const { user } = useContext(UserContext);
    const { setChats } = useContext(ChatsContext);

    const subscribeForChats = useCallback(async () => {
        if (!user) return;

        setChats([]);
        return await chatService.subscribeForChats(user.id, setChats);
    }, [user, setChats]);

    useEffect(() => {
        const unsub = subscribeForChats();
        return () => {
            unsub.then((func) => {
                if (func) func();
            });
        };
    }, [user, subscribeForChats]);

    return <Outlet context={{ onLogInOpen }} />;
};
