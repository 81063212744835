import { Box, Button, Flex, Heading, Square, Text } from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { Link } from "react-router-dom";
import { faqQuestions } from "../../faq/faqQuestions";

interface IProps {
    langKey: keyof IntlKey;
}

export const FaqDesktop: React.FC<IProps> = ({ langKey }) => {
    return (
        <Flex
            h="100%"
            w="100%"
            flexDirection="column"
            borderRadius={16}
            bg="teddy_blue.50"
            justify="center"
            alignItems="center"
            gap={25}
            p={10}
        >
            <Link to="faq">
                <Text fontSize="sm" color="teddy_blue.500">
                    FAQ's
                </Text>
            </Link>

            <Box w="60%">
                <Heading whiteSpace="pre-wrap" fontSize="3xl">
                    {intl.faqHeader[langKey]}
                </Heading>
            </Box>
            <Flex w="100%" justify="center" gap="5vw" pb={15} pt={5} flexWrap="wrap" rowGap="5vh">
                {faqQuestions.slice(0, 4).map((q, index) => {
                    return (
                        <Link to={`faq/${index}`}>
                            <Square bg="white" size="170px" p={2}>
                                <Text fontSize="md">{q.question[langKey]}</Text>
                            </Square>
                        </Link>
                    );
                })}
            </Flex>
            <Link to="faq">
                <Button colorScheme="teddy_blue">FAQ's</Button>
            </Link>
        </Flex>
    );
};
