import { Button, HStack, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { RiBearSmileLine } from "react-icons/ri";
import { useContext } from "react";
import { UserContext } from "./userContext";
import { LanguageContext } from "../../intl/languageContext";
import { IntlKey, Languages, intl } from "../../intl/intlLanguages";
import { Link } from "react-router-dom";
import { firebaseAuth } from "../..";
import { ChatsContext } from "../chat/chatsContext";
import { chatService } from "../chat/chatService";
import { BsEnvelopeExclamation } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

export const UserMenu: React.FC = () => {
    const { user, setUser } = useContext(UserContext);
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;
    const { chats } = useContext(ChatsContext);
    const hasNewMessages = chatService.getNewMessagesStatus(chats, user?.id);
    const newMessage = hasNewMessages ? <BsEnvelopeExclamation /> : <></>;

    const handleLogOutClick = async () => {
        await firebaseAuth.signOut();
        setUser(null);
    };

    return (
        <Menu>
            <MenuButton
                as={Button}
                leftIcon={<RiBearSmileLine />}
                _hover={{ bg: "teddy_blue.50" }}
                _focus={{ bg: "teddy_blue.100" }}
                _active={{ bg: "teddy_blue.200" }}
                color="teddy_blue.500"
                borderWidth={1}
                borderColor="teddy_blue.500"
                bg="white.900"
                size="sm"
            >
                <HStack>
                    <Text>{user?.name}</Text>
                    <Text color="teddy_orange.500">{newMessage}</Text>
                </HStack>
            </MenuButton>
            <MenuList fontSize="md">
                <Link to={`/profile/${user?.type}/${user?.id}`}>
                    <MenuItem>{intl.userMenuProfile[langKey]}</MenuItem>
                </Link>
                <Link to="/messages">
                    <MenuItem>
                        <HStack gap={1} alignItems="center">
                            <>{intl.userMenuMessages[langKey]}</>
                            {hasNewMessages && (
                                <Text color="teddy_orange.500">
                                    <GoDotFill color="teddy_orange.500" />
                                </Text>
                            )}
                        </HStack>
                    </MenuItem>
                </Link>
                <Link to="settings">
                    <MenuItem>{intl.userMenuSettings[langKey]}</MenuItem>
                </Link>
                <MenuItem onClick={handleLogOutClick}>{intl.userMenuLogout[langKey]}</MenuItem>
            </MenuList>
        </Menu>
    );
};
