import { Box, Button, Grid, GridItem, Heading, Image, VStack } from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { Link } from "react-router-dom";

interface IProps {
    langKey: keyof IntlKey;
}

export const HeroMobile: React.FC<IProps> = ({ langKey }) => {
    return (
        <Box w="100%" bg="teddy_yellow.10" borderRadius={16} pt={5} pb={9} px={5}>
            <Grid templateColumns="repeat(7, 1fr)">
                <GridItem colSpan={3}>
                    <VStack alignItems="left">
                        <Heading textAlign="left" fontSize="xl">
                            {intl.heroHeader[langKey]}
                        </Heading>
                        <Link to="search">
                            <Button colorScheme="teddy_orange" size="sm">
                                {intl.tryBtn[langKey]}
                            </Button>
                        </Link>
                    </VStack>
                </GridItem>
                <GridItem colSpan={4}>
                    <Image fit="contain" src="/illustrations/hero.png" />
                </GridItem>
            </Grid>
        </Box>
    );
};
