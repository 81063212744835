import { Box, HStack } from "@chakra-ui/react";
import { IntlKey } from "../../../intl/intlLanguages";
import { User } from "../../user/user";
import { ChatList } from "./chatList/chatList";
import { MessageList } from "./messageList/messageList";
import { Chat } from "../chat";

interface IProps {
    langKey: keyof IntlKey;
    user: User | null;
    activeChat: Chat | null;
    setActiveChat: (chat: Chat | null) => void;
}

export const ChatDesktop: React.FC<IProps> = ({ user, activeChat, setActiveChat }) => {
    return (
        <HStack h="100%" w="100%" align="start" gap={3}>
            <Box w="45%" h="100%">
                <ChatList userId={user?.id} activeChat={activeChat} setActiveChat={setActiveChat} doOverflowY={true} />
            </Box>
            <MessageList chat={activeChat} user={user} isMobile={false} setActiveChat={setActiveChat} />
        </HStack>
    );
};
