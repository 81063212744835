import {
    Box,
    Button,
    Flex,
    Heading,
    Image,
    Link,
    ListItem,
    Spacer,
    Text,
    UnorderedList,
    VStack,
} from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";

interface IProps {
    langKey: keyof IntlKey;
}

export const ForTeachersDesktop: React.FC<IProps> = ({ langKey }) => {
    return (
        <VStack w="100%" py={10} px={20} alignItems="flex-start" borderRadius={16} bg="teddy_teal.50">
            <Link color="teddy_blue.500" fontSize="sm">
                {intl.forTeachersAnchor[langKey]}
            </Link>
            <Flex justify="space-between" gap={5}>
                <Image boxSize="sm" fit="contain" src="/illustrations/forTeachers-2.png" />
                <VStack
                    h="100%"
                    textAlign="left"
                    alignItems="start"
                    gap={10}
                    flexDirection="column"
                    justify="flex-start"
                >
                    <Box>
                        <Box w="80%" pb={10}>
                            <Heading fontWeight="bold" fontSize="4xl">
                                {intl.forTeachersHeader[langKey]}
                            </Heading>
                        </Box>
                        <Box fontSize="md">
                            <Text>{intl.forTeachersDescr[langKey]}</Text>
                            <UnorderedList pl={5}>
                                <ListItem>{intl.forTeachersFirstPoint[langKey]}</ListItem>
                                <ListItem>{intl.forTeachersSecondPoint[langKey]}</ListItem>
                                <ListItem>{intl.forTeachersThirdPoint[langKey]}</ListItem>
                            </UnorderedList>
                        </Box>
                    </Box>
                    <Spacer />
                </VStack>
            </Flex>
            <Flex w="100%" justify="center">
                <Button colorScheme="teddy_yellow">{intl.forTeachersBtn[langKey]}</Button>
            </Flex>
        </VStack>
    );
};
