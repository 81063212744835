import { useCallback, useContext, useEffect, useState } from "react";
import { Box, useBreakpoint } from "@chakra-ui/react";
import { ChatMobile } from "./chatMobile";
import { ChatDesktop } from "./chatDesktop";
import { UserContext } from "../../user/userContext";
import { LanguageContext } from "../../../intl/languageContext";
import { IntlKey, Languages } from "../../../intl/intlLanguages";
import { isMobileScreen } from "../../../common/breakPoints";
import { Chat } from "../chat";
import { useNavigate, useParams } from "react-router-dom";
import { ChatsContext } from "../chatsContext";
import { getUserById } from "../../user/user";
import { v4 as uuidv4 } from "uuid";

export const ChatContainer: React.FC = () => {
    const navigate = useNavigate();
    const { participantId } = useParams();

    const { user } = useContext(UserContext);
    const { lang } = useContext(LanguageContext);
    const { chats } = useContext(ChatsContext);

    const breakPoint = useBreakpoint({ ssr: false });
    const langKey = Languages[lang] as keyof IntlKey;

    const [activeChat, setActiveChat] = useState<Chat | null>(null);

    const setOrCreateChat = useCallback(async () => {
        if (chats?.length === 0 && !participantId) return;

        const foundChat = chats?.find((x) => x.participant?.id === participantId);
        if (foundChat) {
            setActiveChat(foundChat);
            return;
        }

        if (!participantId) return;

        const participantRes = await getUserById(participantId);
        if (!participantRes.ok || !participantRes.data) return;

        setActiveChat({
            id: uuidv4(),
            createdAt: new Date(),
            lastMessage: undefined,
            lastModified: new Date(),
            participant: participantRes.data,
        });
    }, [participantId, chats]);

    useEffect(() => {
        setOrCreateChat();
    }, [setOrCreateChat]);

    useEffect(() => {
        if (!user) navigate("/");
    }, [navigate, user]);

    return (
        <Box w="100%" h="100%" p={isMobileScreen(breakPoint) ? 3 : 10} pt={5} pb={0}>
            {isMobileScreen(breakPoint) ? (
                <ChatMobile langKey={langKey} user={user} activeChat={activeChat} setActiveChat={setActiveChat} />
            ) : (
                <ChatDesktop langKey={langKey} user={user} activeChat={activeChat} setActiveChat={setActiveChat} />
            )}
        </Box>
    );
};
