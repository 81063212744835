import {
    Timestamp,
    average,
    collection,
    doc,
    getAggregateFromServer,
    getCountFromServer,
    getDocs,
    setDoc,
} from "firebase/firestore";
import { firestore } from "../../../..";
import { collections } from "../../../../db/collections";
import { IResponse, IResponseData } from "../../../../common/response";

export type Review = {
    id: string;
    teacherId: string;
    studentId: string;
    rating: number;
    text: string;
    lessonsCount: number;
    dateTime: Date;
};

export const addReviewAndUpdateRating = async (review: Review): Promise<IResponse> => {
    try {
        const reviewsColl = collection(
            firestore,
            collections.teacherInfos,
            review.teacherId,
            collections.teacherInfoReviews
        );

        const reviewDocRef = doc(reviewsColl, review.id);
        await setDoc(reviewDocRef, {
            dateTime: Timestamp.fromDate(review.dateTime),
            studentId: review.studentId,
            text: review.text,
            rating: review.rating,
            lessonsCount: review.lessonsCount,
        });

        await calculateRating(review.teacherId);

        return { ok: true };
    } catch (ex) {
        const error = ex as Error;
        console.log(error);
        return { ok: false, message: error.message };
    }
};

export const calculateRating = async (teacherId: string) => {
    try {
        const reviewsColl = collection(firestore, collections.teacherInfos, teacherId, collections.teacherInfoReviews);

        const reviewCountSnap = await getCountFromServer(reviewsColl);
        const currReviewCount = reviewCountSnap.data().count;
        const avgRating = (await getAggregateFromServer(reviewsColl, { avgRating: average("rating") })) ?? 0;

        const teacherInfoRef = doc(firestore, collections.teacherInfos, teacherId);
        await setDoc(
            teacherInfoRef,
            {
                avgRating: avgRating.data().avgRating,
                reviewsCount: currReviewCount,
            },
            { merge: true }
        );
    } catch (ex) {
        const error = ex as Error;
        console.log(error);
        return { ok: false, message: error.message };
    }
};

export const getTeacherReviews = async (teacherId: string): Promise<IResponseData<Review[]>> => {
    try {
        const collectionRef = collection(
            firestore,
            collections.teacherInfos,
            teacherId,
            collections.teacherInfoReviews
        );

        const snapshot = await getDocs(collectionRef);
        const reviews = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
                id: doc.id,
                lessonsCount: data.lessonsCount,
                rating: data.rating,
                teacherId: data.teacherId,
                text: data.text,
                studentId: data.studentId,
                dateTime: (data.dateTime as Timestamp).toDate(),
            } as Review;
        });

        return { ok: true, data: reviews };
    } catch (ex) {
        const error = ex as Error;
        console.log(error);
        return { ok: false, message: error.message };
    }
};
