import { useBreakpoint } from "@chakra-ui/react";
import { useContext } from "react";
import { LanguageContext } from "../../../intl/languageContext";
import { IntlKey, Languages } from "../../../intl/intlLanguages";
import { isMobileScreen } from "../../../common/breakPoints";
import { FaqMobile } from "./faqMobile";
import { FaqDesktop } from "./faqDesktop";

export const Faq: React.FC = () => {
    const breakPoint = useBreakpoint({ ssr: false });
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return isMobileScreen(breakPoint) ? <FaqMobile langKey={langKey} /> : <FaqDesktop langKey={langKey} />;
};
