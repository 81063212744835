import {
    Avatar,
    Button,
    Card,
    Flex,
    HStack,
    Heading,
    Text,
    VStack,
    Link as ChakraLink,
    Divider,
} from "@chakra-ui/react";
import { GoLocation } from "react-icons/go";
import { ISearchResult } from "./search";
import { TeachersTag } from "../profile/teacher/teachersTag";
import { Tags } from "../../tags/tags";
import { IntlKey, intl } from "../../intl/intlLanguages";
import { getRandomColorScheme } from "../../common/randomColor";
import { Locations } from "../../locations/locations";
import { Link, useNavigate } from "react-router-dom";
import { RatingStar } from "../profile/teacher/rating/ratingStar";
import { User } from "../user/user";

interface IProps {
    langKey: keyof IntlKey;
    name: string;
    teacher: ISearchResult;
    user: User | null;
    onLoginOpen: () => void;
}

export const ProfileCardDesktop: React.FC<IProps> = ({ langKey, user, name, teacher, onLoginOpen }) => {
    const navigate = useNavigate();
    const handleSendMessage = () => {
        if (user) {
            navigate(`/messages/${teacher.user.id}`);
            return;
        }

        onLoginOpen();
    };

    return (
        <Card
            key={teacher.user.id}
            w="100%"
            direction={{ base: "column", sm: "row" }}
            overflow="hidden"
            variant="outline"
            py={3}
            px={5}
            fontSize="sm"
            borderColor="teddy_blue.50"
            _hover={{
                bg: "teddy_blue.50",
            }}
        >
            <Flex w="100%" direction="row" alignItems="top" gap={5}>
                <VStack>
                    <Avatar
                        boxSize="10vw"
                        name={name}
                        src={teacher.user.avatarUrl}
                        bg={getRandomColorScheme(name) + ".400"}
                    />
                    <HStack>
                        {[1, 2, 3, 4, 5].map((i) => (
                            <RatingStar key={i} rating={teacher.info.avgRating} starIndex={i} vw="1.5vw" />
                        ))}
                        {/* <Text>{`(${Math.round(teacher.info.avgRating * 10) / 10})`}</Text> */}
                    </HStack>
                    <ChakraLink href={`/profile/${teacher.user.type}/${teacher.user.id}`}>{`${
                        teacher.info.reviewsCount
                    } ${teacher.info.reviewsCount > 1 ? "reviews" : "review"}`}</ChakraLink>
                </VStack>

                <VStack w="100%" alignItems="left">
                    <VStack alignItems="left">
                        <Heading size="lg">{name}</Heading>
                        <Text>{teacher.info.synopsis}</Text>
                    </VStack>
                    <Flex gap={2} pt={2} pb={2} flexWrap="wrap">
                        {teacher.info.tags.map((tag) => (
                            <TeachersTag text={Tags[tag][langKey]} key={tag} />
                        ))}
                    </Flex>
                </VStack>

                <Divider orientation="vertical" />

                <VStack justify="space-between">
                    <VStack w="100%" alignItems="left">
                        <Text
                            alignSelf="start"
                            fontSize="xl"
                            fontWeight="bold"
                        >{`${teacher.info.priceCurrency}${teacher.info.price}`}</Text>
                        <Divider />
                        <HStack fontSize="md">
                            <GoLocation />
                            <Text>{Locations[teacher.info.location][langKey]}</Text>
                        </HStack>
                        {/* <Text>{teacher.info.priceAdditionalInfo}</Text> */}
                        <Text>{`${intl.profileNationality[langKey]}: ${teacher.info.nationality}`}</Text>
                    </VStack>
                    <Divider />
                    <HStack w="100%" gap={2} minW="220px" justify="center">
                        <Link to={`/profile/${teacher.user.type}/${teacher.user.id}`} target="_blank">
                            <Button size="sm" colorScheme="teddy_blue" variant="outline">
                                {intl.profileCardSeeProfile[langKey]}
                            </Button>
                        </Link>
                        <Button size="sm" colorScheme="teddy_blue" onClick={handleSendMessage}>
                            {intl.sendMessage[langKey]}
                        </Button>
                    </HStack>
                </VStack>
            </Flex>
        </Card>
    );
};
