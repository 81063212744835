import React, { useEffect, useState } from "react";
import { User, getUserById } from "./user";
import { onAuthStateChanged } from "firebase/auth";
import { firebaseAuth } from "../..";

interface IUserContextProps {
    user: User | null;
    setUser: (user: User | null) => void;
}

export const UserContext = React.createContext<IUserContextProps>({
    user: {} as User,
    setUser: () => {
        throw new Error("User context has not been inited");
    },
});

interface IUserContextProviderProps {
    user: User | null;
    children?: React.ReactNode;
}

export const UserContextProvider: React.FC<IUserContextProviderProps> = ({ user, children }) => {
    const [currUser, setCurrUser] = useState(user);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth.Auth, async (firebaseUser) => {
            if (firebaseUser && firebaseUser.uid) {
                const userRes = await getUserById(firebaseUser.uid);
                if (!userRes.ok) {
                    return;
                }

                setCurrUser(userRes.data ?? null);
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return <UserContext.Provider value={{ user: currUser, setUser: setCurrUser }}>{children}</UserContext.Provider>;
};
