import {
    Button,
    Divider,
    Flex,
    HStack,
    Heading,
    IconButton,
    Link as ChakraLink,
    Spacer,
    VStack,
} from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { LogInBtn } from "../../logIn/logInBtn";
import { Link } from "react-router-dom";

interface IProps {
    langKey: keyof IntlKey;
    onLogInModalOpen: () => void;
}

export const FooterDesktop: React.FC<IProps> = ({ langKey, onLogInModalOpen }) => {
    return (
        <VStack w="100%" borderRadius={16} p={20} pt={5} bg="teddy_yellow.50">
            <Flex w="100%" justify="space-between">
                <HStack>
                    <IconButton
                        fontSize="xl"
                        color="magenta"
                        variant="link"
                        aria-label="instagram"
                        icon={<FaInstagram />}
                    />
                    <IconButton
                        fontSize="xl"
                        color="teddy_blue.500"
                        variant="link"
                        aria-label="instagram"
                        icon={<FaFacebookF />}
                    />
                    <IconButton fontSize="xl" color="red" variant="link" aria-label="instagram" icon={<FaYoutube />} />
                </HStack>
                <LogInBtn langKey={langKey} onOpen={onLogInModalOpen} />
            </Flex>
            <Divider colorScheme="teddy_grey" pt={3} />
            <Flex w="100%" justify="space-evenly" pt={10}>
                <VStack w="50%" alignItems="flex-start" gap={10}>
                    <Heading textAlign="left" fontSize="3xl" w="65%">
                        {intl.heroHeader[langKey]}
                    </Heading>
                    <Link to="search">
                        <Button colorScheme="teddy_orange">{intl.tryBtn[langKey]}</Button>
                    </Link>
                </VStack>
                <Spacer />
                <HStack w="30%" gap={0} fontSize="md" color="teddy_grey.500" textAlign="left">
                    <VStack w="100%" alignItems="start" alignSelf="start" gap={5}>
                        <ChakraLink>{intl.navbarForStudents[langKey]}</ChakraLink>
                        <ChakraLink>{intl.navbarForTeachers[langKey]}</ChakraLink>
                        <ChakraLink href="faq">{intl.navbarFaq[langKey]}</ChakraLink>
                    </VStack>
                    <VStack w="100%" alignItems="start" alignSelf="start" gap={5}>
                        <ChakraLink href="about">{intl.navbarAbout[langKey]}</ChakraLink>
                        <ChakraLink>{intl.navbarContact[langKey]}</ChakraLink>
                    </VStack>
                </HStack>
            </Flex>
        </VStack>
    );
};
