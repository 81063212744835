import { useContext } from "react";
import { LanguageContext } from "../../../intl/languageContext";
import { IntlKey, Languages } from "../../../intl/intlLanguages";
import { useBreakpoint } from "@chakra-ui/react";
import { isMobileScreen } from "../../../common/breakPoints";
import { NavbarDesktop } from "./navbarDesktop";
import { NavbarMobile } from "./navbarMobile";

interface IProps {
    showLogo: boolean;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
}

export const Navbar: React.FC<IProps> = ({ isOpen, onOpen, onClose, showLogo }) => {
    const breakPoint = useBreakpoint({ ssr: false });
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return isMobileScreen(breakPoint) ? (
        <NavbarMobile langKey={langKey} onOpen={onOpen} />
    ) : (
        <NavbarDesktop langKey={langKey} onOpen={onOpen} showLogo={showLogo} />
    );
};
