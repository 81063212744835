import {
    Avatar,
    Button,
    Card,
    Divider,
    Flex,
    HStack,
    Heading,
    Text,
    VStack,
    Link as ChakraLink,
} from "@chakra-ui/react";
import { IntlKey, intl } from "../../intl/intlLanguages";
import { ISearchResult } from "./search";
import { GoLocation } from "react-icons/go";
import { Locations } from "../../locations/locations";
import { TeachersTag } from "../profile/teacher/teachersTag";
import { Tags } from "../../tags/tags";
import { Link, useNavigate } from "react-router-dom";
import { RatingStar } from "../profile/teacher/rating/ratingStar";
import { User } from "../user/user";

interface IProps {
    langKey: keyof IntlKey;
    name: string;
    teacher: ISearchResult;
    user: User | null;
    onLoginOpen: () => void;
}

export const ProfileCardMobile: React.FC<IProps> = ({ langKey, name, teacher, user, onLoginOpen }) => {
    const navigate = useNavigate();
    const handleSendMessage = () => {
        if (user) {
            navigate(`/messages/${teacher.user.id}`);
            return;
        }

        onLoginOpen();
    };

    return (
        <Card
            key={teacher.user.id}
            w="100%"
            overflow="hidden"
            variant="outline"
            p={3}
            fontSize="sm"
            borderColor="teddy_blue.50"
            _hover={{
                bg: "teddy_blue.50",
            }}
        >
            <VStack>
                <HStack w="100%" alignItems="top">
                    <Avatar boxSize="20vw" name={name} src={teacher.user.avatarUrl} bg={"teddy_blue.400"} />
                    <VStack w="100%" alignItems="left" gap={1}>
                        <HStack justify="space-between">
                            <Heading size="lg">{name}</Heading>
                            <HStack fontSize="sm" color="teddy_blue.500">
                                <GoLocation />
                                <Text>{Locations[teacher.info.location][langKey]}</Text>
                            </HStack>
                        </HStack>
                        <VStack alignItems="start" w="100%" color="teddy_grey.400" gap={1}>
                            <Text>{`${intl.profileNationality[langKey]}: ${teacher.info.nationality}`}</Text>
                            <Text>{`${intl.profileAge[langKey]}: ${teacher.info.age}`}</Text>
                        </VStack>
                        <HStack justify="space-between">
                            <HStack>
                                <HStack gap={1}>
                                    {[1, 2, 3, 4, 5].map((index) => (
                                        <RatingStar
                                            rating={teacher.info.avgRating}
                                            starIndex={index}
                                            key={index}
                                            vw="4vw"
                                        />
                                    ))}
                                </HStack>
                                <ChakraLink>{`(${teacher.info.reviewsCount} reviews)`}</ChakraLink>
                            </HStack>
                        </HStack>
                    </VStack>
                </HStack>
                <Text>{teacher.info.synopsis}</Text>
                <Flex gap={2} pt={2} pb={2} flexWrap="wrap">
                    {teacher.info.tags.map((tag) => (
                        <TeachersTag text={Tags[tag][langKey]} key={tag} />
                    ))}
                </Flex>
                <Divider />
                <HStack w="100%" alignSelf="flex-end">
                    <Text fontWeight="bold">{intl.profilePricing[langKey]}: </Text>
                    <Heading size="md">{`${teacher.info.priceCurrency}${teacher.info.price}`}</Heading>
                </HStack>
                <Divider />
                <HStack w="100%" gap={2} minW="220px" justify="space-between">
                    <Link to={`/profile/${teacher.user.type}/${teacher.user.id}`} target="_blank">
                        <Button w="100%" size="md" colorScheme="teddy_blue" variant="outline">
                            {intl.profileCardSeeProfile[langKey]}
                        </Button>
                    </Link>
                    <Button w="100%" size="md" colorScheme="teddy_blue" onClick={handleSendMessage}>
                        {intl.sendMessage[langKey]}
                    </Button>
                </HStack>
            </VStack>
        </Card>
    );
};
