import {
    Box,
    Flex,
    Heading,
    Button,
    VStack,
    Spacer,
    HStack,
    Text,
    Image,
    //Wrap,
    //WrapItem,
    Divider,
    Tag,
    TagLabel,
    TagLeftIcon,
} from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { AiOutlineEdit } from "react-icons/ai";
import { TeachersTag } from "./teachersTag";
import { TeacherInfo } from "./teacherInfo";
import { User } from "../../user/user";
import { Tags } from "../../../tags/tags";
//import { PhotoProvider, PhotoView } from "react-photo-view";
//import { getRandomColorScheme } from "../../../common/randomColor";
import { useContext } from "react";
import { UserContext } from "../../user/userContext";
import { UserType } from "../../user/userType";
import { Review } from "./review/review";
import { ReviewCard } from "./review/reviewCard";
import { IoLocationOutline } from "react-icons/io5";
import { Locations } from "../../../locations/locations";
import { useNavigate } from "react-router-dom";

interface IProps {
    langKey: keyof IntlKey;
    onEditModalOpen: () => void;
    onReviewModalOpen: () => void;
    onLogInOpen: () => void;
    teacherInfo: TeacherInfo | undefined;
    teachersUser: User | null;
    reviews: Review[] | undefined;
    certificatesUrls: string[];
}

export const TeachersProfileDesktop: React.FC<IProps> = ({
    langKey,
    teacherInfo,
    reviews,
    teachersUser,
    //certificatesUrls,
    onEditModalOpen,
    onReviewModalOpen,
    onLogInOpen,
}) => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const isUsersProfile = user?.id === teachersUser?.id;
    const locationsStr = teacherInfo ? Locations[teacherInfo?.location][langKey] : "";

    const handleSendMessage = () => {
        if (user) {
            navigate(`/messages/${teachersUser?.id}`);
            return;
        }

        onLogInOpen();
    };

    return (
        <>
            <VStack w="100%" minW={815} py={5} pr={10} pl={10} gap={0} fontSize={15}>
                <Box w="100%" position="relative">
                    <Flex
                        w="100%"
                        h="120px"
                        align="flex-end"
                        justify="space-between"
                        bg="teddy_blue.50"
                        borderRadius={8}
                    >
                        <Flex w="100%" direction="row" justify="space-between" pb={3} pr={3}>
                            <Spacer />
                            <HStack gap={3}>
                                {isUsersProfile && (
                                    <Button
                                        leftIcon={<AiOutlineEdit />}
                                        colorScheme="teddy_blue"
                                        variant="outline"
                                        size="sm"
                                        onClick={onEditModalOpen}
                                    >
                                        Edit
                                    </Button>
                                )}
                                {user?.type === UserType.Student && (
                                    <Button
                                        size="sm"
                                        variant="outline"
                                        colorScheme="teddy_blue"
                                        onClick={onReviewModalOpen}
                                    >
                                        {intl.profileWriteReview[langKey]}
                                    </Button>
                                )}
                                {!isUsersProfile && (
                                    <Button
                                        size="sm"
                                        colorScheme="teddy_blue"
                                        variant="solid"
                                        onClick={handleSendMessage}
                                    >
                                        {intl.sendMessage[langKey]}
                                    </Button>
                                )}
                            </HStack>
                        </Flex>
                    </Flex>
                </Box>
                <Image
                    borderRadius="full"
                    boxSize="140px"
                    bg={"teddy_blue.300"}
                    position="absolute"
                    top="120px"
                    left="7vw"
                    src={teachersUser?.avatarUrl}
                />
                <HStack w="100%" alignItems="start" gap={5}>
                    <Box h="100%" w="25vw"></Box>
                    <VStack w="100%" pt={5} gap={8} alignItems="start">
                        <VStack w="100%" bg="teddy_blue.50" p={5} borderRadius={8}>
                            <VStack w="100%" alignItems="left">
                                <Heading textAlign="left" fontSize="4xl">
                                    {teachersUser?.name}
                                </Heading>
                                <HStack w="100%" gap={5} fontSize="sm">
                                    <Tag colorScheme="teddy_blue" variant="solid">
                                        <TagLeftIcon as={IoLocationOutline} />
                                        <TagLabel>{locationsStr}</TagLabel>
                                    </Tag>
                                    <Tag colorScheme="teddy_blue" variant="solid">
                                        <TagLabel>{`${intl.profileAge[langKey]}: ${teacherInfo?.age}`}</TagLabel>
                                    </Tag>
                                    <Tag colorScheme="teddy_blue" variant="solid">
                                        <TagLabel>{`${intl.profileNationality[langKey]}: ${teacherInfo?.nationality}`}</TagLabel>
                                    </Tag>
                                    {isUsersProfile && teacherInfo && (
                                        <Tag variant="solid" colorScheme={teacherInfo.posted ? "green" : "red"}>
                                            <TagLabel>
                                                {teacherInfo.posted ? "Profile is visible" : "Profile is hidden"}
                                            </TagLabel>
                                        </Tag>
                                    )}

                                    {/* <HStack p={2} borderRadius={8} bg="teddy_blue.50">
                                        <Text>Age:</Text>
                                        <Text>{teacherInfo?.age}</Text>
                                    </HStack> */}
                                    {/* <HStack>
                                        <Text>Nationality:</Text>
                                        <Text>{teacherInfo?.nationality}</Text>
                                    </HStack> */}
                                </HStack>
                            </VStack>
                            <VStack w="100%" align="left" pt={2}>
                                {/* <Heading fontSize="xl">Synopsis</Heading> */}
                                <Text>{teacherInfo?.synopsis}</Text>
                            </VStack>
                        </VStack>

                        <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                            <Heading fontSize="lg">
                                In General; what kinds of English lessons are you best at teaching?
                            </Heading>
                            <Text>{teacherInfo?.kindsOfEnglish}</Text>
                        </VStack>
                        <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                            <Heading fontSize="lg">
                                In General; what kind of student will benefit most from your lessons?
                            </Heading>
                            <Text>{teacherInfo?.kindsOfStudents}</Text>
                        </VStack>
                        <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                            <Heading fontSize="lg">What can a student expect a lesson with you to be like?</Heading>
                            <Text>{teacherInfo?.lessonStructure}</Text>
                        </VStack>
                        <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                            <Heading fontSize="lg">
                                Are you living in South Korea, or strictly an online teacher?
                            </Heading>
                            <Text>{teacherInfo?.locationDescription}</Text>
                        </VStack>
                        {teacherInfo?.experience && (
                            <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                                <Heading fontSize="lg">
                                    Do you have any relevant experience or professional certifications you would like to
                                    share?
                                </Heading>
                                <Text>{teacherInfo?.experience}</Text>
                            </VStack>
                        )}

                        <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                            <Heading fontSize="lg">When are you generally available for lessons?</Heading>
                            <Text>{teacherInfo?.availability}</Text>
                        </VStack>
                        <VStack w="100%" align="left" bg="teddy_blue.50" borderRadius={8} p={5}>
                            <Heading fontSize="lg">Additional interesting information about yourself</Heading>
                            <Text>{teacherInfo?.availability}</Text>
                        </VStack>
                        {/* <VStack w="100%" align="left" pt={10}>
                            <PhotoProvider bannerVisible>
                                <Wrap spacing="5px">
                                    {certificatesUrls.map((url) => {
                                        console.log(url);
                                        return (
                                            <WrapItem>
                                                <PhotoView src={url}>
                                                    <Image boxSize="150px" objectFit="cover" src={url} />
                                                </PhotoView>
                                            </WrapItem>
                                        );
                                    })}
                                </Wrap>
                            </PhotoProvider>
                        </VStack> */}
                        {reviews && reviews.length > 0 && (
                            <VStack w="100%" align="left" gap={5}>
                                <Heading fontSize="lg">Reviews</Heading>
                                <VStack w="100%">
                                    {reviews?.map((review) => {
                                        return <ReviewCard key={review.id} langKey={langKey} review={review} />;
                                    })}
                                </VStack>
                            </VStack>
                        )}
                    </VStack>
                    <VStack w="30vw" pt={5} gap={5}>
                        {teacherInfo?.price && (
                            <VStack w="100%" gap={3} borderRadius={8} bg="teddy_blue.50" p={3}>
                                <Heading size="md">{intl.profilePricing[langKey]}</Heading>
                                <Divider colorScheme="whiteAlpha" size="lg" />
                                <VStack>
                                    <HStack w="100%" fontSize="lg">
                                        <Text fontWeight="bold">{`${intl.profilePricingBase[langKey]}:`}</Text>
                                        <Text>{teacherInfo?.priceCurrency + teacherInfo?.price} / hr</Text>
                                    </HStack>
                                    <Text fontSize="sm">{teacherInfo.priceAdditionalInfo}</Text>
                                </VStack>
                            </VStack>
                        )}

                        <Flex h="100%" w="100%" gap={3} justify="flex-start" wrap="wrap">
                            {teacherInfo?.tags.map((tagId) => (
                                <TeachersTag key={tagId} text={Tags[tagId][langKey]} />
                            ))}
                        </Flex>
                    </VStack>
                </HStack>
            </VStack>
        </>
    );
};
