import { Link } from "react-router-dom";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import {
    Box,
    Button,
    Flex,
    Heading,
    Image,
    ListItem,
    Text,
    UnorderedList,
    VStack,
    Link as ChakraLink,
} from "@chakra-ui/react";

interface IProps {
    langKey: keyof IntlKey;
}

export const AboutMobile: React.FC<IProps> = ({ langKey }) => {
    return (
        <Flex p={5} justify="space-between" flexDirection="column" alignItems="start">
            <Link to="about">
                <ChakraLink color="teddy_blue.500" fontSize="sm" href="about">
                    {intl.aboutAnchor[langKey]}
                </ChakraLink>
            </Link>

            <Image boxSize="md" fit="contain" src="/illustrations/about.png" />
            <VStack h="100%" textAlign="left" gap={10} flexDirection="column" justify="flex-start">
                <Box>
                    <Box w="90%" pb={18}>
                        <Heading fontWeight="bold" fontSize="xl">
                            {intl.aboutHeader[langKey]}
                        </Heading>
                    </Box>
                    <Box fontSize="md">
                        <Text>{intl.aboutDescr[langKey]}</Text>
                        <UnorderedList pl={5}>
                            <ListItem>{intl.aboutDescrFirstPoint[langKey]}</ListItem>
                            <ListItem>{intl.aboutDescrSecondPoint[langKey]}</ListItem>
                            <ListItem>{intl.aboutDescrThirdPoint[langKey]}</ListItem>
                        </UnorderedList>
                    </Box>
                </Box>
                <Link to="about">
                    <Button size="md" colorScheme="teddy_orange">
                        {intl.aboutBtn[langKey]}
                    </Button>
                </Link>
            </VStack>
        </Flex>
    );
};
