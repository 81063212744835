import { useDisclosure } from "@chakra-ui/react";
import { isMobileScreen } from "../../../common/breakPoints";
import { useCallback, useContext, useEffect, useState } from "react";
import { IntlKey } from "../../../intl/intlLanguages";
import { TeachersProfileMobile } from "./teachersProfileMobile";
import { TeachersProfileDesktop } from "./teachersProfileDesktop";
import { TeachersProfileEditModal } from "./teachersProfileEditModal";
import { UserContext } from "../../user/userContext";
import { TeacherInfo, getTeacherInfoById } from "./teacherInfo";
import { User, getUserById } from "../../user/user";
import { useOutletContext, useParams } from "react-router-dom";
//import { fireStorage } from "../../..";
import { ReviewModal } from "./review/reviewModal";
import { Review, getTeacherReviews } from "./review/review";
import { OutletContextType } from "../../outletContext";

interface IProps {
    breakPoint: string;
    langKey: keyof IntlKey;
}

export const TeachersProfile: React.FC<IProps> = ({ breakPoint, langKey }) => {
    const { onLogInOpen } = useOutletContext<OutletContextType>();
    const { isOpen: isEditModalOpen, onOpen: onEditModalOpen, onClose: onEditModalСlose } = useDisclosure();
    const { isOpen: isReviewModalOpen, onOpen: onReviewModalOpen, onClose: onReviewModalClose } = useDisclosure();

    const { user, setUser } = useContext(UserContext);
    const [teacherInfo, setTeacherInfo] = useState<TeacherInfo>();
    const [reviews, setReviews] = useState<Review[]>();
    const [teachersUser, setTeachersUser] = useState<User | null>();
    //const [certificatesUrls, setCertificatesUrls] = useState<string[]>([]);
    const certificatesUrls: string[] = [];
    const { profileId } = useParams();

    // const loadCertificatesLinks = useCallback(async () => {
    //     if (!profileId || profileId.length === 0) {
    //         return;
    //     }
    //     const certificatesUrls = await fireStorage.getListUrls(`${profileId}/certificates`);
    //     if (certificatesUrls.ok && certificatesUrls.data) setCertificatesUrls(certificatesUrls.data);
    // }, [profileId]);

    const loadTeachersData = useCallback(async () => {
        if (!profileId || profileId.length === 0) {
            return;
        }

        const infoRes = await getTeacherInfoById(profileId);
        if (!infoRes.ok) {
            console.log(infoRes);
            return;
        }

        const userRes = await getUserById(profileId);
        if (!userRes.ok) {
            console.log(userRes);
            return;
        }

        const reviewsRes = await getTeacherReviews(profileId);
        if (!reviewsRes.ok) {
            console.log(reviewsRes);
            return;
        }

        setTeachersUser(userRes.data);
        setTeacherInfo(infoRes.data);
        setReviews(reviewsRes.data);
        //await loadCertificatesLinks();
    }, [profileId]);

    useEffect(() => {
        loadTeachersData();
    }, [loadTeachersData]);

    return (
        <>
            {teacherInfo && teachersUser && (
                <>
                    {isMobileScreen(breakPoint) ? (
                        <TeachersProfileMobile
                            langKey={langKey}
                            onEditModalOpen={onEditModalOpen}
                            onReviewModalOpen={onReviewModalOpen}
                            teacherInfo={teacherInfo}
                            reviews={reviews}
                            teachersUser={teachersUser}
                            certificatesUrls={certificatesUrls}
                            onLogInOpen={onLogInOpen}
                        />
                    ) : (
                        <TeachersProfileDesktop
                            langKey={langKey}
                            onEditModalOpen={onEditModalOpen}
                            onReviewModalOpen={onReviewModalOpen}
                            onLogInOpen={onLogInOpen}
                            teacherInfo={teacherInfo}
                            reviews={reviews}
                            teachersUser={teachersUser}
                            certificatesUrls={certificatesUrls}
                        />
                    )}
                    <TeachersProfileEditModal
                        langKey={langKey}
                        isOpen={isEditModalOpen}
                        onClose={onEditModalСlose}
                        teacherInfo={teacherInfo}
                        setTeacherInfo={setTeacherInfo}
                        user={user}
                        setUser={setUser}
                        isMobile={isMobileScreen(breakPoint)}
                    />
                    {user && (
                        <ReviewModal
                            langKey={langKey}
                            isOpen={isReviewModalOpen}
                            onClose={onReviewModalClose}
                            teacherUser={teachersUser}
                            user={user}
                        />
                    )}
                </>
            )}
        </>
    );
};
