import { Avatar, Divider, Flex, HStack, IconButton, Input, Text, VStack } from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { RiSendPlaneFill, RiArrowLeftSLine } from "react-icons/ri";
import { chatService } from "../../chatService";
import { MessageBox } from "./messageBox";
import { Message } from "../../message";
import { Chat } from "../../chat";
import { User, getProfileUrl } from "../../../user/user";
import { Link } from "react-router-dom";
import { MessageStatus } from "../../messageStatus";
import { getRandomColorScheme } from "../../../../common/randomColor";

interface IProps {
    chat: Chat | null;
    user: User | null;
    isMobile: boolean;
    setActiveChat: (chat: Chat | null) => void;
}

export const MessageList: React.FC<IProps> = ({ chat, user, isMobile, setActiveChat }) => {
    const [msgText, setMsgText] = useState("");
    const [messages, setMessages] = useState<Message[]>([]);
    const [isSendInProgress, setIsSendInProgress] = useState(false);
    const messageEndRef = useRef<HTMLDivElement | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);
    const [chatName, setChatName] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);

    const profileUrl = getProfileUrl(chat?.participant);

    useEffect(() => {
        const current = messageEndRef.current;
        if (current) {
            const scrollableContainer = current.parentElement; // Assuming the direct parent is the scrollable Flex container
            if (scrollableContainer) {
                scrollableContainer.scrollTop = scrollableContainer.scrollHeight;
            }
        }
    }, [messages]);

    useEffect(() => {
        setChatName(`${chat?.participant?.name}`);
    }, [chat]);

    const subscribeForMessages = useCallback(async () => {
        if (!chat) return;

        setMessages([]);
        return await chatService.subscribeForMessagesV2(chat?.id, setMessages);
    }, [chat]);

    const onSend = async () => {
        inputRef.current?.focus();
        if (!chat || msgText.length <= 0) return;

        setIsSendInProgress(true);
        await chatService.sendMessage(msgText, user, chat);
        setMsgText("");
        setIsSendInProgress(false);
    };

    useEffect(() => {
        const unsub = subscribeForMessages();
        return () => {
            unsub.then((func) => {
                if (func) func();
            });
        };
    }, [chat, subscribeForMessages]);

    useEffect(() => {
        const options = {
            root: document.getElementById("messages-list"), // The Flex container ID
            threshold: 1.0, // Adjust as needed. 1.0 means 100% of the item is visible
        };

        observer.current = new IntersectionObserver((entries) => {
            entries.forEach(async (entry) => {
                if (entry.isIntersecting) {
                    // Message is fully visible, mark as read
                    const messageId = entry.target.getAttribute("message-id");
                    const messageStatus = entry.target.getAttribute("message-status");
                    const isUsersMessage = entry.target.getAttribute("is-users-message") === "1";
                    if (isUsersMessage || Number(messageStatus) === MessageStatus.Received) return;
                    await chatService.setMessageReceived(messageId, chat);
                }
            });
        }, options);

        return () => observer.current?.disconnect(); // Clean up
    }, [chat]);

    return (
        <Flex flexDirection="column" justify="space-between" h="89vh" w="100%" bg="teddy_blue.50" borderRadius={8}>
            {chat && (
                <VStack w="100%">
                    <Flex w="100%" p={3} pl={3} pb={0} alignItems="center" gap={3}>
                        {isMobile && (
                            <IconButton
                                fontSize="xx-large"
                                variant="ghost"
                                isRound
                                size="lg"
                                aria-label="back-to-chat-list"
                                icon={<RiArrowLeftSLine />}
                                colorScheme="teddy_blue"
                                onClick={() => setActiveChat(null)}
                            />
                        )}
                        <Avatar
                            src={chat?.participant?.avatarUrl}
                            name={chatName}
                            bg={getRandomColorScheme(chatName) + ".200"}
                        />
                        <Link to={profileUrl}>
                            <Text
                                fontSize="xl"
                                fontWeight="bold"
                                maxW="350px"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                overflow="hidden"
                            >
                                {chatName}
                            </Text>
                        </Link>
                    </Flex>
                    <Divider />
                </VStack>
            )}
            {user && (
                <Flex id="messages-list" flexDirection="column" w="100%" h="90%" overflowY="auto" pr={10} pl={10}>
                    {messages.map((msg) => {
                        return <MessageBox key={msg.id} message={msg} userId={user.id} observer={observer} />;
                    })}
                    <div ref={messageEndRef} />
                </Flex>
            )}
            {chat && (
                <HStack p={2}>
                    <Input
                        ref={inputRef}
                        variant="filled"
                        focusBorderColor="white"
                        bg="white"
                        _hover={{
                            bg: "white",
                        }}
                        _active={{
                            bg: "white",
                        }}
                        _focusWithin={{
                            bg: "white",
                        }}
                        onChange={(e) => {
                            setMsgText(e.target.value);
                        }}
                        value={msgText}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                                // Check if Enter is pressed without the Shift key
                                e.preventDefault(); // Prevent the default action to avoid line breaks in input
                                onSend(); // Call your function to handle the message sending
                            }
                        }}
                    />
                    <IconButton
                        isDisabled={isSendInProgress}
                        aria-label="send"
                        icon={<RiSendPlaneFill />}
                        colorScheme="teddy_blue"
                        borderRadius="full"
                        onClick={(e) => {
                            e.preventDefault();
                            onSend();
                        }}
                    />
                </HStack>
            )}
        </Flex>
    );
};
