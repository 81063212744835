export const intl = {
    navbarForTeachers: {
        en: "For teachers",
        한국어: "교사용",
    },
    navbarForStudents: {
        en: "For students",
        한국어: "학생용",
    },
    navbarFaq: {
        en: "FAQ",
        한국어: "FAQ",
    },
    navbarAbout: {
        en: "About",
        한국어: "우리에 대해",
    },
    navbarContact: {
        en: "Contact us",
        한국어: "문의하기",
    },
    loginBtn: {
        en: "Log in",
        한국어: "로그인",
    },
    heroHeader: {
        en: "English has never been so easy",
        한국어: "영어가 이렇게 쉬웠던 적은 없었습니다",
    },
    tryBtn: {
        en: "Find teacher",
        한국어: "선생님 찾기",
    },
    stepByStepFirstHeader: {
        en: "Create account",
        한국어: "계정 만들기",
    },
    stepByStepFirstDescr: {
        en: "Absolutely FREE for students! 2 months trial period for teachers",
        한국어: "학생들에게는 완전 무료입니다! 교사를 위한 2개월의 시험 기간",
    },
    stepByStepSecondHeader: {
        en: "Find perfect teacher for you",
        한국어: "당신에게 딱 맞는 선생님을 찾아보세요",
    },
    stepByStepSecondDescr: {
        en: "Make your decision based on our ranking and review system",
        한국어: "순위 및 리뷰 시스템을 기반으로 결정을 내리세요.",
    },
    stepByStepThirdHeader: {
        en: "Message the teacher and plan first lesson!",
        한국어: "선생님에게 메시지를 보내고 첫 수업을 계획해보세요!",
    },
    stepByStepThirdDescr: {
        en: "Use our messaging feature to get in touch with teachers",
        한국어: "메시지 기능을 사용하여 교사와 연락하세요",
    },
    aboutAnchor: {
        en: "About us",
        한국어: "우리에 대해",
    },
    aboutHeader: {
        en: "Our goal is to connect students & language teachers",
        한국어: "우리의 목표는 학생과 언어 교사를 연결하는 것입니다",
    },
    aboutDescr: {
        en: "Some text explaining our goal and value we bring to students and teachers",
        한국어: "우리가 학생과 교사에게 제공하는 목표와 가치를 설명하는 일부 텍스트",
    },
    aboutDescrFirstPoint: {
        en: "here is one benefit",
        한국어: "여기 장점이 하나 있어요",
    },
    aboutDescrSecondPoint: {
        en: "and another one",
        한국어: "그리고 또 하나",
    },
    aboutDescrThirdPoint: {
        en: "and more",
        한국어: "그리고 더",
    },
    aboutBtn: {
        en: "About us",
        한국어: "회사 소개",
    },
    faqHeader: {
        en: "Do you have any questions?\nWe will be happy to answer all of them",
        한국어: "질문있으세요?\n우리는 모든 질문에 기꺼이 답변해 드리겠습니다.",
    },
    forTeachersAnchor: {
        en: "For teachers",
        한국어: "교사용",
    },
    forTeachersHeader: {
        en: "Some attractive phrase about finding students",
        한국어: "학생 찾기에 대한 매력적인 문구",
    },
    forTeachersDescr: {
        en: "And here must be some text about advantages and fees and trial periodand etc ",
        한국어: "여기에는 장점, 수수료, 시험 기간 등에 대한 텍스트가 있어야 합니다.",
    },
    forTeachersFirstPoint: {
        en: "here is one benefit",
        한국어: "여기 장점이 하나 있어요",
    },
    forTeachersSecondPoint: {
        en: "and another one",
        한국어: "그리고 또 하나",
    },
    forTeachersThirdPoint: {
        en: "and more",
        한국어: "그리고 더",
    },
    forTeachersBtn: {
        en: "Get started",
        한국어: "Get started",
    },
    forStudentsAnchor: {
        en: "For students",
        한국어: "학생용",
    },
    forStudentsHeader: {
        en: "Some attractive phrase for students",
        한국어: "학생들에게 매력적인 문구",
    },
    forStudentsDescr: {
        en: "And here some points for students and etc",
        한국어: "그리고 여기에 학생 등을 위한 몇 가지 사항이 있습니다.",
    },
    forStudentsFirstPoint: {
        en: "here is one benefit",
        한국어: "여기 장점이 하나 있어요",
    },
    forStudentsSecondPoint: {
        en: "and another one",
        한국어: "그리고 또 하나",
    },
    forStudentsThirdPoint: {
        en: "and more",
        한국어: "그리고 더",
    },
    forStudentsBtn: {
        en: "Join for Free",
        한국어: "Join for Free",
    },
    contactUsAnchor: {
        en: "Contact us",
        한국어: "문의하기",
    },
    contactUsHeader: {
        en: "Feel free to contact us",
        한국어: "저희에게 연락 주시기 바랍니다",
    },
    contactUsDescr: {
        en: "We are happy to answer all of your questions ",
        한국어: "우리는 귀하의 모든 질문에 기꺼이 답변해 드리겠습니다.",
    },
    contactUsBtn: {
        en: "Contact us",
        한국어: "문의하기",
    },
    userMenuProfile: {
        en: "My profile",
        한국어: "내 프로필",
    },
    userMenuMessages: {
        en: "Messages",
        한국어: "메시지",
    },
    userMenuSettings: {
        en: "Settings",
        한국어: "설정",
    },
    userMenuLogout: {
        en: "Log out",
        한국어: "로그 아웃",
    },
    signInModalHeader: {
        en: "Log in",
        한국어: "로그인",
    },
    signInModalEmail: {
        en: "Email",
        한국어: "이메일",
    },
    signInModalPassword: {
        en: "Password",
        한국어: "비밀번호",
    },
    signInModalNoAccount: {
        en: "Don't have an account?",
        한국어: "계정이 없나요?",
    },
    signInModalSignUp: {
        en: "Sign up",
        한국어: "가입하기",
    },
    signInModalForgotPwd: {
        en: "Forgot password?",
        한국어: "비밀번호를 잊으 셨나요?",
    },
    signInModalRecoverHeader: {
        en: "Password recovery",
        한국어: "비밀번호 복구",
    },
    signInModalRecoverDescription: {
        en: "Enter email you use for Teddybear Teacher",
        한국어: "Teddybear Teacher에 사용하는 이메일을 입력하세요",
    },
    signInModalRecoverButton: {
        en: "Send recovery email",
        한국어: "복구 이메일 보내기",
    },
    signInModalRecoverBackButton: {
        en: "Back to Sign In",
        한국어: "로그인으로 돌아가기",
    },
    recoveryPasswordSuccess: {
        en: "If an account exists, we’ll send instructions for resetting your password. Didn’t get them? Check the email address or ask to resend the instructions.",
        한국어: "계정이 존재하는 경우 비밀번호 재설정 지침을 보내드립니다. 못 받았나요? 이메일 주소를 확인하거나 지침을 다시 보내달라고 요청하세요.",
    },
    profileEdit: {
        en: "Edit",
        한국어: "프로필 수정",
    },
    profileEditName: {
        en: "Name",
        한국어: "이름",
    },
    profileEditChangeAvatar: {
        en: "Change avatar",
        한국어: "아바타 변경",
    },
    avatarEditError: {
        en: "Something went wrong, please try again!",
        한국어: "문제가 발생했습니다. 다시 시도하십시오!",
    },
    profilePricing: {
        en: "Pricing",
        한국어: "가격",
    },
    profilePricingBase: {
        en: "Base",
        한국어: "기본 가격",
    },
    profileAge: {
        en: "Age",
        한국어: "나이",
    },
    profileNationality: {
        en: "Nationality",
        한국어: "국적",
    },
    sendMessage: {
        en: "Send message",
        한국어: "문자 보내",
    },
    profileWriteReview: {
        en: "Write review",
        한국어: "리뷰를 쓰다",
    },
    reviewCardLessons: {
        en: "Lessons",
        한국어: "수업",
    },
    aboutPageHeading: {
        en: "What happens when an English Teacher and an IT expert become best friends?",
        한국어: "영어선생님과 IT 전문가가 절친이 되면 어떻게 될까요?",
    },
    aboutPageHeading2: {
        en: "Teddybear Teacher happens!",
        한국어: "Teddybear Teacher 일어나요!",
    },
    aboutPageP1: {
        en: "The Teddybear Teacher startup is the brainchild of two friends living in South Korea; one who is an American English teacher and one who is an IT computer wizard.",
        한국어: "Teddybear Teacher 스타트업은 한국에 살고 있는 두 친구의 아이디어입니다. 한 사람은 미국 영어교사이자 IT 컴퓨터 마법사인 사람.",
    },
    aboutPageP2: {
        en: "One day the English Teacher was lamenting about how hard it was for teachers and students to connect for private lessons; and the IT expert stumbled upon the idea of a modern solution: a website that allows teachers to create profiles, which students are then able to peruse, review, and directly message. The main advantage of this system is that teachers are able to clearly identify the key student styles they are best at teaching, and students are able to then choose the teacher that is the best fit for them.",
        한국어: "어느 날 영어 교사는 개인 수업을 위해 교사와 학생을 연결하는 것이 얼마나 어려운지 한탄했습니다. 그리고 IT 전문가는 우연히 현대적인 솔루션에 대한 아이디어를 생각해 냈습니다. 교사가 프로필을 만들고 학생들이 프로필을 읽고 검토하고 직접 메시지를 보낼 수 있는 웹사이트였습니다. 이 시스템의 가장 큰 장점은 교사가 자신이 가장 잘 가르치는 학생의 주요 스타일을 명확하게 식별할 수 있고, 학생들은 자신에게 가장 적합한 교사를 선택할 수 있다는 것입니다.",
    },
    aboutPageP3: {
        en: "Our main mission is to provide a user-friendly service that helps make education more efficient for both the student and the teacher. Our main goal is to keep this service both cost effective and streamlined.",
        한국어: "우리의 주요 임무는 교육을 보다 효율적으로 만드는 데 도움이 되는 사용자 친화적인 서비스를 제공하는 것입니다. 학생과 선생님. 우리의 주요 목표는 이 서비스를 비용 효율적이고 능률적으로 유지하는 것입니다.",
    },
    aboutPageP4: {
        en: "The idea for Teddybear Teacher took root in late 2023, and our official launch began in 2024. Our hopes for the future include expanding our teacher profile base, advertising to more students, and even providing additional education and teaching materials on our website!",
        한국어: "Teddybear Teacher에 대한 아이디어는 2023년 말에 뿌리를 내렸고, 공식 출시는 2024년에 시작되었습니다. 우리의 희망 미래에는 교사 프로필 기반을 확대하고, 더 많은 학생에게 광고하고, 심지어 추가 교육 및 교재는 당사 웹사이트에서 확인하실 수 있습니다!",
    },
    faqPageHeader: {
        en: "You may still be wondering...",
        한국어: "아직도 궁금하실텐데요...",
    },
    profileCardSeeProfile: {
        en: "See profile",
        한국어: "프로필 보기",
    },
    signUpHeader: {
        en: "Sign up",
        한국어: "가입하기",
    },
    signUpHeader2: {
        en: "Join as",
        한국어: "다음으로 가입",
    },
    signUpStudent: {
        en: "Student",
        한국어: "학생",
    },
    signUpTeacher: {
        en: "Teacher",
        한국어: "선생님",
    },
    signUpName: {
        en: "Name",
        한국어: "이름",
    },
    signUpEmail: {
        en: "Email",
        한국어: "이메일",
    },
    signUpPassword: {
        en: "Password",
        한국어: "비밀번호",
    },
    signUpReenterPassword: {
        en: "Repeat password",
        한국어: "비밀번호를 반복하세요",
    },
    signUpContinue: {
        en: "Continue",
        한국어: "계속하다",
    },
    signUpGoToProfile: {
        en: "Go to profile",
        한국어: "프로필로 이동",
    },
    signUpStudentWelcome: {
        en: "Please, proceed to the profile page and fill information about you :)",
        한국어: "프로필 페이지로 이동하여 정보를 입력해주세요 :)",
    },
    settingsAccount: {
        en: "Account",
        한국어: "계정",
    },
    settingsChangePwd: {
        en: "Change password",
        한국어: "비밀번호 변경",
    },
    settingsSendPwdEmail: {
        en: "Send password change email",
        한국어: "비밀번호 변경 이메일 보내기",
    },
    settingsNotifications: {
        en: "Notifications",
        한국어: "알림",
    },
    settngsNotificationsNewMsg: {
        en: "New messages",
        한국어: "새 메시지 이메일 알림 보내기",
    },
    settingsNotificationsNeMsgDesc: {
        en: "Receive email notifications about new messages",
        한국어: "새 메시지에 대한 이메일 알림 받기",
    },
    search: {
        en: "Search",
        한국어: "검색",
    },
    searchLocation: {
        en: "Location",
        한국어: "위치",
    },
    searchAddFilters: {
        en: "Add filters",
        한국어: "필터 추가",
    },
    searchLocationAnywhere: {
        en: "Anywhere",
        한국어: "어딘가에",
    },
    searchTagRemoveAll: {
        en: "Remove all",
        한국어: "모두 제거",
    },
    searchSortBy: {
        en: "Sort by:",
        한국어: "정렬 기준:",
    },
    searchSortByPriceHigh: {
        en: "Price: High to low",
        한국어: "가격: 높음에서 낮음",
    },
    searchSortByPriceLow: {
        en: "Price: Low to high",
        한국어: "가격: 낮음순",
    },
    searchSortByRatingHigh: {
        en: "Rating: High to low",
        한국어: "평점: 높음에서 낮음",
    },
    searchSortByRatingLow: {
        en: "Rating: Low to high",
        한국어: "평점: 낮음에서 높음",
    },
};

export type IntlKey = {
    en: string;
    한국어: string;
};

// Updated Languages enum
export enum Languages {
    한국어 = "한국어",
    en = "en",
}

export const WeekdayShortNames = {
    Sunday: {
        en: "Su",
        한국어: "일",
    },
    Monday: {
        en: "Mo",
        한국어: "월",
    },
    Tuesday: {
        en: "Tu",
        한국어: "화",
    },
    Wednesday: {
        en: "We",
        한국어: "수",
    },
    Thursday: {
        en: "Th",
        한국어: "목",
    },
    Friday: {
        en: "Fr",
        한국어: "금",
    },
    Saturday: {
        en: "Sa",
        한국어: "토",
    },
};

export const LanguageCode = {
    getCode(lang: Languages) {
        switch (lang) {
            case Languages.en:
                return "en-US";
            case Languages.한국어:
                return "ko-KR";
            default:
                throw new Error(`There is no language code for ${lang} language`);
        }
    },
};

export const intlErrorMessages = {
    invalidLoginOrPassword: {
        en: "Invalid login or password!",
        한국어: "로그인 또는 비밀번호가 틀립니다!",
    },
    loginGeneralError: {
        en: "Unexpected error occured during login! Try again alter",
        한국어: "로그인 중 예상치 못한 오류가 발생했습니다! 나중에 다시 시도해 보세요!",
    },
    loginEmpty: {
        en: "Enter login",
        한국어: "로그인을 입력하세요",
    },
    loginPasswordEmpty: {
        en: "Enter password",
        한국어: "암호를 입력",
    },
    userNotFound: {
        en: "User not found",
        한국어: "사용자를 찾을 수 없습니다",
    },
    somethingWentWrong: {
        en: "Something went wrong! Please, try again later!",
        한국어: "문제가 발생했습니다. 나중에 다시 시도 해주십시오!",
    },
};
