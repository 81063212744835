import { IntlKey } from "../../intl/intlLanguages";

export type FaqItem = {
    question: IntlKey;
    answer: IntlKey;
};

export const faqQuestions: FaqItem[] = [
    {
        question: {
            en: "What is TeddyBear Teacher?",
            한국어: "TeddyBear Teacher 뭐예요?",
        },
        answer: {
            en: "TeddyBear Teacher is an online platform that makes it easy for English Language students to custom search and choose from listed Teacher Profiles. We are based in South Korea and our student base is primarily Korean.",
            한국어: "TeddyBear Teacher는 영어를 배우는 학생들이 쉽게 맞춤화할 수 있는 온라인 플랫폼입니다. 나열된 교사 프로필 중에서 검색하고 선택하세요. 우리는 한국에 본사를 두고 있으며 학생 기반은 다음과 같습니다. 주로 한국인.",
        },
    },
    {
        question: {
            en: "How does TeddyBear Teacher work?",
            한국어: "TeddyBear Teacher 어떻게 일하나요?",
        },
        answer: {
            en: "English Language Teachers create and customize a profile which is then advertised on our website. English Language students are then able to search the profiles and to directly message the teachers they are interested in for themselves or for their children. The teacher and student are then able to coordinate a day and time, and price that is best for both of them.",
            한국어: "영어 교사는 프로필을 만들고 맞춤화하여 웹사이트에 광고합니다. 그러면 영어를 배우는 학생들은 프로필을 검색하고 교사에게 직접 메시지를 보낼 수 있습니다. 자신이나 자녀를 위한 것에 관심이 있습니다. 그러면 교사와 학생이 협력할 수 있습니다. 요일과 시간, 둘 모두에게 가장 적합한 가격.",
        },
    },
    {
        question: {
            en: "Do I have to message through the TeddyBear Teacher website?",
            한국어: "TeddyBear Teacher 웹사이트를 통해 메시지를 보내야 하나요?",
        },
        answer: {
            en: "No, not at all! You can feel free to exchange phone numbers, email, social media, or any other form of communication with each other. Our messaging service is to allow initial contact between student and teacher, and to also provide a comfortable option for anyone who isn’t comfortable with exchanging personal information.",
            한국어: "아니요, 전혀 그렇지 않습니다! 전화번호, 이메일, 소셜미디어 등 어떤 형태로든 자유롭게 서로 소통할 수 있습니다. 우리의 메시징 서비스는 학생과 교사 간의 초기 접촉을 허용하고 개인 정보 교환이 불편한 모든 사람에게 편안한 옵션을 제공하는 것입니다.",
        },
    },
    {
        question: {
            en: "Who decides the price of lessons with a teacher?",
            한국어: "선생님과의 수업 가격은 누가 결정하나요?",
        },
        answer: {
            en: "The individual English Teachers decide and list their own prices on the website. They are allowed to update their profile, including price, at anytime, however part of the value of the TeddyBear Teacher website is it allows quick price comparison with other teachers on one easy-to-use website.",
            한국어: "개별 영어 교사가 가격을 결정하고 웹사이트에 게시합니다. 그들은 허용됩니다 언제든지 가격을 포함한 프로필을 업데이트할 수 있습니다. 단, 이는 TeddyBear Teacher의 가치 중 일부입니다. 웹사이트는 사용하기 쉬운 하나의 웹사이트에서 다른 교사들과 빠른 가격 비교를 가능하게 해줍니다.",
        },
    },
    {
        question: {
            en: "Why do you have so few teacher profiles listed?",
            한국어: "왜 교사 프로필이 그렇게 적나요?",
        },
        answer: {
            en: "We are currently brand new! We just recently launched on [the Day of Month]. We are currently advertising for both students and teachers, and we hope to gradually grow within the next three months. So, if you are a teacher then tell your teacher-friends about us – and if you are a student then tell your English-learning friends about us!",
            한국어: "우리는 현재 새로운 브랜드입니다! 우리는 최근 [월요일]에 출시했습니다. 현재 학생과 교사 모두를 대상으로 광고를 진행하고 있으며 향후 3개월 이내에 점진적으로 성장할 수 있기를 바랍니다. 그러니 선생님이라면 선생님이자 친구들에게 우리에 대해 이야기해 주세요. 학생이라면 영어를 배우는 친구들에게 우리에 대해 이야기해주세요!",
        },
    },
    {
        question: {
            en: "Is there a difference between TeddyBear Teacher and a language school?",
            한국어: "테디베어 선생님과 어학원의 차이점이 있나요?",
        },
        answer: {
            en: "Yes! A normal language school hires teachers, pays those teachers a wage or salary, and then provides a classroom for the teachers to conduct lessons in. Students pay the language school a tuition and have lessons with a teacher they did not choose and in a fixed location. TeddyBear Teacher is not like that at all: teachers create profiles to be advertised on our platform (we don’t hire them and we don’t pay them), students choose a teacher they like and message them through our platform, and they choose a place and time to meet for the lesson. Both students and teachers have a lot more flexibility with schedule, location, price, and who they are meeting for the lesson.",
            한국어: "예! 일반 어학원에서는 교사를 고용하고 그 교사에게 급여나 급여를 지급한 후 교사가 수업을 진행할 수 있는 교실을 제공합니다. 학생들은 어학원에 수업료를 지불하고 자신이 선택하지 않은 교사와 고정된 장소에서 수업을 받습니다. . TeddyBear Teacher는 전혀 그렇지 않습니다. 교사는 플랫폼에 광고할 프로필을 만들고(우리는 고용하지 않으며 비용도 지불하지 않습니다), 학생들은 자신이 좋아하는 교사를 선택하고 우리 플랫폼을 통해 메시지를 보냅니다. 수업을 위해 만날 장소와 시간. 학생과 교사 모두 일정, 위치, 가격, 수업을 위해 만날 사람을 훨씬 더 유연하게 선택할 수 있습니다.",
        },
    },
    {
        question: {
            en: "What if my teacher/student is not the best fit for me?",
            한국어: "선생님/학생이 나에게 가장 적합하지 않으면 어떻게 되나요?",
        },
        answer: {
            en: "Students are allowed to message any new teacher, and to switch with any available teacher, at any point they choose. We also highly encourage students to leave reviews of teachers who they love, or hate, so that future students are able to better evaluate the teachers they choose.",
            한국어: "학생들은 새로운 교사에게 메시지를 보낼 수 있으며, 언제든지 원하는 교사로 전환할 수 있습니다. 또한 학생들이 자신이 좋아하거나 싫어하는 교사에 대한 리뷰를 남겨 미래의 학생들이 자신이 선택한 교사를 더 잘 평가할 수 있도록 적극 권장합니다.",
        },
    },
    {
        question: {
            en: "Can teachers search and message student profiles?",
            한국어: "교사는 학생 프로필을 검색하고 메시지를 보낼 수 있나요?",
        },
        answer: {
            en: "Only students can search for teacher profiles. Teachers can respond back only after the student has initiated the message.",
            한국어: "학생만 교사 프로필을 검색할 수 있습니다. 교사는 학생이 메시지를 시작한 후에만 응답할 수 있습니다.",
        },
    },
    {
        question: {
            en: "Is TeddyBear Teacher only for English language teachers?",
            한국어: "테디베어 선생님은 영어 선생님만을 위한 곳인가요?",
        },
        answer: {
            en: "Currently, and for the foreseeable future – yes, TeddyBear Teacher is only for English language teachers. This is to avoid clutter and to keep the site clean and simple. Both students and teachers know exactly what to expect when they come to TeddyBear Teacher.",
            한국어: "현재, 그리고 가까운 미래에도 – 그렇습니다. TeddyBear Teacher는 영어 교사만을 위한 것입니다. 이는 혼잡함을 피하고 사이트를 깨끗하고 단순하게 유지하기 위한 것입니다. 학생과 교사 모두 TeddyBear Teacher에 오면 무엇을 기대해야 할지 정확히 알고 있습니다.",
        },
    },
    {
        question: {
            en: "How can I contact TeddyBear Teacher for more information?",
            한국어: "자세한 내용은 TeddyBear 선생님에게 어떻게 문의하나요?",
        },
        answer: {
            en: "You can email us at TeddyBear.Teaching@gmail.com .",
            한국어: "TeddyBear.Teaching@gmail.com으로 이메일을 보내실 수 있습니다.",
        },
    },
];
