import { Box, Button, Grid, GridItem, Heading, Image, Spacer, VStack } from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { Link } from "react-router-dom";

interface IProps {
    langKey: keyof IntlKey;
}

export const HeroDesktop: React.FC<IProps> = ({ langKey }) => {
    return (
        <Box h="420px" bg="teddy_yellow.10" borderRadius={16} mt={5}>
            <Grid w="100%" h="100%" templateColumns="repeat(12, 1fr)" templateRows="repeat(8, 1fr)">
                <GridItem colSpan={1} rowSpan={8}></GridItem>
                <GridItem colSpan={3} rowSpan={8}>
                    <VStack h="100%" w="70%" gap={8} align="left" textAlign="left">
                        <Spacer />
                        <Heading>{intl.heroHeader[langKey]}</Heading>
                        <Link to="search">
                            <Button colorScheme="teddy_orange">{intl.tryBtn[langKey]}</Button>
                        </Link>
                        <Spacer />
                    </VStack>
                </GridItem>
                <GridItem colSpan={6} rowSpan={8} ml={16}>
                    <Image borderRadius={24} boxSize="410" fit="contain" src="/illustrations/hero-3.png" />
                </GridItem>
                <GridItem colSpan={1} rowSpan={8}></GridItem>
            </Grid>
        </Box>
    );
};
