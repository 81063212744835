import {
    Box,
    FormLabel,
    FormControl,
    Input,
    Textarea,
    Button,
    VStack,
    FormErrorMessage,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    ModalCloseButton,
    Alert,
    Flex,
    Checkbox,
    CheckboxGroup,
    Spinner,
    HStack,
    useDisclosure,
    Image,
    Select,
    Text,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    SimpleGrid,
    Switch,
    Divider,
    useToast,
} from "@chakra-ui/react";
import { IntlKey } from "../../../intl/intlLanguages";
import { useCallback, useEffect, useState } from "react";
import { TeacherInfo, setOrUpdateTeacherInfo } from "./teacherInfo";
import { User, updateUser } from "../../user/user";
import { Tags } from "../../../tags/tags";
import { AvatarEditModal } from "../avatar/avatarEditModal";
import { Locations } from "../../../locations/locations";
//import { GalleryUploadModal } from "./gallery/galleryUploadModal";
//import { MinorAlert } from "./minorAlert";
import { fireStorage } from "../../..";
import { getRandomColorScheme } from "../../../common/randomColor";

interface IProps {
    langKey: keyof IntlKey;
    isOpen: boolean;
    onClose: () => void;
    teacherInfo: TeacherInfo | undefined;
    setTeacherInfo: (teacherInfo: TeacherInfo | undefined) => void;
    user: User | null;
    setUser: (user: User | null) => void;
    isMobile: boolean;
}

export const TeachersProfileEditModal: React.FC<IProps> = ({
    langKey,
    user,
    setUser,
    teacherInfo,
    setTeacherInfo,
    isOpen,
    onClose,
    isMobile,
}) => {
    // State hooks for each input field
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [nickname, setNickname] = useState<string>("");
    const [minorAllowed, setMinorAllowed] = useState<boolean>(false);
    const [location, setLocation] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const [age, setAge] = useState<number>(0);
    const [nationality, setNationality] = useState<string>("");
    const [synopsis, setSynopsis] = useState<string>("");
    const [kindsOfEnglish, setKindsOfEnglish] = useState<string>("");
    const [kindsOfStudents, setKindsOfStudents] = useState<string>("");
    const [lessonStructure, setLessonStructure] = useState<string>("");
    const [locationDescription, setLocationDescription] = useState<string>("");
    const [experience, setExperience] = useState<string>("");
    const [availability, setAvailability] = useState<string>("");
    const [price, setPrice] = useState<number>(0);
    const [priceCurrency, setPriceCurrency] = useState("$");
    const [priceAdditionalInfo, setPriceAdditionalInfo] = useState<string>("");
    const [additionalInfo, setAdditionalInfo] = useState<string>("");
    const [posted, setIsPosted] = useState(false);

    const { onOpen: onAvatarEditOpen, isOpen: isAvatarEditOpen, onClose: onAvatarEditClose } = useDisclosure();
    //const { onOpen: onGalleryUploadOpen, isOpen: isGalleryUploadOpen, onClose: onGalleryUploadClose } = useDisclosure();
    //const { onOpen: onMinorAlertOpen, isOpen: isMinorAlertOpen, onClose: onMinorAlertClose } = useDisclosure();
    //const cancelRef = useRef<HTMLButtonElement>(null);
    const toast = useToast();

    // State hooks for input field errors
    const [errors, setErrors] = useState({
        name: "",
        nickname: "",
        minorAllowed: "",
        location: "",
        tags: "",
        age: "",
        nationality: "",
        synopsis: "",
        kindsOfEnglish: "",
        kindsOfStudents: "",
        lessonStructure: "",
        locationDescription: "",
        experience: "",
        availability: "",
        price: "",
        priceAdditionalInfo: "",
        additionalInfo: "",
    });

    const [saveError, setSaveError] = useState("");
    const [inProgress, setInProgress] = useState(false);

    const textAreaHeight = "20vh";

    // Validate form fields
    const validateField = (name: string, value: string) => {
        if (!value || value.length === 0) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: "This field is required." }));
            return false;
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
            return true;
        }
    };

    const validatePrice = () => {
        if (price > 0) {
            setErrors((prevErrors) => ({ ...prevErrors, price: "" }));
            return true;
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, price: "This field is required" }));
            return false;
        }
    };

    const validateLocation = () => {
        if (!location || location === "00000000-0000-0000-0000-0000000000000") {
            setErrors((prevErrors) => ({ ...prevErrors, location: "This field is required" }));
            return false;
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, location: "" }));
            return true;
        }
    };

    const validateAge = () => {
        if (age > 0) {
            setErrors((prevErrors) => ({ ...prevErrors, age: "" }));
            return true;
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, age: "This field is required" }));
            return false;
        }
    };

    const processSynopsis = (text: string) => {
        if (text.length <= 500) setSynopsis(text);
    };

    // Handle form submit
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setInProgress(true);
        // Simple front-end validation
        const isValidName = validateField("name", name);
        const isValidAge = validateAge();
        const isValidLocation = validateLocation();
        const isValidSynopsis = validateField("synopsis", synopsis);
        const isValidKindsOfEnglish = validateField("kindsOfEnglish", kindsOfEnglish);
        const isValidKindsOfStudents = validateField("kindsOfStudents", kindsOfStudents);
        const isValidLessonStructure = validateField("lessonStructure", lessonStructure);
        const isValidPrice = validatePrice();

        if (
            !isValidName ||
            !isValidAge ||
            !isValidLocation ||
            !isValidSynopsis ||
            !isValidKindsOfEnglish ||
            !isValidKindsOfStudents ||
            !isValidLessonStructure ||
            !isValidPrice
        ) {
            setInProgress(false);
            return;
        }

        if (!user) {
            setSaveError("No user!");
            setInProgress(false);
            return;
        }

        const updatedUser = {
            id: user.id,
            avatarUrl: user.avatarUrl,
            name: name,
            email: email,
            type: user.type,
            nickname: nickname,
        } as User;

        const updateUserResult = await updateUser(updatedUser);
        if (!updateUserResult.ok) {
            setSaveError(updateUserResult.message || "Error occured while saving user info");
            setInProgress(false);
            return;
        }

        const updatedTeacherInfo = {
            minorAllowed: minorAllowed,
            location: location,
            tags: tags,
            age: age,
            nationality: nationality,
            synopsis: synopsis,
            kindsOfEnglish: kindsOfEnglish,
            kindsOfStudents: kindsOfStudents,
            lessonStructure: lessonStructure,
            locationDescription: locationDescription,
            experience: experience,
            availability: availability,
            price: price,
            priceCurrency: priceCurrency,
            priceAdditionalInfo: priceAdditionalInfo,
            additionalInfo: additionalInfo,
            posted: posted,
        } as TeacherInfo;

        const updateTeacherInfoResult = await setOrUpdateTeacherInfo(user.id, updatedTeacherInfo);
        if (!updateTeacherInfoResult.ok) {
            setSaveError(updateTeacherInfoResult.message || "Error occured while saving teacher's info");
            setInProgress(false);
            return;
        }

        if (!minorAllowed) await fireStorage.deleteAllFiles(`${user.id}/certificates`);

        setUser(updatedUser);
        setTeacherInfo(updatedTeacherInfo);
        setInProgress(false);
        toast({
            duration: 3000,
            title: "Success",
            description: "Profile has been updated!",
            status: "success",
        });
    };

    const setInitialState = useCallback(() => {
        setInProgress(false);
        setErrors({
            name: "",
            nickname: "",
            minorAllowed: "",
            location: "",
            tags: "",
            age: "",
            nationality: "",
            synopsis: "",
            kindsOfEnglish: "",
            kindsOfStudents: "",
            lessonStructure: "",
            locationDescription: "",
            experience: "",
            availability: "",
            price: "",
            priceAdditionalInfo: "",
            additionalInfo: "",
        });

        if (!user) return;
        if (!teacherInfo) return;

        setName(user.name);
        setEmail(user.email);
        setNickname(user.nickname);
        setMinorAllowed(teacherInfo.minorAllowed);
        setLocation(teacherInfo.location);
        setTags(teacherInfo.tags);
        setAge(teacherInfo.age);
        setNationality(teacherInfo.nationality);
        setSynopsis(teacherInfo.synopsis);
        setKindsOfEnglish(teacherInfo.kindsOfEnglish);
        setKindsOfStudents(teacherInfo.kindsOfStudents);
        setLessonStructure(teacherInfo.lessonStructure);
        setLocationDescription(teacherInfo.locationDescription);
        setExperience(teacherInfo.experience);
        setAvailability(teacherInfo.availability);
        setPrice(teacherInfo.price);
        setPriceAdditionalInfo(teacherInfo.priceAdditionalInfo);
        setAdditionalInfo(teacherInfo.additionalInfo);
        setIsPosted(teacherInfo.posted);
    }, [teacherInfo, user]);

    const handleClose = () => {
        setInitialState();
        onClose();
    };

    const handleTagCheck = (checked: boolean, value: string) => {
        if (checked) setTags([...tags, value]);
        else setTags(tags.filter((tag) => tag !== value));
    };

    // const handleMinorAllowedChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    //     if (e.target.checked) {
    //         onMinorAlertOpen();
    //     } else {
    //         setMinorAllowed(false);
    //     }
    // };

    useEffect(() => {
        setInitialState();
    }, [setInitialState]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={handleClose} size="3xl">
                <ModalOverlay h="100%" w="100%" bg="teddy_blue.5069" />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack p={isMobile ? 0 : 4} pb={0}>
                            <Box my={isMobile ? 6 : 8} textAlign="left">
                                {
                                    <form onSubmit={handleSubmit}>
                                        <VStack spacing={4}>
                                            <VStack gap={3}>
                                                <Image
                                                    borderRadius="full"
                                                    boxSize={isMobile ? "130px" : "170px"}
                                                    src={user?.avatarUrl}
                                                    fit="contain"
                                                    bg={`${getRandomColorScheme("")}.300`}
                                                />
                                                <Button
                                                    isDisabled={inProgress}
                                                    size="sm"
                                                    colorScheme="teddy_blue"
                                                    variant="ghost"
                                                    onClick={onAvatarEditOpen}
                                                >
                                                    Change avatar
                                                </Button>
                                            </VStack>

                                            <SimpleGrid w="100%" columns={isMobile ? 1 : 2} spacing={3}>
                                                <FormControl isInvalid={!!errors.name}>
                                                    <FormLabel fontWeight="bold">Name</FormLabel>
                                                    <Input
                                                        isDisabled={inProgress}
                                                        type="text"
                                                        placeholder="Last name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                    />
                                                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                                                </FormControl>

                                                <FormControl isInvalid={!!errors.nickname}>
                                                    <FormLabel fontWeight="bold">Nickname</FormLabel>
                                                    <Input
                                                        isDisabled={inProgress}
                                                        placeholder=""
                                                        type="text"
                                                        value={nickname}
                                                        onChange={(e) => setNickname(e.target.value)}
                                                    />
                                                    <FormErrorMessage>{errors.nickname}</FormErrorMessage>
                                                </FormControl>
                                                {/* </HStack> */}

                                                {/* <HStack w="100%"> */}
                                                <FormControl isInvalid={!!errors.nationality}>
                                                    <FormLabel fontWeight="bold">Nationality</FormLabel>
                                                    <Input
                                                        isDisabled={inProgress}
                                                        type="text"
                                                        placeholder="ex. Canada"
                                                        value={nationality}
                                                        onChange={(e) => setNationality(e.target.value)}
                                                    />
                                                    <FormErrorMessage>{errors.nationality}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl isInvalid={!!errors.age}>
                                                    <FormLabel fontWeight="bold">Age</FormLabel>
                                                    <NumberInput
                                                        isDisabled={inProgress}
                                                        onChange={(valueString) => setAge(+valueString)}
                                                        value={age}
                                                        min={0}
                                                    >
                                                        <NumberInputField />
                                                        <NumberInputStepper>
                                                            <NumberIncrementStepper />
                                                            <NumberDecrementStepper />
                                                        </NumberInputStepper>
                                                    </NumberInput>
                                                    <FormErrorMessage>{errors.age}</FormErrorMessage>
                                                </FormControl>

                                                <FormControl isInvalid={!!errors.location}>
                                                    <FormLabel fontWeight="bold">Location</FormLabel>
                                                    <Select
                                                        isDisabled={inProgress}
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        defaultValue={location}
                                                    >
                                                        {Object.entries(Locations)
                                                            .filter(
                                                                (l) => l[0] !== "00000000-0000-0000-0000-0000000000000"
                                                            )
                                                            .map((location) => {
                                                                return (
                                                                    <option key={location[0]} value={location[0]}>
                                                                        {location[1][langKey]}
                                                                    </option>
                                                                );
                                                            })}
                                                    </Select>
                                                    <FormErrorMessage>{errors.location}</FormErrorMessage>
                                                </FormControl>
                                                {/* </HStack> */}
                                            </SimpleGrid>
                                            <FormControl isInvalid={!!errors.synopsis}>
                                                <FormLabel fontWeight="bold">Synopsis</FormLabel>
                                                <VStack gap={1}>
                                                    <Textarea
                                                        isDisabled={inProgress}
                                                        minH={textAreaHeight}
                                                        placeholder=""
                                                        value={synopsis}
                                                        onChange={(e) => processSynopsis(e.target.value)}
                                                        resize="vertical"
                                                    />
                                                    <Text
                                                        alignSelf="flex-end"
                                                        color="teddy_grey.200"
                                                        fontSize="xs"
                                                        pr={3}
                                                    >{`${synopsis.length}/500`}</Text>
                                                </VStack>

                                                <FormErrorMessage>{errors.synopsis}</FormErrorMessage>
                                            </FormControl>

                                            <FormControl isInvalid={!!errors.kindsOfEnglish}>
                                                <FormLabel fontWeight="bold">
                                                    In General; what kinds of English lessons are you best at teaching?
                                                </FormLabel>
                                                <Textarea
                                                    isDisabled={inProgress}
                                                    minH={textAreaHeight}
                                                    placeholder="explain all that apply"
                                                    value={kindsOfEnglish}
                                                    onChange={(e) => setKindsOfEnglish(e.target.value)}
                                                    resize="vertical"
                                                />
                                                <FormErrorMessage>{errors.kindsOfEnglish}</FormErrorMessage>
                                            </FormControl>

                                            <FormControl isInvalid={!!errors.kindsOfStudents}>
                                                <FormLabel fontWeight="bold">
                                                    In General; what kind of student will benefit most from your
                                                    lessons?
                                                </FormLabel>
                                                <Textarea
                                                    isDisabled={inProgress}
                                                    minH={textAreaHeight}
                                                    placeholder="explain all that apply"
                                                    value={kindsOfStudents}
                                                    onChange={(e) => setKindsOfStudents(e.target.value)}
                                                    resize="vertical"
                                                />
                                                <FormErrorMessage>{errors.kindsOfStudents}</FormErrorMessage>
                                            </FormControl>

                                            <FormControl isInvalid={!!errors.lessonStructure}>
                                                <FormLabel fontWeight="bold">
                                                    What can a student expect a lesson with you to be like?
                                                </FormLabel>
                                                <Textarea
                                                    isDisabled={inProgress}
                                                    minH={textAreaHeight}
                                                    placeholder="typical structure of the lesson"
                                                    value={lessonStructure}
                                                    onChange={(e) => setLessonStructure(e.target.value)}
                                                    resize="vertical"
                                                />
                                                <FormErrorMessage>{errors.lessonStructure}</FormErrorMessage>
                                            </FormControl>

                                            <FormControl isInvalid={!!errors.locationDescription}>
                                                <FormLabel fontWeight="bold">
                                                    Are you living in South Korea, or strictly an online teacher?
                                                </FormLabel>
                                                <Textarea
                                                    isDisabled={inProgress}
                                                    minH={textAreaHeight}
                                                    placeholder="if not living in South Korea please explain the online platforms you prefer to use:  zoom, teams, skype, etc. for lessons"
                                                    value={locationDescription}
                                                    onChange={(e) => setLocationDescription(e.target.value)}
                                                    resize="vertical"
                                                />
                                                <FormErrorMessage>{errors.locationDescription}</FormErrorMessage>
                                            </FormControl>

                                            <FormControl isInvalid={!!errors.experience}>
                                                <FormLabel fontWeight="bold">
                                                    Do you have any relevant experience or professional certifications
                                                    you would like to share?
                                                </FormLabel>
                                                <Textarea
                                                    isDisabled={inProgress}
                                                    minH={textAreaHeight}
                                                    placeholder=""
                                                    value={experience}
                                                    onChange={(e) => setExperience(e.target.value)}
                                                    resize="vertical"
                                                />
                                                <FormErrorMessage>{errors.experience}</FormErrorMessage>
                                            </FormControl>

                                            <FormControl isInvalid={!!errors.availability}>
                                                <FormLabel fontWeight="bold">
                                                    When are you generally available for lessons?
                                                </FormLabel>
                                                <Textarea
                                                    isDisabled={inProgress}
                                                    minH={textAreaHeight}
                                                    placeholder="morning, afternoon, evening, weekdays, weekends, etc."
                                                    value={availability}
                                                    onChange={(e) => setAvailability(e.target.value)}
                                                    resize="vertical"
                                                />
                                                <FormErrorMessage>{errors.availability}</FormErrorMessage>
                                            </FormControl>

                                            <FormControl isInvalid={!!errors.additionalInfo}>
                                                <FormLabel fontWeight="bold">
                                                    Additional Interesting Information About Yourself:
                                                </FormLabel>
                                                <Textarea
                                                    isDisabled={inProgress}
                                                    minH={textAreaHeight}
                                                    placeholder=""
                                                    value={additionalInfo}
                                                    onChange={(e) => setAdditionalInfo(e.target.value)}
                                                    resize="vertical"
                                                />
                                                <FormErrorMessage>{errors.additionalInfo}</FormErrorMessage>
                                            </FormControl>
                                            <HStack w="100%">
                                                <FormControl isInvalid={!!errors.price}>
                                                    <FormLabel fontWeight="bold">Price</FormLabel>
                                                    <NumberInput
                                                        isDisabled={inProgress}
                                                        onChange={(valueString) => setPrice(+valueString)}
                                                        value={price}
                                                        min={0}
                                                    >
                                                        <NumberInputField />
                                                        {/* <NumberInputStepper>
                                                            <NumberIncrementStepper />
                                                            <NumberDecrementStepper />
                                                        </NumberInputStepper> */}
                                                    </NumberInput>
                                                    <FormErrorMessage>{errors.price}</FormErrorMessage>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel fontWeight="bold">Currency</FormLabel>
                                                    <Select
                                                        isDisabled={inProgress}
                                                        maxW="3wv"
                                                        onChange={(e) => setPriceCurrency(e.target.value)}
                                                    >
                                                        <option key="usd" value="$">
                                                            $
                                                        </option>
                                                        <option key="kwn" value="₩">
                                                            ₩
                                                        </option>
                                                    </Select>
                                                </FormControl>
                                            </HStack>
                                            <FormControl isInvalid={!!errors.priceAdditionalInfo}>
                                                <FormLabel fontWeight="bold">
                                                    Any Additional Pricing Information:
                                                </FormLabel>
                                                <Textarea
                                                    isDisabled={inProgress}
                                                    minH={textAreaHeight}
                                                    placeholder="group lessons, special lessons, first time discounts, etc."
                                                    value={priceAdditionalInfo}
                                                    onChange={(e) => setPriceAdditionalInfo(e.target.value)}
                                                    resize="vertical"
                                                />
                                                <FormErrorMessage>{errors.priceAdditionalInfo}</FormErrorMessage>
                                            </FormControl>

                                            <FormControl>
                                                <FormLabel fontWeight="bold">Tags</FormLabel>
                                                <CheckboxGroup
                                                    isDisabled={inProgress}
                                                    colorScheme="teddy_yellow"
                                                    defaultValue={tags}
                                                >
                                                    <Flex flexWrap="wrap" gap={3}>
                                                        {Object.entries(Tags).map((tag) => {
                                                            return (
                                                                <Checkbox
                                                                    key={tag[0]}
                                                                    size="md"
                                                                    value={tag[0]}
                                                                    onChange={(e) =>
                                                                        handleTagCheck(e.target.checked, e.target.value)
                                                                    }
                                                                >
                                                                    {tag[1][langKey]}
                                                                </Checkbox>
                                                            );
                                                        })}
                                                    </Flex>
                                                </CheckboxGroup>
                                            </FormControl>
                                            <Divider />
                                            <HStack w={isMobile ? "full" : "25vw"} justify="space-between">
                                                <Text>Visible for students</Text>
                                                <Switch
                                                    isDisabled={inProgress}
                                                    isChecked={posted}
                                                    onChange={(e) => setIsPosted(e.target.checked)}
                                                    colorScheme="teddy_yellow"
                                                />
                                            </HStack>

                                            {saveError !== "" && <Alert status="error">{saveError}</Alert>}
                                            <Button
                                                colorScheme="teddy_yellow"
                                                w={isMobile ? "full" : "25vw"}
                                                mt={2}
                                                type="submit"
                                                isDisabled={inProgress}
                                            >
                                                {inProgress ? <Spinner /> : "Save"}
                                            </Button>
                                        </VStack>
                                    </form>
                                }
                            </Box>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <AvatarEditModal
                langKey={langKey}
                isOpen={isAvatarEditOpen}
                onClose={onAvatarEditClose}
                user={user}
                setUser={setUser}
                isMobile={isMobile}
            />
            {/* <MinorAlert
                cancelRef={cancelRef}
                isOpen={isMinorAlertOpen}
                onClose={onMinorAlertClose}
                handleYes={onGalleryUploadOpen}
            /> */}
            {/* <GalleryUploadModal
                isOpen={isGalleryUploadOpen}
                onClose={onGalleryUploadClose}
                isMobile={isMobile}
                path={`${user?.id}/certificates`}
                onSuccess={() => {
                    setMinorAllowed(true);
                }}
            /> */}
        </>
    );
};
