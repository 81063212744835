import { fireStorage } from "../../..";
import { IResponseData } from "../../../common/response";
import { User } from "../../user/user";

export const saveAvatar = async (blob: Blob | null, user: User | null): Promise<IResponseData<string>> => {
    try {
        if (!blob) return { ok: false, message: "No image to save!" };
        if (!user) return { ok: false, message: "No user" };

        const fileName = `${user.id}/avatars/${user.name}`;
        const uploadRes = await fireStorage.uploadFile(fileName, blob);

        return uploadRes;
    } catch (ex) {
        console.log(ex);
        return { ok: false, message: (ex as Error).message };
    }
};
