import { Button, Menu, MenuButton, MenuItem, MenuList, Box, Text, useBreakpoint } from "@chakra-ui/react";
import React, { useContext } from "react";
import { MdLanguage } from "react-icons/md";
import { LanguageContext } from "../../../intl/languageContext";
import { isMobileScreen } from "../../../common/breakPoints";
import { Languages } from "../../../intl/intlLanguages";

const LanguageSelector = () => {
    const { lang, setLanguage } = useContext(LanguageContext);

    const breakPoint = useBreakpoint();
    const isMobile = isMobileScreen(breakPoint);
    const icon = isMobile ? <></> : <MdLanguage />;

    const mobileMenuBtnProps = {
        as: Button,
    };
    const desktopMenuBtnProps = {
        as: Button,
        rightIcon: icon,
    };
    const menuBtnProps = isMobile ? mobileMenuBtnProps : desktopMenuBtnProps;

    return (
        <Box>
            <Menu variant="languageSelector">
                <MenuButton
                    {...menuBtnProps}
                    /*it was easier to style Button here, because in custom theme you can't place icon, 
and all stylings have to be done from scratch */
                    _hover={{ bg: "teddy_blue.50" }}
                    _focus={{ bg: "teddy_blue.100" }}
                    _active={{ bg: "teddy_blue.200" }}
                    color="teddy_blue.500"
                    borderWidth={1}
                    borderColor="teddy_blue.500"
                    bg="white.900"
                    size="sm"
                >
                    <Text casing="capitalize">{Languages[lang]}</Text>
                </MenuButton>
                <MenuList fontSize="md">
                    <MenuItem onClick={() => setLanguage(Languages.한국어)}>{Languages[Languages.한국어]}</MenuItem>
                    <MenuItem onClick={() => setLanguage(Languages.en)}>
                        <Text casing="capitalize">{Languages[Languages.en]}</Text>
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    );
};

export default LanguageSelector;
