import { RiCheckDoubleFill, RiCheckFill, RiLoader4Fill } from "react-icons/ri";
import { FaCircle } from "react-icons/fa";
import { MessageStatus } from "../messageStatus";

interface IProps {
    status: MessageStatus;
    senderId: string | undefined;
    userId: string | undefined;
}

export const MsgStatus: React.FC<IProps> = ({ status, userId, senderId }) => {
    switch (status) {
        case MessageStatus.Pending:
            return <RiLoader4Fill />;
        case MessageStatus.Sent: {
            if (userId && senderId && userId !== senderId) return <FaCircle />;
            else return <RiCheckFill />;
        }
        case MessageStatus.Received:
            return <RiCheckDoubleFill />;
        default:
            return <></>;
    }
};
