import { Button, Card, CardBody, Flex, HStack, Heading, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";
import { User } from "../../user/user";
import { GoMail } from "react-icons/go";
import { AiOutlineEdit } from "react-icons/ai";

interface IProps {
    langKey: keyof IntlKey;
    profileUser: User | null;
    user: User | null;
    onModalEditOpen: () => void;
}

export const StudentProfileDesktop: React.FC<IProps> = ({ langKey, user, profileUser, onModalEditOpen }) => {
    return (
        <>
            <Flex w="100%" h="95vh" justify="center" alignItems="center">
                <Card w="400px" h="600px">
                    <CardBody w="100%" h="100%" bg="teddy_yellow.25">
                        <VStack w="100%" h="100%" alignContent="center" justify="center">
                            <Image
                                borderRadius="full"
                                boxSize="170px"
                                bg={"teddy_yellow.200"}
                                src={profileUser?.avatarUrl}
                            />
                            <Heading fontSize="3xl">{profileUser?.name}</Heading>
                            <HStack fontSize={16} gap={10}>
                                <Flex
                                    flexDirection="row"
                                    justify="flex-start"
                                    align="center"
                                    gap={1}
                                    color="teddy_grey.400"
                                >
                                    <Icon as={GoMail} />
                                    <Text>{profileUser?.email}</Text>
                                </Flex>
                            </HStack>
                            {profileUser?.id === user?.id && (
                                <Button
                                    w="50%"
                                    mt={10}
                                    leftIcon={<AiOutlineEdit />}
                                    color="teddy_yellow.10"
                                    colorScheme="teddy_yellow"
                                    size="sm"
                                    onClick={onModalEditOpen}
                                >
                                    {intl.profileEdit[langKey]}
                                </Button>
                            )}
                        </VStack>
                    </CardBody>
                </Card>
            </Flex>
        </>
    );
};
