import { isMobileScreen } from "../../../common/breakPoints";
import { HeroMobile } from "./heroMobile";
import { HeroDesktop } from "./heroDesktop";
import { useBreakpoint } from "@chakra-ui/react";
import { LanguageContext } from "../../../intl/languageContext";
import { IntlKey, Languages } from "../../../intl/intlLanguages";
import { useContext } from "react";

export const Hero: React.FC = () => {
    const breakPoint = useBreakpoint({ ssr: false });
    const { lang } = useContext(LanguageContext);
    const langKey = Languages[lang] as keyof IntlKey;

    return isMobileScreen(breakPoint) ? <HeroMobile langKey={langKey} /> : <HeroDesktop langKey={langKey} />;
};
