import { IIndexable } from "../common/iindexable";
import { IntlKey } from "../intl/intlLanguages";

export const Tags = {
    "adec5451-1812-4f3f-985e-ecb4ad31e32a": { en: "IELTS", 한국어: "IELTS" },
    "113cac67-c3de-4ca7-9474-a25161d787e9": { en: "TOEFL", 한국어: "TOEFL" },
    "6dfd1309-00ba-44b9-8f43-94f505e11443": { en: "Business", 한국어: "사업" },
    "c52fbfe6-e52e-434a-ab03-630f34f2b801": { en: "Job interview", 한국어: "직업 인터뷰" },
    "25484e60-2b9c-49ff-885c-c58131da89f1": { en: "Travel", 한국어: "해외 여행" },
    "95fc67b4-a14b-469b-a9a0-362115619aa4": { en: "School exams", 한국어: "학교 시험" },
    "90931380-f48e-412e-9112-fd585484e0d7": { en: "For kids", 한국어: "아이들 용" },
    "55d8bb70-c5f7-4fe0-87f9-b7e2a4e78ce1": { en: "University", 한국어: "대학 과정" },
    "15348acf-f463-481e-984a-3af67ba4baad": { en: "Online", 한국어: "온라인" },
    "6a9e1654-1dff-474d-a495-1310192a711b": { en: "In person", 한국어: "직접" },
    "3faa1138-52b6-4321-9d3c-165728c10060": {en: "Speak Korean", 한국어: "한국어로 말해요"}
} as IIndexable<IntlKey>;
