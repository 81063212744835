import * as React from "react";
import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MainContainer } from "./pages/mainContainer";
import { ErrorPage } from "./pages/error/errorPage";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase/firebaseConfig";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { HomeContainer } from "./pages/home/homeContainer";
import { FirebaseAuth } from "./firebase/firebaseAuth";
import { FireStorage } from "./firebase/fireStorage";
import "react-photo-view/dist/react-photo-view.css";
import { UserProfile } from "./pages/user/userProfile";
import { ChatContainer } from "./pages/chat/components/chatContainer";
import { Search } from "./pages/search/search";
import { SignUp } from "./pages/signUp/signUp";
import { AboutPage } from "./pages/about/aboutPage";
import { FaqPage } from "./pages/faq/faqPage";
import { UserSettings } from "./pages/userSettings/userSettings";

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const fireStorage = new FireStorage(app);
export const firebaseAuth = new FirebaseAuth(app);

//const analytics = getAnalytics(app);

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainContainer />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <HomeContainer />,
                errorElement: <ErrorPage />,
            },
            {
                path: "profile/:type/:profileId",
                element: <UserProfile />,
                errorElement: <ErrorPage />,
            },
            {
                path: "messages/:participantId?",
                element: <ChatContainer />,
                errorElement: <ErrorPage />,
            },
            {
                path: "settings",
                element: <UserSettings />,
                errorElement: <ErrorPage />,
            },
            {
                path: "search",
                element: <Search />,
                errorElement: <ErrorPage />,
            },
            {
                path: "signup",
                element: <SignUp />,
                errorElement: <ErrorPage />,
            },
            {
                path: "about",
                element: <AboutPage />,
                errorElement: <ErrorPage />,
            },
            {
                path: "faq/:qid?",
                element: <FaqPage />,
                errorElement: <ErrorPage />,
            },
        ],
    },
]);

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
