import { useBreakpoint } from "@chakra-ui/react";
import { ISearchResult } from "./search";
import { IntlKey } from "../../intl/intlLanguages";
import { isMobileScreen } from "../../common/breakPoints";
import { ProfileCardDesktop } from "./profileCardDesktop";
import { ProfileCardMobile } from "./profileCardMobile";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "../outletContext";
import { useContext } from "react";
import { UserContext } from "../user/userContext";

interface IProps {
    langKey: keyof IntlKey;
    teacher: ISearchResult;
}

export const ProfileCard: React.FC<IProps> = ({ teacher, langKey }) => {
    const { onLogInOpen } = useOutletContext<OutletContextType>();
    const { user } = useContext(UserContext);
    const bp = useBreakpoint();
    const isMobile = isMobileScreen(bp);
    const name = teacher.user.nickname ? teacher.user.nickname : teacher.user.name;
    return isMobile ? (
        <ProfileCardMobile langKey={langKey} name={name} teacher={teacher} user={user} onLoginOpen={onLogInOpen} />
    ) : (
        <ProfileCardDesktop langKey={langKey} name={name} teacher={teacher} user={user} onLoginOpen={onLogInOpen} />
    );
};
