import { IIndexable } from "../common/iindexable";
import { IntlKey } from "../intl/intlLanguages";

export const Locations = {
    "00000000-0000-0000-0000-0000000000000": {
        en: "",
        한국어: "",
    },
    "f7f6e547-37cf-4536-9bba-b650998f34d3": {
        en: "Online",
        한국어: "Online",
    },
    "2e402317-a7d4-48c4-ae4c-08dafe7acfcb": {
        en: "Seoul",
        한국어: "서울",
    },
    "8500d53b-bec3-41e1-a960-7734d6cedf60": {
        en: "Busan",
        한국어: "부산광역시",
    },
    "dee58f6a-0454-49ea-a08a-83c392b82512": {
        en: "Incheon",
        한국어: "인천광역시",
    },
    "fc8ecca3-b068-4031-8135-8ab8c473ccee": {
        en: "Ansan",
        한국어: "안산시",
    },
} as IIndexable<IntlKey>;
