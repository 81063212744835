import { Box, Circle, Flex, Grid, GridItem, HStack, Heading, Text } from "@chakra-ui/react";
import { IntlKey, intl } from "../../../intl/intlLanguages";

interface IProps {
    langKey: keyof IntlKey;
}

export const StepByStepDesktop: React.FC<IProps> = ({ langKey }) => {
    return (
        <Flex minW="70%" gap={10} p={20} pt={20} justify="center">
            <GridItem colSpan={1}>
                <Grid templateRows="repeat(2, 1fr)" w="100%" h="100%" gap={0} textAlign="left">
                    <GridItem>
                        <HStack w="100%" h="100%" gap={0} alignItems="center">
                            <Box>
                                <Flex h="100%" position="relative" alignItems="flex-end">
                                    <Circle
                                        position="absolute"
                                        top={0}
                                        left={1}
                                        size="60px"
                                        bg="teddy_yellow.500"
                                        zIndex={-1}
                                    />
                                    <Heading fontSize="6xl" pl={8} alignSelf="flex-end">
                                        1
                                    </Heading>
                                </Flex>
                            </Box>
                            <Heading fontSize="xl" w="40%" pb={2}>
                                {intl.stepByStepFirstHeader[langKey]}
                            </Heading>
                        </HStack>
                    </GridItem>
                    <GridItem>
                        <Text color="teddy_grey.400" fontWeight="bold" fontSize="md">
                            {intl.stepByStepFirstDescr[langKey]}
                        </Text>
                    </GridItem>
                </Grid>
            </GridItem>
            <GridItem colSpan={1}>
                <Grid templateRows="repeat(2, 1fr)" w="100%" h="100%" gap={0} textAlign="left">
                    <GridItem>
                        <HStack w="100%" h="100%" gap={0} alignItems="center">
                            <Box>
                                <Flex h="100%" position="relative" alignItems="flex-end">
                                    <Circle
                                        position="absolute"
                                        top={0}
                                        left={1}
                                        size="60px"
                                        bg="teddy_blue.500"
                                        zIndex={-1}
                                    />
                                    <Heading fontSize="6xl" pl={9} alignSelf="flex-end">
                                        2
                                    </Heading>
                                </Flex>
                            </Box>
                            <Heading fontSize="xl" w="50%" pb={2}>
                                {intl.stepByStepSecondHeader[langKey]}
                            </Heading>
                        </HStack>
                    </GridItem>
                    <GridItem>
                        <Text color="teddy_grey.400" fontWeight="bold" fontSize="md">
                            {intl.stepByStepSecondDescr[langKey]}
                        </Text>
                    </GridItem>
                </Grid>
            </GridItem>
            <GridItem colSpan={1}>
                <Grid templateRows="repeat(2, 1fr)" w="100%" h="100%" gap={0} textAlign="left">
                    <GridItem>
                        <HStack w="100%" h="100%" gap={0} alignItems="center">
                            <Box>
                                <Flex h="100%" position="relative" alignItems="flex-end">
                                    <Circle
                                        position="absolute"
                                        top={0}
                                        left={1}
                                        size="60px"
                                        bg="teddy_teal.500"
                                        zIndex={-1}
                                    />
                                    <Heading fontSize="6xl" pl={9} alignSelf="flex-end">
                                        3
                                    </Heading>
                                </Flex>
                            </Box>
                            <Heading fontSize="xl" w="70%" pb={2}>
                                {intl.stepByStepThirdHeader[langKey]}
                            </Heading>
                        </HStack>
                    </GridItem>
                    <GridItem>
                        <Text color="teddy_grey.400" fontWeight="bold" fontSize="md">
                            {intl.stepByStepThirdDescr[langKey]}
                        </Text>
                    </GridItem>
                </Grid>
            </GridItem>
        </Flex>
    );
};
